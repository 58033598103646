import {
  GET_PAGE_CONTENT_BY_ID,
  GET_PAGE_CONTENTS,
  SET_PAGE_CONTENT,
} from '../types';
import { success } from '../utils';

export const initialState = {
  contents: [],
  pageContent: null,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case success(GET_PAGE_CONTENTS):
      return {
        ...state,
        contents: action.payload.data,
      };
    case success(GET_PAGE_CONTENT_BY_ID):
      return {
        ...state,
        pageContent: action.payload.data,
      };
    case SET_PAGE_CONTENT:
      return {
        ...state,
        pageContent: action.pageContent,
      };
    default:
      return state;
  }
};
