/**
 * toQueryString converts an object of key/value pairs into a query string.
 * @param params: an object of key value pairs to convert into a query string
 * @return string: query string when there are params
 */
const toQueryString = (params) => {
  return params && Object.keys(params).length > 0
    ? '?' +
        Object.keys(params)
          .map((key) => {
            if (params[key] !== null && params[key] !== undefined) {
              if (Array.isArray(params[key])) {
                return params[key]
                  .map(
                    (value) =>
                      `${encodeURIComponent(key)}=${encodeURIComponent(
                        value.toString()
                      )}`
                  )
                  .filter(Boolean)
                  .join('&');
              } else {
                return `${encodeURIComponent(key)}=${encodeURIComponent(
                  params[key].toString()
                )}`;
              }
            } else return null;
          })
          .filter(Boolean)
          .join('&')
    : '';
};

export default toQueryString;
