import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  PageHeader,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedDate, FormattedTime } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import { Pager } from '../../../../components';
import { getSeachFilterProps } from '../../../../components/TableFilters';
import { warrantyClaimsActions } from '../../../../store';
import { useHistory } from 'react-router';
import { useTableSorter } from '../../../../hooks/useTableSorter';

const { Search } = Input;
const { Paragraph } = Typography;

export const SearchWarrantyClaims = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useQueryParams({
    page: withDefault(NumberParam, 1),
    query: StringParam,
    claimNumber: StringParam,
    firstName: StringParam,
    lastName: StringParam,
    company: StringParam,
    city: StringParam,
    region: StringParam,
    country: StringParam,
    orderBy: ArrayParam,
  });
  const settingsFormRef = useRef();

  // app state
  const searchResults = useSelector(
    (state) => state.warrantyClaims.searchResults,
    shallowEqual
  );
  const settings = useSelector(
    (state) => state.warrantyClaims.settings,
    shallowEqual
  );

  // local state
  const [loading, setLoading] = useState(true);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const { handleTableChange, sortOrder } = useTableSorter(
    queryParams,
    setQueryParams
  );

  useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(warrantyClaimsActions.searchWarrantyClaims(queryParams)),
      dispatch(warrantyClaimsActions.getSettings()),
    ]).finally(() => setLoading(false));
  }, [dispatch, window.location.search]);

  /***
   * When the page changes, scroll back to the top
   */
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [queryParams.page]);

  /***
   * updateQueryParam updates a single param in the query object
   * @param key: the query param to update
   * @param value: the new value of the query param
   */
  const updateQueryParam = (key, value) => {
    let updatedQuery = { ...queryParams };
    updatedQuery[key] = value;
    setQueryParams(updatedQuery);
  };

  /***
   * clearAll wipes out all query string parameters and resets the table
   */
  const clearAll = () => {
    setQueryParams({}, 'push');
  };

  /***
   * handleSettingsFormSubmit takes the form values and submits them to the server
   * @param values: the form values
   */
  const handleSettingsFormSubmit = (values) => {
    dispatch(warrantyClaimsActions.updateSettings(values))
      .then(() => message.success('Settings Updated!'))
      .catch(() =>
        message.error(
          'Something went wrong while updating the settings...Please try again later.'
        )
      );
  };

  const onViewClick = (id) => {
    // Clears out warranty claim from state to be refetched on page load in ViewWarrantyClaims
    dispatch(warrantyClaimsActions.setWarrantyClaim(null));
    history.push('/warrantyclaims/' + id);
  };

  const columns = [
    {
      title: 'Claim #',
      dataIndex: 'claimNumber',
      sorter: {
        multiple: 1,
      },
      sortOrder: sortOrder['ClaimNumber'],
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'claimNumber',
        queryParamValue: queryParams.claimNumber,
      }),
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      sorter: {
        multiple: 1,
      },
      sortOrder: sortOrder['FirstName'],
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'firstName',
        queryParamValue: queryParams.firstName,
      }),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      sorter: {
        multiple: 1,
      },
      sortOrder: sortOrder['LastName'],
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'lastName',
        queryParamValue: queryParams.lastName,
      }),
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      sorter: {
        multiple: 1,
      },
      sortOrder: sortOrder['PhoneNumber'],
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'phoneNumber',
        queryParamValue: queryParams.phoneNumber,
      }),
    },
    {
      title: 'Email',
      dataIndex: 'emailAddress',
      sorter: {
        multiple: 1,
      },
      sortOrder: sortOrder['EmailAddress'],
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'emailAddress',
        queryParamValue: queryParams.emailAddress,
      }),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      sorter: {
        multiple: 1,
      },
      sortOrder: sortOrder['Company'],
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'company',
        queryParamValue: queryParams.company,
      }),
    },
    {
      title: 'City',
      dataIndex: 'city',
      sorter: {
        multiple: 1,
      },
      sortOrder: sortOrder['City'],
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'city',
        queryParamValue: queryParams.city,
      }),
    },
    {
      title: 'State/Region',
      dataIndex: 'region',
      sorter: {
        multiple: 1,
      },
      sortOrder: sortOrder['Region'],
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'region',
        queryParamValue: queryParams.region,
      }),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      sorter: {
        multiple: 1,
      },
      sortOrder: sortOrder['Country'],
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'country',
        queryParamValue: queryParams.country,
      }),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      sorter: {
        multiple: 1,
      },
      sortOrder: sortOrder['CreatedAt'],
      render: (date) =>
        date ? (
          <Space>
            <FormattedDate value={moment.utc(date).local()} />
            <FormattedTime value={moment.utc(date).local()} />
          </Space>
        ) : (
          '-'
        ),
    },
    {
      title: 'Status',
      key: 'isResolved',
      dataIndex: 'isResolved',
      fixed: 'right',
      render: (isResolved) => (
        <Tag color={isResolved ? 'green' : 'volcano'}>
          {isResolved ? 'Resolved' : 'Unresolved'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (claim) => (
        <Space size='middle'>
          <Button onClick={() => onViewClick(claim.id)}>View</Button>
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>Warranty Claims | Stiletto Tools Admin</title>
      </Helmet>
      <PageHeader
        title='Warranty Claims'
        extra={[
          <Button
            key='settings'
            icon={<SettingOutlined />}
            onClick={() => setSettingsVisible(true)}
          >
            Settings
          </Button>,
        ]}
      />
      <Row gutter={[16, 16]}>
        <Col xs={18} sm={20} md={16} lg={12} xl={8}>
          <Search
            placeholder={'Search...'}
            onSearch={(value) =>
              setQueryParams({ ...queryParams, query: value })
            }
            defaultValue={queryParams.query}
            loading={loading}
            style={{ width: '100%' }}
          />
        </Col>
        <Col>
          <Button onClick={clearAll}>Reset</Button>
        </Col>
      </Row>
      <Table
        rowKey={'id'}
        columns={columns}
        dataSource={searchResults?.data || []}
        onChange={handleTableChange}
        scroll={{ x: true }}
        pagination={false}
        loading={loading}
      />
      {searchResults?.totalCount > 50 && (
        <Pager
          current={queryParams.page}
          pageSize={50}
          total={searchResults?.totalCount}
          showSizeChanger={false}
          onChange={(page) => setQueryParams({ page })}
        />
      )}
      <Drawer
        title='Settings'
        width={720}
        style={{ maxWidth: '100%' }}
        onClose={() => setSettingsVisible(false)}
        visible={settingsVisible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => setSettingsVisible(false)}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => settingsFormRef.current.submit()}
              type='primary'
            >
              Save
            </Button>
          </div>
        }
      >
        <Form
          ref={settingsFormRef}
          name='settings'
          layout='vertical'
          onFinish={handleSettingsFormSubmit}
          initialValues={settings}
        >
          <Divider orientation='left'>Support Email</Divider>
          <Paragraph>
            These settings affect the emails that are sent to the support team
            only.
          </Paragraph>

          <Form.Item
            label='From Address'
            name={['supportEmailSetting', 'fromAddress']}
            rules={[
              { required: true, message: 'An Email Address is Required' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='To Addresses'
            name={['supportEmailSetting', 'toAddresses']}
            rules={[
              { required: true, message: 'An Email Address is Required' },
            ]}
          >
            <Select
              mode='tags'
              style={{ width: '100%' }}
              maxTagCount={50}
              placeholder="Hit 'enter' after each email"
              dropdownStyle={{ display: 'none' }}
            />
          </Form.Item>

          <Form.Item
            label='Cc Addresses'
            name={['supportEmailSetting', 'ccAddresses']}
          >
            <Select
              mode='tags'
              style={{ width: '100%' }}
              maxTagCount={50}
              placeholder="Hit 'enter' after each email"
              dropdownStyle={{ display: 'none' }}
            />
          </Form.Item>

          <Form.Item
            label='Bcc Addresses'
            name={['supportEmailSetting', 'bccAddresses']}
          >
            <Select
              mode='tags'
              style={{ width: '100%' }}
              maxTagCount={50}
              placeholder="Hit 'enter' after each email"
              dropdownStyle={{ display: 'none' }}
            />
          </Form.Item>

          <Divider orientation='left'>Customer Email</Divider>
          <Paragraph>
            These settings affect the warranty emails that are sent to the
            customer.
          </Paragraph>

          <Form.Item
            label='From Address'
            name={['customerEmailSetting', 'fromAddress']}
            rules={[
              { required: true, message: 'An Email Address is Required' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Email Header'
            name={['customerEmailSetting', 'emailHeader']}
            rules={[{ required: true, message: 'An email header is required' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Email Sub Header'
            name={['customerEmailSetting', 'emailSubHeader']}
            rules={[
              { required: true, message: 'An email sub header is required' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Email Body'
            name={['customerEmailSetting', 'emailBody']}
            rules={[{ required: true, message: 'An email body is required' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Email Body Line 2'
            name={['customerEmailSetting', 'emailBodyLine2']}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Sendgrid Template Id'
            name={['customerEmailSetting', 'templateId']}
          >
            <Input readOnly disabled />
          </Form.Item>
        </Form>
      </Drawer>
    </React.Fragment>
  );
};
