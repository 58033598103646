import { useEffect, useState } from 'react';

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export const useTableSorter = (queryParams, setQueryParams) => {
  const [sortOrder, setSortOrder] = useState({});

  useEffect(() => {
    let sortOrder = {};
    queryParams.orderBy &&
      queryParams.orderBy.forEach((orderByField) => {
        let parts = orderByField.split(' ');
        sortOrder[parts[0].capitalize()] =
          parts[1] === 'asc' ? 'ascend' : 'descend';
      });
    setSortOrder(sortOrder);
  }, [queryParams.orderBy]);

  /***
   * handleSort will update the orderBy query string parameter with the sorted fields and directions
   * @param sorter: the sorter which contains all fields and their sort order
   */
  const handleSort = (sorter) => {
    if (Array.isArray(sorter)) {
      let newOrderBy = sorter.map(
        (column) =>
          `${
            column.column.key?.capitalize() ||
            column.column.dataIndex?.capitalize()
          } ${column.order === 'ascend' ? 'asc' : 'desc'}`
      );
      setQueryParams({ orderBy: newOrderBy });
    } else if (!sorter?.order) {
      setQueryParams({ orderBy: [] });
    } else {
      setQueryParams({
        orderBy: [
          `${
            sorter.column.key?.capitalize() ||
            sorter.column.dataIndex?.capitalize()
          } ${sorter.order === 'ascend' ? 'asc' : 'desc'}`,
        ],
      });
    }
  };

  /***
   * handleChange updates the orderBy query parameter.
   * We are ignoring pagination and filters, as we are handling those manually on the column itself.
   * @param pagination
   * @param filters
   * @param sorter
   */
  const handleTableChange = (pagination, filters, sorter) => {
    handleSort(sorter);
  };

  return { handleTableChange, sortOrder };
};
