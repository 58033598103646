import {
  GET_DISTRIBUTOR,
  GET_DISTRIBUTORS,
  SET_DISTRIBUTOR,
  UPDATE_DISTRIBUTOR,
} from '../types';
import { success } from '../utils';

export const initialState = {
  distributorsList: null,
  isDistributorsListLoading: false,
  distributor: null,
  isDistributorLoading: false,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_DISTRIBUTORS:
      return {
        ...state,
        isDistributorsListLoading: true,
      };
    case success(GET_DISTRIBUTORS):
      return {
        ...state,
        distributorsList: action.payload.data,
        isDistributorsListLoading: false,
      };
    case SET_DISTRIBUTOR:
      return {
        ...state,
        distributor: action.distributor,
      };
    case GET_DISTRIBUTOR:
      return {
        ...state,
        isDistributorLoading: true,
      };
    case success(GET_DISTRIBUTOR):
      return {
        ...state,
        distributor: action.payload.data,
        isDistributorLoading: false,
      };
    case success(UPDATE_DISTRIBUTOR):
      return {
        ...state,
        distributor: action.payload.data,
        distributorsList: null,
      };
    default:
      return state;
  }
};
