import React from 'react';
import styled from 'styled-components';

export const SortableImageWrapper = styled.div`
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  border: 1px solid lightgray;
  justify-content: center;
  display: flex;
  padding: 7px;
`;

export const SortableImage = ({ file }) => {
  return (
    <SortableImageWrapper className={'SortableImageWrapper'}>
      <img
        style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
        src={file.url}
        alt=''
      />
    </SortableImageWrapper>
  );
};
