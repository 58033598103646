import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import * as Config from './Config';
import * as ContactUs from './ContactUs';
import * as Distributors from './Distributors';
import * as Locales from './Locales';
import * as PageContents from './PageContents';
import * as Products from './Products';
import * as RetailStores from './RetailStores';
import * as WarrantyClaims from './WarrantyClaims';
import * as FileUpload from './FileUpload';
import * as ReasonCodes from './ReasonCodes';
import * as Continents from './Continents';
import * as ContinentCountries from './ContinentCountries';
import * as ContinentCountryStores from './ContinentCountryStores';

export default function configureStore(history, initialState) {
  const reducers = {
    config: Config.reducer,
    contactUs: ContactUs.reducer,
    locales: Locales.reducer,
    pageContents: PageContents.reducer,
    products: Products.reducer,
    distributors: Distributors.reducer,
    retailStores: RetailStores.reducer,
    warrantyClaims: WarrantyClaims.reducer,
    fileUpload: FileUpload.reducer,
    reasonCodes: ReasonCodes.reducer,
    continents: Continents.reducer,
    continentCountries: ContinentCountries.reducer,
    continentCountryStores: ContinentCountryStores.reducer,
  };

  const middleware = [thunk, routerMiddleware(history)];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (
    isDevelopment &&
    typeof window !== 'undefined' &&
    window.devToolsExtension
  ) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
