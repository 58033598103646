import styled, { css } from 'styled-components';

export const ProductCard = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const getBackgroundColor = (props) => {
  if (props.highlight) {
    return css`
      background-image: linear-gradient(#2c2c2c, white);
      border: 2px solid white;
    `;
  } else {
    return css`
      background-color: #f6f6f6;
    `;
  }
};

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  ${(props) => getBackgroundColor(props)}
`;
