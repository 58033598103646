import Icon, {
  BankOutlined,
  DatabaseOutlined,
  FileProtectOutlined,
  GlobalOutlined,
  HomeOutlined,
  LayoutOutlined,
  InfoCircleOutlined,
  LockOutlined,
  MailOutlined,
  ProfileOutlined,
  SafetyCertificateOutlined,
  ShopOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  FileExcelOutlined,
  QuestionCircleOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import Logo from '../../assets/logo.png';
import { ReactComponent as Hammer } from '../../assets/svgs/hammer.svg';
import { withAppInsights } from '../../helpers/appInsights';
import { AdminFooter, AdminHeader } from './styled';
import useCurrentUserProfile from '../../hooks/useCurrentUserProfile';
import useSignOut from '../../hooks/useSignOut';

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

export const SiteLayout = withAppInsights(({ children }) => {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);

  const signOut = useSignOut();
  const userProfile = useCurrentUserProfile();
  const fullName = `${userProfile?.given_name} ${userProfile?.family_name}`;

  const isAdmin = () => {
    return userProfile.roles.includes('SuperUser');
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        theme='dark'
        collapsible
        collapsed={collapsed}
        onCollapse={(isCollapsed) => setCollapsed(isCollapsed)}
      >
        <div onClick={() => history.push('/')}>
          <img
            src={Logo}
            alt='logo'
            style={{ width: '100%', padding: '0.3rem' }}
          />
        </div>
        <Menu
          theme='dark'
          defaultOpenKeys={['support', 'policies', 'page-contents', 'data']}
          defaultSelectedKeys={[window.location.pathname]}
          mode='inline'
        >
          <SubMenu
            key='support'
            title={
              <span>
                <UserOutlined />
                <span>Support</span>
              </span>
            }
          >
            <Menu.Item key='/' icon={<SafetyCertificateOutlined />}>
              <Link to='/'>Warranty Claims</Link>
            </Menu.Item>
            <Menu.Item key='/contactus' icon={<MailOutlined />}>
              <Link to='/contactus'>Contact Us</Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu
            style={{ display: isAdmin() ? 'block' : 'none' }}
            key='data'
            title={
              <span>
                <DatabaseOutlined />
                <span>Data</span>
              </span>
            }
          >
            <Menu.Item key='/products' icon={<Icon component={Hammer} />}>
              <Link to='/products'>Products</Link>
            </Menu.Item>
            <Menu.Item key='/international' icon={<GlobalOutlined />}>
              <Link to='/international'>International</Link>
            </Menu.Item>
            <Menu.Item key='/distributors' icon={<UsergroupAddOutlined />}>
              <Link to='/distributors'>Distributors</Link>
            </Menu.Item>
            <Menu.Item key='/reasoncodes' icon={<FileExcelOutlined />}>
              <Link to='/reasoncodes'>Reason Codes</Link>
            </Menu.Item>
            <Menu.Item key='/retailstores' icon={<ShopOutlined />}>
              <Link to='/retailstores'>Retail Stores</Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu
            style={{ display: isAdmin() ? 'block' : 'none' }}
            key='page-contents'
            title={
              <span>
                <ProfileOutlined />
                <span>Page Contents</span>
              </span>
            }
          >
            <Menu.Item key='/landingpage' icon={<HomeOutlined />}>
              <Link to='/landing-page'>Landing Page</Link>
            </Menu.Item>
            <Menu.Item key='/category-pages' icon={<LayoutOutlined />}>
              <Link to='/category-pages'>Category Pages</Link>
            </Menu.Item>
            <Menu.Item key='/about' icon={<InfoCircleOutlined />}>
              <Link to='/about'>About Us</Link>
            </Menu.Item>
            <Menu.Item key='/warranty' icon={<SafetyCertificateOutlined />}>
              <Link to='/warranty'>Warranty</Link>
            </Menu.Item>
            <Menu.Item key='/global' icon={<GlobalOutlined />}>
              <Link to='/globalcontents'>Global</Link>
            </Menu.Item>
            <Menu.Item key='/faq' icon={<QuestionCircleOutlined />}>
              <Link to='/faq'>FAQ</Link>
            </Menu.Item>
            <Menu.Item key='/fileupload' icon={<CloudUploadOutlined />}>
              <Link to='/fileupload'>File Upload</Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu
            style={{ display: isAdmin() ? 'block' : 'none' }}
            key='policies'
            title={
              <span>
                <FileProtectOutlined />
                <span>Policies</span>
              </span>
            }
          >
            <Menu.Item key='/legal' icon={<BankOutlined />}>
              <Link to='/legal'>Legal Policy</Link>
            </Menu.Item>
            <Menu.Item
              key='/warranty-policy'
              icon={<SafetyCertificateOutlined />}
            >
              <Link to='/warranty-policy'>Warranty Policy</Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
      <Layout className='site-layout'>
        <AdminHeader>
          <Menu
            defaultSelectedKeys={[]}
            mode='horizontal'
            style={{ width: '100%' }}
          >
            <SubMenu
              style={{ float: 'right' }}
              icon={<UserOutlined />}
              title={fullName}
            >
              <Menu.Item key='admin:signOut' onClick={() => signOut()}>
                Sign Out
              </Menu.Item>
            </SubMenu>
          </Menu>
        </AdminHeader>
        <Content style={{ margin: 16 }}>{children}</Content>
        <AdminFooter>
          ©{new Date().getFullYear()} by Stiletto Tools. All rights reserved.
        </AdminFooter>
      </Layout>
    </Layout>
  );
});
