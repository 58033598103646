import {
  MinusCircleOutlined,
  SaveOutlined,
  DashOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Descriptions,
  Layout,
  message,
  PageHeader,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { ReactSortable } from 'react-sortablejs';
import {
  Container,
  ProductImage,
  AutoCompleteInput,
  MarkdownEditorXs,
} from '../../../components';
import { productsActions } from '../../../store';
import { ImageUploader } from './Component/ImageUploader';
import { SortableImage } from './Component/SortableImage';
import { MetadataFormItem } from './Component/MetadataFormItem';
import {
  dimensionsFormItems,
  metadataFormItems,
  categoryOptions,
} from './config';
import { DimensionsFormItem } from './Component/DimensionsFormItem';
import { FeaturesListFormItem } from './Component/FeaturesListFormItem';

import './styles.css';
import { Specifications } from './styled';
import ReactDOMServer from 'react-dom/server';
import ReactMarkdown from 'react-markdown';

const { Title } = Typography;
const { Content } = Layout;
const { TextArea } = Input;

function constructDnDImageList(imageList) {
  let finalImageList = [];

  // Loop through each image object
  Object.keys(imageList).forEach(function (key, index) {
    // Construct initial image obj
    let imageObj = {
      index: index,
      name: key,
      url: imageList[key].imageUrl,
    };
    finalImageList.push(imageObj);
  });

  return finalImageList;
}

export const EditProduct = () => {
  const intl = useIntl();
  const { sku } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const formRef = useRef();

  // app state
  const productDetails = useSelector((state) => state.products.productDetails);
  const isLoading = useSelector(
    (state) => state.products.productDetailsLoading
  );
  const productImageList = useSelector(
    (state) => state.products.productImageList,
    shallowEqual
  );
  const isUpdating = useSelector((state) => state.products.isUpdating);
  const suggestions = useSelector(
    (state) => state.products.suggestions,
    shallowEqual
  );

  // local state
  const [isUploading, setIsUploading] = useState(false);
  const [imageIndex, setImageIndex] = useState([]);
  const [isEditingOrder, setIsEditingOrder] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [featureList, setFeatureList] = useState([]);

  /***
   * On a hard refresh, we won't have a product loaded yet.
   * Go hit the api to load the product details.
   */
  useEffect(() => {
    if (!productDetails && sku !== 'new') {
      // hit api and load product
      dispatch(productsActions.getProduct(sku));
    }
  }, [dispatch, sku, productDetails]);

  /***
   * When the product image list changes, we need to recalculate if ANY
   * image is currently being uploaded.
   */
  useEffect(() => {
    if (productImageList) {
      setIsUploading(
        Object.keys(productImageList).some(
          (fileName) => productImageList[fileName].isUploading
        )
      );
      // Construct image array for DnD
      setImageIndex(constructDnDImageList(productImageList));
    }
  }, [productImageList]);

  /***
   * When image is reordered
   * Set the new image order and update app state
   */
  useEffect(() => {
    let newImageList = [];
    if (!isEditingOrder && imageIndex.length > 0) {
      //Reorder images in productImageList
      imageIndex.forEach((item) => {
        newImageList.push(productImageList[item.name].imageUrl);
      });

      // Reconstruct productImage list to use new order
      dispatch(productsActions.setImageOrder(newImageList, sku));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingOrder]);

  /***
   * When product details are loading, be sure to set the related products
   * on state and set our intial form field values
   */
  useEffect(() => {
    if (productDetails && sku !== 'new') {
      setRelatedProducts(productDetails.relatedProducts);
      formRef.current.setFieldsValue({ ...productDetails });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails]);

  useEffect(() => {
    if (productDetails && sku !== 'new') {
      setFeatureList(productDetails?.features);
      formRef.current.setFieldsValue({ ...productDetails });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails]);

  /***
   * onFinish will save the updated product info
   * @param form: the values of the form
   */
  const onFinish = (form) => {
    let product = {
      ...form,
      // constructing imageUrls and relatedProducts manually
      imageUrls: imageIndex.map((item) => item.url),
      relatedProducts: relatedProducts,
      features: featureList,
    };
    if (sku !== 'new') {
      dispatch(productsActions.updateProduct(product))
        .then(() => {
          message.success('Successfully Updated!');
        })
        .catch(() => {
          message.error('An error has occurred');
        });
    } else {
      dispatch(productsActions.insertProduct(product))
        .then(() => {
          message.success('Successfully Added!');
          history.replace('/products/' + product.sku);
        })
        .catch((error) => {
          if (error.response.status === 409) {
            message.warning('A product with this SKU already exists');
          } else {
            message.error('An error has occurred');
          }
        });
    }
  };

  const handleSuggestProducts = (query) => {
    if (query)
      dispatch(productsActions.suggestProducts(query)).catch(() =>
        message.error('Something went wrong while getting suggestions...')
      );
  };

  const handleSuggestionSelect = (sku) => {
    setRelatedProducts([
      ...relatedProducts,
      suggestions.find((x) => x.document.sku === sku).document,
    ]);
  };

  const handleFeaturedItems = (featuredItems) => {
    setFeatureList(featuredItems);
  };

  return (
    <Container style={{ padding: 24 }}>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'adminProductDetails.title',
          })}
        </title>
      </Helmet>
      <Content>
        <Spin
          size='large'
          wrapperClassName='product-details-spinner'
          spinning={isLoading}
        >
          <span></span>
        </Spin>
        {(productDetails || sku === 'new') && (
          <Typography>
            <Form
              ref={formRef}
              name='dynamic_form_nest_item'
              onFinish={onFinish}
              autoComplete='off'
              hideRequiredMark={true}
              layout={'vertical'}
            >
              <PageHeader
                style={{ padding: 0 }}
                onBack={() => history.goBack()}
                title={intl.formatMessage({
                  id: 'adminProductDetails.editProduct',
                })}
                extra={[
                  <Button
                    key={'Button1'}
                    type='primary'
                    htmlType='submit'
                    disabled={isUploading}
                    loading={isUpdating}
                    icon={<SaveOutlined />}
                  >
                    {intl.formatMessage({
                      id: 'adminProductDetails.saveBtn',
                    })}
                  </Button>,
                ]}
              />
              <Divider />
              <Row gutter={[16, 16]} justify='center'>
                <Col xs={24} lg={12}>
                  {productImageList[Object.keys(productImageList)[0]]
                    ?.imageUrl ? (
                    <ProductImage
                      imageUrl={
                        productImageList[Object.keys(productImageList)[0]]
                          ?.imageUrl
                      }
                      maxHeight={300}
                    />
                  ) : (
                    ''
                  )}
                  {isEditingOrder ? (
                    <ReactSortable
                      tag={'div'}
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: '20px',
                      }}
                      list={imageIndex}
                      setList={setImageIndex}
                    >
                      {imageIndex.map((item, index) => (
                        <SortableImage key={index} file={item} />
                      ))}
                    </ReactSortable>
                  ) : (
                    <ImageUploader />
                  )}

                  <Button
                    style={{ width: '100%' }}
                    onClick={() => setIsEditingOrder(!isEditingOrder)}
                  >
                    {isEditingOrder ? (
                      <FormattedMessage id='adminProductDetails.finish' />
                    ) : (
                      <FormattedMessage id='adminProductDetails.editImageOrder' />
                    )}
                  </Button>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name='sku'
                    label={intl.formatMessage({
                      id: 'adminProductDetails.sku',
                    })}
                    initialValue={productDetails?.skuAlias}
                    rules={[{ required: true, message: 'Required' }]}
                  >
                    <Input disabled={sku === 'new' ? false : true} />
                  </Form.Item>
                  <Form.Item
                    name='title'
                    label={intl.formatMessage({
                      id: 'adminProductDetails.productTitle',
                    })}
                    rules={[{ required: true, message: 'Missing Title' }]}
                    initialValue={productDetails?.title}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name='overview'
                    label={intl.formatMessage({
                      id: 'adminProductDetails.overview',
                    })}
                    rules={[{ required: true, message: 'Missing Overview' }]}
                    initialValue={productDetails?.overview}
                  >
                    <MarkdownEditorXs
                      options={{
                        spellChecker: false,
                        previewRender(value) {
                          return ReactDOMServer.renderToString(
                            <ReactMarkdown source={value} />
                          );
                        },
                      }}
                    />
                  </Form.Item>

                  <FeaturesListFormItem
                    productDetails={productDetails}
                    onChange={handleFeaturedItems}
                  />

                  <Form.Item label='Related Products' name='relatedProducts'>
                    <AutoCompleteInput
                      defaultValue={productDetails?.relatedProducts.map(
                        (x) => x.skuAlias
                      )}
                      onSearch={handleSuggestProducts}
                      onChange={handleSuggestionSelect}
                      suggestions={suggestions}
                      style={{ marginBottom: '8px' }}
                    />
                    <ReactSortable
                      tag={'div'}
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: '20px',
                      }}
                      list={relatedProducts}
                      setList={setRelatedProducts}
                    >
                      {relatedProducts?.map((product, i) => {
                        return (
                          <Input
                            key={`related-${i}`}
                            style={{ width: '100%', marginBottom: '8px' }}
                            value={`${product.skuAlias}: ${product.title}`}
                            addonBefore={
                              <DashOutlined className={'draggable'} />
                            }
                            suffix={
                              <MinusCircleOutlined
                                title={intl.formatMessage({
                                  id: 'general.delete',
                                })}
                                onClick={() =>
                                  setRelatedProducts(
                                    relatedProducts.filter(
                                      (x) => x.sku !== product.sku
                                    )
                                  )
                                }
                              />
                            }
                          />
                        );
                      })}
                    </ReactSortable>
                  </Form.Item>
                </Col>
              </Row>

              <Title level={2}>
                <FormattedMessage id='productDetails.specificationsLabel' />
              </Title>

              <Divider />

              <Specifications
                bordered
                title={intl.formatMessage({
                  id: 'productDetails.dimensions.dimensionsLabel',
                })}
                size='default'
                column={{
                  xxl: 2,
                  xl: 2,
                  lg: 2,
                  md: 1,
                  sm: 1,
                  xs: 1,
                }}
              >
                {dimensionsFormItems.map((item, index) => {
                  return (
                    <Descriptions.Item
                      key={`dimensions-${index}`}
                      label={intl.formatMessage({
                        id: `productDetails.dimensions.${item.dimensionsKey}`,
                      })}
                    >
                      <DimensionsFormItem
                        productDetails={productDetails}
                        dimensionsKey={item.dimensionsKey}
                        formatter={item.formatter}
                        parser={item.parser}
                      />
                    </Descriptions.Item>
                  );
                })}
              </Specifications>

              <Specifications
                bordered
                title={intl.formatMessage({
                  id: 'productDetails.metadata.specificationDetailsLabel',
                })}
                size='default'
                column={{
                  xxl: 2,
                  xl: 2,
                  lg: 2,
                  md: 1,
                  sm: 1,
                  xs: 1,
                }}
              >
                {metadataFormItems.map((item, index) => {
                  return (
                    <Descriptions.Item
                      key={`metadata-${index}`}
                      label={intl.formatMessage({
                        id: `productDetails.metadata.${item.metadataKey}`,
                      })}
                    >
                      <MetadataFormItem
                        formRef={formRef}
                        productDetails={productDetails}
                        metadataKey={item.metadataKey}
                        type={item.type}
                        options={item.options}
                      />
                    </Descriptions.Item>
                  );
                })}
              </Specifications>

              <Form.Item
                name='categories'
                label={intl.formatMessage({
                  id: 'adminProductDetails.categories',
                })}
                rules={[
                  {
                    required: true,
                    message: 'Must have at least 1 category',
                  },
                ]}
                initialValue={productDetails?.categories}
              >
                <Select
                  mode='multiple'
                  placeholder='Inserted are removed'
                  style={{ width: '100%' }}
                >
                  {categoryOptions.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name='isHidden'
                valuePropName='checked'
                initialValue={productDetails?.isHidden}
              >
                <Checkbox>Is Hidden</Checkbox>
              </Form.Item>

              <Form.Item
                name='isDiscontinued'
                valuePropName='checked'
                initialValue={productDetails?.isDiscontinued}
              >
                <Checkbox>
                  <FormattedMessage id='adminProductDetails.isDiscontinued' />
                </Checkbox>
              </Form.Item>

              <Divider />
              <Row justify='end'>
                <Col>
                  <Button
                    type='primary'
                    htmlType='submit'
                    disabled={isUploading}
                    loading={isUpdating}
                    icon={<SaveOutlined />}
                  >
                    {intl.formatMessage({ id: 'adminProductDetails.saveBtn' })}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Typography>
        )}
      </Content>
    </Container>
  );
};
