import { PublicClientApplication } from '@azure/msal-browser';

const appId = '45130ac8-763e-427c-989b-05c8ba8de0b5';
const authority = 'https://login.microsoftonline.com/';
const tenant = '324f02a1-b901-46ec-867e-a62e49604dbb';

export const msalConfig = {
  auth: {
    clientId: appId,
    authority: `${authority}${tenant}`,
    redirectUri: 'https://' + window.location.host,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ['openid', 'profile', 'email', 'offline_access', 'User.Read'],
};

export const parseAccessToken = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const msalInstance = new PublicClientApplication(msalConfig);
