import createAxiosAction from '../createAxiosAction';
import {
  GET_REASON_CODES,
  INSERT_REASON_CODE,
  UPDATE_REASON_CODE,
} from '../types';

export default {
  getReasonCodes: () => {
    return createAxiosAction({
      url: '/api/v1/reasonCodes',
      startAction: GET_REASON_CODES,
    });
  },
  insertReasonCode: (reasonCode) => {
    return createAxiosAction({
      method: 'POST',
      url: '/api/v1/reasonCodes',
      body: { ReasonCode: reasonCode },
      startAction: INSERT_REASON_CODE,
    });
  },
  updateReasonCode: (reasonCode) => {
    return createAxiosAction({
      method: 'PUT',
      url: '/api/v1/reasonCodes',
      body: { ReasonCode: reasonCode },
      startAction: UPDATE_REASON_CODE,
    });
  },
};
