import React, { useEffect, useState } from 'react';
import { Button, Typography, Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DashOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { ReactSortable } from 'react-sortablejs';

const { Text } = Typography;

function CreateUUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export const FeaturesListFormItem = ({ productDetails, onChange }) => {
  const intl = useIntl();

  // Local State
  const [featureList, setFeatureList] = useState([]);

  useEffect(() => {
    if (productDetails) {
      let list = productDetails?.features?.map((feature, index) => {
        return {
          id: CreateUUID(),
          value: feature,
        };
      });
      setFeatureList(list || []);
    }
  }, [productDetails]);

  const handleChange = (e, feature, index) => {
    let list = featureList;
    let changedFeature = { ...feature, value: e.target.value };
    list[index] = changedFeature;
    setFeatureList(list);
    handleCallback(list);
  };
  const handleAddClick = () => {
    let list = [...featureList, { id: CreateUUID(), value: '' }];
    setFeatureList(list);
    handleCallback(list);
  };

  const handleDeleteClick = (index) => {
    let list = featureList.filter((x, i) => i !== index);
    setFeatureList(list);
    handleCallback(list);
  };

  const handleCallback = (list) => {
    onChange(list.filter((x) => x.value.trim() !== '').map((x) => x.value));
  };
  return (
    <React.Fragment>
      <Text>
        <FormattedMessage id='productDetails.featuresLabel' />
      </Text>
      <ReactSortable
        tag={'div'}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: '20px',
        }}
        list={featureList}
        setList={setFeatureList}
      >
        {featureList?.map((feature, index) => {
          return (
            <Form.Item
              key={`feature-${feature.id}`}
              style={{ marginBottom: '5px', width: '100%' }}
              validateTrigger={['onChange', 'onBlur']}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: (
                    <FormattedMessage id='adminProductDetails.featureIsRequired' />
                  ),
                },
              ]}
              initialValue={feature.value}
            >
              <Input
                onChange={(e) => handleChange(e, feature, index)}
                style={{ width: '100%', marginBottom: '8px' }}
                defaultValue={`${feature.value ? feature.value : ''}`}
                addonBefore={<DashOutlined className={'draggable'} />}
                suffix={
                  <MinusCircleOutlined
                    title={intl.formatMessage({
                      id: 'general.delete',
                    })}
                    onClick={() => handleDeleteClick(index)}
                  />
                }
              />
            </Form.Item>
          );
        })}
      </ReactSortable>
      <Form.Item>
        <Button
          type='dashed'
          onClick={() => {
            handleAddClick();
          }}
          style={{ width: '100%' }}
        >
          <PlusOutlined />{' '}
          <FormattedMessage id='adminProductDetails.addFeature' />
        </Button>
      </Form.Item>
    </React.Fragment>
  );
};
