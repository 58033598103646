import createAxiosAction from '../createAxiosAction';
import {
  GET_CONTACT_US,
  GET_CONTACT_US_SETTINGS,
  INSERT_CONTACT_US_NOTE,
  SEARCHING_CONTACT_US,
  SET_CONTACT_US,
  SET_DISTRIBUTOR,
  UPDATE_CONTACT_US_IS_RESOLVED,
  UPDATE_CONTACT_US_SETTINGS,
} from '../types';

export default {
  searchContactUs: (queryString) => {
    return createAxiosAction({
      url: '/api/v1/contactus/search',
      queryString,
      startAction: SEARCHING_CONTACT_US,
    });
  },
  getSettings: () => {
    return createAxiosAction({
      url: '/api/v1/contactus/settings',
      startAction: GET_CONTACT_US_SETTINGS,
    });
  },
  updateSettings: (settings) => {
    return createAxiosAction({
      url: '/api/v1/contactus/settings',
      body: settings,
      method: 'PUT',
      startAction: UPDATE_CONTACT_US_SETTINGS,
    });
  },
  getContactUsById: (id) => {
    return createAxiosAction({
      url: '/api/v1/contactus/' + id,
      startAction: GET_CONTACT_US,
    });
  },
  updateContactUsIsResolved: (id, isResolved) => {
    return createAxiosAction({
      url: '/api/v1/contactus/isResolved',
      body: { id: id, isResolved: isResolved },
      method: 'PUT',
      startAction: UPDATE_CONTACT_US_IS_RESOLVED,
    });
  },
  insertContactUsNote: (note) => {
    return createAxiosAction({
      url: '/api/v1/contactus/contactUsNote',
      body: { ContactUsNote: note },
      method: 'POST',
      startAction: INSERT_CONTACT_US_NOTE,
    });
  },
  setContactUs: (contactUs) => (dispatch) => {
    dispatch({ type: SET_CONTACT_US, contactUs });
  },
};
