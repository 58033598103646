import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

export const getSeachFilterProps = ({
  setQueryParamFunction,
  queryParamValue,
  queryParamKey,
}) => {
  const inputRef = React.createRef();

  return {
    filterDropdown: ({ confirm }) => (
      <TableSearchFilter
        inputRef={inputRef}
        onReset={() => setQueryParamFunction(queryParamKey, undefined)}
        onSearch={(value) => setQueryParamFunction(queryParamKey, value)}
        confirm={confirm}
        defaultValue={queryParamValue}
      />
    ),
    filterIcon: () => (
      <SearchOutlined
        style={{ color: queryParamValue ? '#1890ff' : undefined }}
      />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => inputRef.current?.select(), 100);
      }
    },
  };
};

export const TableSearchFilter = ({
  inputRef,
  onSearch,
  onReset,
  confirm,
  defaultValue,
}) => {
  const intl = useIntl();
  const [value, setValue] = useState(defaultValue || '');

  const handleSearch = () => {
    confirm();
    onSearch(value);
  };

  const handleReset = () => {
    setValue('');
    confirm();
    onReset();
  };

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={inputRef}
        placeholder={`Search`}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onPressEnter={handleSearch}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type='primary'
          onClick={handleSearch}
          icon={<SearchOutlined />}
          size='small'
        >
          Search
        </Button>
        <Button onClick={handleReset} size='small'>
          Reset
        </Button>
      </Space>
    </div>
  );
};
