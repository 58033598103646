import createAxiosAction from '../createAxiosAction';
import {
  GET_CONTINENTS,
  SET_CONTINENT,
  UPDATE_CONTINENT,
  INSERT_CONTINENT,
} from '../types';

export default {
  getContinents: () => {
    return createAxiosAction({
      url: `/api/v1/continents`,
      startAction: GET_CONTINENTS,
    });
  },

  setContinent: (continent) => (dispatch) => {
    dispatch({ type: SET_CONTINENT, continent });
  },

  insertContinent: (continent) => {
    return createAxiosAction({
      method: 'POST',
      url: '/api/v1/continents',
      body: { Continent: continent },
      startAction: INSERT_CONTINENT,
    });
  },
  updateContinent: (continent) => {
    return createAxiosAction({
      method: 'PUT',
      url: '/api/v1/continents',
      body: { Continent: continent },
      startAction: UPDATE_CONTINENT,
    });
  },
};
