import { PageHeader, Button, Table, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { EditItemModal } from './EditItemModal';
import { useHistory, useLocation, Link } from 'react-router-dom';

export const ListView = ({
  pageTitle,
  hasAdd,
  hasEditListItem,
  listItems,
  hasOnBack = false,
  modalConfirmDelete,
  modalOnFinish,
}) => {
  const history = useHistory();
  const location = useLocation();

  const [columns, setColumns] = useState([]);

  const [state, setState] = useState({
    selectedItem: null,
    isVisible: false,
    event: null,
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getColumns();
    setLoading(false);
  }, []);

  const handleAddClick = (e) => {
    setState({
      ...state,
      selectedItem: null,
      isVisible: true,
      event: e.timeStamp,
    });
  };

  const handleEditActionClick = (e, listItem) => {
    setState({
      ...state,
      selectedItem: listItem,
      isVisible: true,
      event: e.timeStamp,
    });
  };

  const getColumns = () => {
    setColumns([
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (listItem) => (
          <Space style={{ marginLeft: '-2rem' }}>
            {hasEditListItem ? (
              <Button
                key='edit'
                onClick={(e) => handleEditActionClick(e, listItem)}
              >
                Edit
              </Button>
            ) : (
              <></>
            )}
            <Link
              to={{
                pathname: `${location.pathname}/${listItem.name}`,
                state: { listItem },
              }}
            >
              <Button key='view' type='primary'>
                View
              </Button>
            </Link>
          </Space>
        ),
      },
    ]);
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle} | Stiletto Tools Admin</title>
      </Helmet>
      <PageHeader
        title={pageTitle}
        onBack={hasOnBack ? () => history.goBack() : null}
        extra={
          hasAdd ? (
            [
              <Button
                key='add-new-list-item'
                type='primary'
                icon={<PlusOutlined />}
                onClick={(e) => handleAddClick(e)}
              >
                Add
              </Button>,
            ]
          ) : (
            <></>
          )
        }
      />
      <Table
        rowKey='id'
        dataSource={listItems || []}
        columns={columns}
        scroll={{ x: true }}
        loading={loading}
        pagination={false}
      />
      {hasEditListItem ? (
        <EditItemModal
          {...state}
          confirmDelete={modalConfirmDelete}
          onFinish={modalOnFinish}
        />
      ) : (
        <></>
      )}
    </>
  );
};
