import {
  GET_CONTINENTCOUNTRYSTORES,
  UPDATE_CONTINENTCOUNTRYSTORES,
} from '../types';
import { success } from '../utils';

export const initialState = {
  continentCountryStoresList: null,
  isContinentCountryStoresListLoading: false,
  continentCountryStores: null,
  iscontinentCountryStoresLoading: false,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_CONTINENTCOUNTRYSTORES:
      return {
        ...state,
        isContinentCountryStoresListLoading: true,
      };
    case success(GET_CONTINENTCOUNTRYSTORES):
      return {
        ...state,
        continentCountryStoresList: action.payload.data,
        isContinentCountryStoresListLoading: false,
      };
    case success(UPDATE_CONTINENTCOUNTRYSTORES):
      return {
        ...state,
        continentCountryStores: action.payload.data,
        continentCountryStoresList: null,
      };
    default:
      return state;
  }
};
