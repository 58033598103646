import SimpleMDE from 'react-simplemde-editor';
import styled from 'styled-components';

const StyledSimpleMDE = styled(SimpleMDE)`
  .editor-toolbar,
  .editor-statusbar,
  .cm-s-easymde {
    border-radius: 0;
  }
`;

export const MarkdownEditorMd = styled(StyledSimpleMDE)`
  .CodeMirror {
    min-height: 500px;
    max-height: 560px;
  }
  .CodeMirror-scroll {
    height: 560px;
  }
`;

export const MarkdownEditorSm = styled(StyledSimpleMDE)`
  .CodeMirror {
    min-height: 250px;
    max-height: 350px;
  }
  .CodeMirror-scroll {
    height: 350px;
  }
`;

export const MarkdownEditorXs = styled(StyledSimpleMDE)`
  .CodeMirror {
    min-height: 150px;
    max-height: 280px;
  }
  .CodeMirror-scroll {
    height: 280px;
  }
`;
