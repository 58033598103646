import { FilterOutlined } from '@ant-design/icons';
import { Button, Select, Space } from 'antd';
import React, { useState } from 'react';

const { Option } = Select;

export const getDropDownFilterProps = ({
  setQueryParamFunction,
  queryParamValue,
  queryParamKey,
  options,
}) => {
  const inputRef = React.createRef();

  return {
    filterDropdown: ({ confirm }) => (
      <TableDropDownFilter
        inputRef={inputRef}
        onReset={() => setQueryParamFunction(queryParamKey, undefined)}
        onSubmit={(value) => setQueryParamFunction(queryParamKey, value)}
        confirm={confirm}
        defaultValue={queryParamValue}
        options={options}
      />
    ),
    filterIcon: () => (
      <FilterOutlined
        style={{ color: queryParamValue ? '#1890ff' : undefined }}
      />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => inputRef.current?.focus(), 100);
      }
    },
  };
};

export const TableDropDownFilter = ({
  inputRef,
  onSubmit,
  onReset,
  confirm,
  defaultValue,
  options,
}) => {
  const [value, setValue] = useState(defaultValue || []);

  const handleChange = (selectedValue) => {
    setValue(selectedValue);
    onSubmit(selectedValue);
  };

  const handleReset = () => {
    setValue([]);
    confirm();
    onReset();
  };

  const dropDownOptions = options?.map((option, index) => {
    return (
      <Option key={`${option.value}-${index}`} value={option.value}>
        {option.label}
      </Option>
    );
  });

  return (
    <div style={{ padding: 8 }}>
      <Select
        ref={inputRef}
        mode='multiple'
        allowClear
        style={{ width: 300, marginBottom: 8, display: 'block' }}
        placeholder='Select an option...'
        value={value}
        onChange={handleChange}
        showArrow
      >
        {dropDownOptions}
      </Select>
      <Space>
        <Button onClick={handleReset} size='small'>
          Reset
        </Button>
      </Space>
    </div>
  );
};
