import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Input, Radio, Select } from 'antd';

const yesNoOptions = [
  { label: <FormattedMessage id='general.yes' />, value: true },
  { label: <FormattedMessage id='general.no' />, value: false },
  { label: <FormattedMessage id='general.notApplicable' />, value: '' },
];

export const MetadataFormItem = ({
  formRef,
  productDetails,
  metadataKey,
  type,
  options,
}) => {
  if (!productDetails && !formRef.current) return null;

  return (
    <Form.Item
      name={['metadata', metadataKey]}
      initialValue={productDetails?.metadata[metadataKey]}
    >
      {type === 'yesNo' && (
        <Radio.Group
          defaultValue={productDetails?.metadata[metadataKey] || ''}
          optionType='button'
          buttonStyle='solid'
          options={yesNoOptions}
          onChange={(e) =>
            formRef.current.setFieldsValue({
              metadata: { [metadataKey]: e.target.value },
            })
          }
        />
      )}
      {type === 'input' && (
        <Input
          defaultValue={productDetails?.metadata[metadataKey]}
          onChange={(e) =>
            formRef.current.setFieldsValue({
              metadata: { [metadataKey]: e.target.value },
            })
          }
        />
      )}
      {type === 'select' && (
        <Select
          defaultValue={productDetails?.metadata[metadataKey]}
          optionFilterProp='children'
          onChange={(value) =>
            formRef.current.setFieldsValue({
              metadata: { [metadataKey]: value },
            })
          }
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {options}
        </Select>
      )}
    </Form.Item>
  );
};
