import createAxiosAction from '../createAxiosAction';
import { GET_LOCATION_DATA } from '../types';

export default {
  getLocationData: () => {
    return createAxiosAction({
      url: '/api/v1/config/locationdata',
      startAction: GET_LOCATION_DATA,
    });
  },
};
