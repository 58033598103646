import {
  GET_CONTACT_US,
  GET_CONTACT_US_SETTINGS,
  INSERT_CONTACT_US_NOTE,
  SEARCHING_CONTACT_US,
  SET_CONTACT_US,
} from '../types';
import { success } from '../utils';

export const initialState = {
  searchResultsLoading: false,
  searchResults: null,
  settings: null,
  contactUs: null,
  contactUsNotes: [],
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case SEARCHING_CONTACT_US:
      return {
        ...state,
        searchResultsLoading: true,
      };
    case success(SEARCHING_CONTACT_US):
      return {
        ...state,
        searchResultsLoading: false,
        searchResults: action.payload,
      };
    case success(GET_CONTACT_US_SETTINGS):
      return {
        ...state,
        settings: action.payload.data,
      };
    case success(GET_CONTACT_US):
      return {
        ...state,
        contactUs: action.payload.data,
        contactUsNotes: action.payload.data.contactUsNotes,
      };
    case success(INSERT_CONTACT_US_NOTE):
      return {
        ...state,
        contactUsNotes: action.payload.data,
      };
    case SET_CONTACT_US:
      return {
        ...state,
        contactUs: action.contactUs,
        contactUsNotes: action.contactUs?.contactUsNotes,
      };
    default:
      return state;
  }
};
