import createAxiosAction from '../createAxiosAction';
import {
  GET_CONTINENTCOUNTRIES,
  UPDATE_CONTINENTCOUNTRIES,
  INSERT_CONTINENTCOUNTRIES,
} from '../types';

export default {
  getContinentCountriesById: (continentId) => {
    return createAxiosAction({
      url: `/api/v1/continentCountries/${continentId}`,
      startAction: GET_CONTINENTCOUNTRIES,
    });
  },

  insertContinentCountries: (continentCountries) => {
    return createAxiosAction({
      method: 'POST',
      url: '/api/v1/continentCountries',
      body: { ContinentCountries: continentCountries },
      startAction: INSERT_CONTINENTCOUNTRIES,
    });
  },
  updateContinentCountries: (continentCountries) => {
    return createAxiosAction({
      method: 'PUT',
      url: '/api/v1/continentCountries',
      body: { ContinentCountries: continentCountries },
      startAction: UPDATE_CONTINENTCOUNTRIES,
    });
  },
};
