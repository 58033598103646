import React from 'react';
import { DatePicker, Form, Input, Space } from 'antd';
import moment from 'moment';

export const GeneralInformationFormFields = ({ selectedContent }) => {
  return (
    <React.Fragment>
      <Form.Item
        name='description'
        label='Description'
        initialValue={selectedContent?.description}
        rules={[{ required: true, message: 'Required' }]}
      >
        <Input placeholder='Basic usage' />
      </Form.Item>
      <Space>
        <Form.Item
          name='effectiveStart'
          label='Effective Start'
          initialValue={
            selectedContent?.effectiveStart
              ? moment(selectedContent?.effectiveStart)
              : null
          }
        >
          <DatePicker showTime />
        </Form.Item>
        <Form.Item
          name='effectiveEnd'
          label='Effective End'
          initialValue={
            selectedContent?.effectiveEnd
              ? moment(selectedContent?.effectiveEnd)
              : null
          }
        >
          <DatePicker showTime />
        </Form.Item>
      </Space>
    </React.Fragment>
  );
};
