import createAxiosAction from '../createAxiosAction';
import {
  DELETE_MISC_FILE,
  GET_FILES,
  INSERT_MISC_FILE,
  UPLOAD_MISC_FILE,
} from '../types';

export default {
  getFiles: () => {
    return createAxiosAction({
      method: 'GET',
      url: `/api/v1/files`,
      startAction: GET_FILES,
    });
  },

  uploadFile: (file) => {
    let form = new FormData();

    form.append('file', file);
    form.append('name', file.name);

    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/files/upload`,
      body: form,
      startAction: UPLOAD_MISC_FILE,
      contentType: 'multipart/form-data',
    });
  },

  insertFile: (data) => {
    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/files`,
      body: { File: data },
      startAction: INSERT_MISC_FILE,
    });
  },
  deleteFile: (id) => {
    return createAxiosAction({
      url: `/api/v1/files/${id}`,
      method: 'DELETE',
      startAction: DELETE_MISC_FILE,
    });
  },
};
