import { GET_CONTINENTCOUNTRIES, UPDATE_CONTINENTCOUNTRIES } from '../types';
import { success } from '../utils';

export const initialState = {
  continentCountriesList: null,
  isContinentCountriesListLoading: false,
  continentCountries: null,
  isContinentCountriesLoading: false,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_CONTINENTCOUNTRIES:
      return {
        ...state,
        isContinentCountriesListLoading: true,
      };
    case success(GET_CONTINENTCOUNTRIES):
      return {
        ...state,
        continentCountriesList: action.payload.data,
        isContinentCountriesListLoading: false,
      };
    case success(UPDATE_CONTINENTCOUNTRIES):
      return {
        ...state,
        continentCountries: action.payload.data,
        continentCountriesList: null,
      };
    default:
      return state;
  }
};
