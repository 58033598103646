import createAxiosAction from '../createAxiosAction';
import {
  GET_WARRANTY_CLAIMS_SETTINGS,
  UPDATE_WARRANTY_SETTINGS,
  GET_WARRANTY_CLAIM,
  SEARCHING_WARRANTY_CLAIMS,
  UPDATE_WARRANTY_CLAIM_IS_RESOLVED,
  INSERT_WARRANTY_CLAIM_NOTE,
  SET_WARRANTY_CLAIM,
} from '../types';

export default {
  searchWarrantyClaims: (queryString) => {
    return createAxiosAction({
      url: '/api/v1/warrantyclaims/search',
      queryString,
      startAction: SEARCHING_WARRANTY_CLAIMS,
    });
  },

  getWarrantyClaimById: (id) => {
    return createAxiosAction({
      url: '/api/v1/warrantyclaims/' + id,
      startAction: GET_WARRANTY_CLAIM,
    });
  },
  getSettings: () => {
    return createAxiosAction({
      url: '/api/v1/warrantyclaims/settings',
      startAction: GET_WARRANTY_CLAIMS_SETTINGS,
    });
  },
  updateSettings: (settings) => {
    return createAxiosAction({
      url: '/api/v1/warrantyclaims/settings',
      body: settings,
      method: 'PUT',
      startAction: UPDATE_WARRANTY_SETTINGS,
    });
  },

  updateWarrantyClaimIsResolved: (id, isResolved) => {
    return createAxiosAction({
      url: '/api/v1/warrantyclaims/isResolved',
      body: { id: id, isResolved: isResolved },
      method: 'PUT',
      startAction: UPDATE_WARRANTY_CLAIM_IS_RESOLVED,
    });
  },
  insertWarrantyClaimNote: (warrantyClaimNote) => {
    return createAxiosAction({
      url: '/api/v1/warrantyclaims/warrantyClaimNote',
      body: { WarrantyClaimNote: warrantyClaimNote },
      method: 'POST',
      startAction: INSERT_WARRANTY_CLAIM_NOTE,
    });
  },
  setWarrantyClaim: (warrantyClaim) => (dispatch) => {
    dispatch({ type: SET_WARRANTY_CLAIM, warrantyClaim });
  },
};
