import * as React from 'react';
import { SimplePageContentForm } from '../../../components';

export const EditWarrantyPolicy = () => {
  return (
    <SimplePageContentForm
      pageTitle='Warranty Policy'
      dataKey='warranty-policy'
      slug='/warranty-policy'
    />
  );
};
