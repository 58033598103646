import axios from 'axios';
import { loginRequest, msalInstance } from '../helpers/azureAd';
import toQueryString from '../helpers/toQueryString';
import { error, success } from './utils';

const createAxiosAction = ({
  actionPayload,
  startAction,
  method,
  url,
  body,
  contentType = 'application/json',
  queryString = {},
}) => async (dispatch) => {
  dispatch({ ...actionPayload, type: startAction });

  const accounts = msalInstance.getAllAccounts();

  const { idToken } = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: accounts[0],
  });

  let queryStringToAppend = toQueryString(queryString);

  try {
    const response = await axios.request({
      method: method,
      data: body,
      url: queryStringToAppend !== '' ? `${url}${queryStringToAppend}` : url,
      params: queryString,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    dispatch({
      ...actionPayload,
      type: success(startAction),
      payload: response.data,
    });

    return response;
  } catch (payload) {
    dispatch({ ...actionPayload, type: error(startAction) });
    return Promise.reject(payload);
  }
};

export default createAxiosAction;
