import {
  DELETE_RETAIL_STORE,
  INSERT_RETAIL_STORE,
  SEARCHING_RETAIL_STORES,
  UPDATE_RETAIL_STORE,
} from '../types';
import { success } from '../utils';

export const initialState = {
  searchResultsLoading: false,
  searchResults: null,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case success(DELETE_RETAIL_STORE):
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.filter((x) => x.id !== action.id),
        },
      };

    case success(INSERT_RETAIL_STORE):
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: [action.retailStore, ...state.searchResults.data],
        },
      };

    case SEARCHING_RETAIL_STORES:
      return {
        ...state,
        searchResultsLoading: true,
      };

    case success(SEARCHING_RETAIL_STORES):
      return {
        ...state,
        searchResultsLoading: false,
        searchResults: action.payload,
      };

    case success(UPDATE_RETAIL_STORE):
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.map((retailStore) =>
            retailStore.id === action.retailStore.id
              ? { ...action.retailStore, updatedAt: new Date() }
              : retailStore
          ),
        },
      };
    default:
      return state;
  }
};
