import {
  Button,
  Form,
  Input,
  message,
  Upload,
  Space,
  PageHeader,
  Layout,
  Spin,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactMarkdown from 'react-markdown';
import {
  Container,
  GeneralInformationFormFields,
  MarkdownEditorSm,
} from '../../components/';
import { DeleteFilled, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { pageContentsActions } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getCDN } from '../../helpers/cdnHelper';
import { useHistory, useParams } from 'react-router';
import { Helmet } from 'react-helmet';

const { Content } = Layout;

export const EditWarranty = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const formRef = useRef();
  const content = useSelector((state) => state.pageContents.pageContent);

  const [isSaving, setIsSaving] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [warrantyReturnPolicyPDF, setWarrantyReturnPolicyPDF] = useState(null);
  const [warrantyEvaluationAidPDF, setWarrantyEvaluationAidPDF] =
    useState(null);

  useEffect(() => {
    if (!content) {
      if (id !== 'new') {
        dispatch(pageContentsActions.getPageContentById(id));
      }
    }

    setSelectedContent(content);
    setWarrantyReturnPolicyPDF(content?.content?.warrantyReturnPolicyPDF);
    setWarrantyEvaluationAidPDF(content?.content?.warrantyEvaluationAidPDF);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, content]);

  const warrantyReturnPolicyPDFProp = {
    beforeUpload: (file) => {
      dispatch(pageContentsActions.uploadMiscFile(file))
        .then((res) => {
          setWarrantyReturnPolicyPDF(`${getCDN()}/misc/${res.data.data}`);

          if (formRef?.current) {
            formRef.current.setFieldsValue({
              content: {
                warrantyReturnPolicyPDF: `${getCDN()}/misc/${res.data.data}`,
              },
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            message.error('Unsupported File Type');
          } else {
            message.error('An Error has occured. Please try again');
          }
        });

      return false;
    },
    warrantyReturnPolicyPDF,
  };

  const warrantyEvaluationAidPDFProp = {
    beforeUpload: (file) => {
      dispatch(pageContentsActions.uploadMiscFile(file))
        .then((res) => {
          setWarrantyEvaluationAidPDF(`${getCDN()}/misc/${res.data.data}`);

          if (formRef?.current) {
            formRef.current.setFieldsValue({
              content: {
                warrantyEvaluationAidPDF: `${getCDN()}/misc/${res.data.data}`,
              },
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            message.error('Unsupported File Type');
          } else {
            message.error('An Error has occured. Please try again');
          }
        });

      return false;
    },
    warrantyEvaluationAidPDF,
  };

  const uploadButton = (
    <Button icon={<UploadOutlined />}>Click to upload</Button>
  );

  const onRemoveEvaluationPDF = () => {
    setWarrantyEvaluationAidPDF(null);
    formRef.current.setFieldsValue({
      content: {
        warrantyEvaluationAidPDF: null,
      },
    });
  };

  const onRemovePolicyPDF = () => {
    setWarrantyReturnPolicyPDF(null);
    formRef.current.setFieldsValue({
      content: {
        warrantyReturnPolicyPDF: null,
      },
    });
  };

  const onFinish = (values) => {
    setIsSaving(true);

    let content;
    content = {
      ...selectedContent,
      ...values,
      content: {
        ...values?.content,
      },
    };

    if (id === 'new') {
      // Add
      let newContent = content;
      newContent = { ...newContent, dataKey: 'warranty', slug: '/' };
      dispatch(pageContentsActions.addPageContent(newContent))
        .then(() => {
          setIsSaving(false);
          dispatch(pageContentsActions.getPageContents('warranty'));
          message.success('Successfully Saved!');
          history.push('/warranty');
        })
        .catch(() => {
          setIsSaving(false);
          message.error('An error has occurred');
        });
    } else {
      // Edit
      dispatch(pageContentsActions.updatePageContent(content))
        .then(() => {
          setIsSaving(false);
          dispatch(pageContentsActions.getPageContents('warranty'));
          message.success('Successfully Saved!');
          history.push('/warranty');
        })
        .catch(() => {
          setIsSaving(false);
          message.error('An error has occurred');
        });
    }
  };

  return (
    <Container>
      <Helmet>
        <title>{id === 'new' ? `Add New Warranty` : `Edit Warranty`}</title>
      </Helmet>
      <Content>
        {((!selectedContent && id === 'new') || selectedContent) && (
          <Form
            name='dynamic_form_nest_item'
            onFinish={onFinish}
            autoComplete='off'
            hideRequiredMark={false}
            layout={'vertical'}
            ref={formRef}
          >
            <PageHeader
              style={{ padding: 0 }}
              onBack={() => history.goBack()}
              title={id === 'new' ? `Add New Warranty` : `Edit Warranty`}
              extra={[
                <Spin
                  size='default'
                  wrapperClassName='button1-spinner'
                  spinning={isSaving}
                >
                  <Button
                    key={'Button1'}
                    type='primary'
                    onClick={() => formRef?.current?.submit()}
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                </Spin>,
              ]}
            />
            <GeneralInformationFormFields selectedContent={selectedContent} />
            <Form.Item
              name={['content', 'warrantyReturnPolicyLinkText']}
              label='Warranty Return Policy Link Text'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={
                selectedContent?.content?.warrantyReturnPolicyLinkText
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['content', 'warrantyReturnPolicyPDF']}
              label='Warranty Return Policy PDF'
              valuePropName={['content', 'warrantyReturnPolicyPDF']}
              getValueFromEvent={warrantyReturnPolicyPDF}
              rules={[{ required: false, message: 'Required' }]}
              initialValue={selectedContent?.content?.warrantyReturnPolicyPDF}
            >
              <Space direction={'horizontal'}>
                {warrantyReturnPolicyPDF && (
                  <React.Fragment>
                    <a
                      href={warrantyReturnPolicyPDF}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {warrantyReturnPolicyPDF?.slice(
                        warrantyReturnPolicyPDF?.lastIndexOf('/') + 1
                      )}
                    </a>

                    <DeleteFilled onClick={() => onRemovePolicyPDF()} />
                  </React.Fragment>
                )}
              </Space>
              <br />
              <Upload
                {...warrantyReturnPolicyPDFProp}
                fileList={[]}
                multiple={false}
                accept={'.pdf'}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
            <Form.Item
              name={['content', 'warrantyEvaluationAidLinkText']}
              label='Warranty Aid Link Text'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={
                selectedContent?.content?.warrantyEvaluationAidLinkText
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['content', 'warrantyEvaluationAidPDF']}
              label='Warranty Evaluation Aid PDF'
              valuePropName={['content', 'warrantyEvaluationAidPDF']}
              getValueFromEvent={warrantyEvaluationAidPDF}
              rules={[{ required: false, message: 'Required' }]}
              initialValue={warrantyEvaluationAidPDF}
            >
              <Space direction={'horizontal'}>
                {warrantyEvaluationAidPDF && (
                  <React.Fragment>
                    <a
                      href={warrantyEvaluationAidPDF}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {warrantyEvaluationAidPDF?.slice(
                        warrantyEvaluationAidPDF?.lastIndexOf('/') + 1
                      )}
                    </a>

                    <DeleteFilled onClick={() => onRemoveEvaluationPDF()} />
                  </React.Fragment>
                )}
              </Space>
              <br />
              <Upload
                {...warrantyEvaluationAidPDFProp}
                fileList={[]}
                multiple={false}
                accept={'.pdf'}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
            <Form.Item
              name={['content', 'body']}
              label='Body'
              initialValue={selectedContent?.content?.body}
              rules={[{ required: true, message: 'Required' }]}
            >
              <MarkdownEditorSm
                options={{
                  spellChecker: false,
                  previewRender(value) {
                    return ReactDOMServer.renderToString(
                      <ReactMarkdown source={value} />
                    );
                  },
                }}
              />
            </Form.Item>
          </Form>
        )}
      </Content>
    </Container>
  );
};
