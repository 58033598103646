import React, { useState } from 'react';
import { Divider, Form, Input, Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import { ProductImage } from '../../../components/ProductImage';
import { getCDN } from '../../../helpers/cdnHelper';
import { pageContentsActions } from '../../../store';
import { FeaturedItemButtonWrapper } from './styled';

export const FeaturedItem = ({ remove, field, storageLocation }) => {
  const dispatch = useDispatch();

  const [featuredImageUrl, setFeaturedImageUrl] = useState('');

  const featuredImageProp = {
    beforeUpload: async (file) => {
      const res = await dispatch(
        pageContentsActions.uploadImage(file, storageLocation)
      ).catch((error) => {
        if (error.response.status === 400) {
          message.error('Unsupported File Type');
        } else {
          message.error('An Error has occurred. Please try again');
        }
      });
      setFeaturedImageUrl(
        `${getCDN()}/images/${storageLocation}/${res.data.data}`
      );

      return false;
    },
    featuredImageUrl,
  };

  const handleFeatureImageProps = (e) => {
    if (e && typeof e === 'string' && !featuredImageUrl) {
      setFeaturedImageUrl(e);
    } else if (featuredImageUrl) {
      setFeaturedImageUrl(featuredImageUrl);
    }
  };

  return (
    <>
      <Divider orientation='left'>{`Featured Item ${field.name + 1}`}</Divider>
      <FeaturedItemButtonWrapper>
        <Button type='danger' onClick={() => remove(field.name)}>
          Remove Item
        </Button>
      </FeaturedItemButtonWrapper>
      <Form.Item
        {...field}
        name={[field.name, 'featuredTitle']}
        fieldKey={[field.fieldKey, 'featuredTitle']}
        label='Title'
        rules={[{ required: true, message: 'Required' }]}
        key={`featuredTitle${field.fieldKey}`}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...field}
        name={[field.name, 'featuredSubText']}
        fieldKey={[field.fieldKey, 'featuredSubText']}
        label='Sub Title'
        key={`featuredSubText${field.fieldKey}`}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...field}
        name={[field.name, 'featuredHyperlinkText']}
        fieldKey={[field.fieldKey, 'featuredHyperlinkText']}
        label='Hyperlink Text'
        key={`featuredHyperlinkText${field.fieldKey}`}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...field}
        name={[field.name, 'featuredHyperlinkUrl']}
        fieldKey={[field.fieldKey, 'featuredHyperlinkUrl']}
        label='Hyperlink Url'
        rules={[{ required: true, message: 'Required' }]}
        key={`featuredHyperlinkUrl${field.fieldKey}`}
      >
        <Input addonBefore='https://stiletto.com/' />
      </Form.Item>
      <Form.Item
        {...field}
        name={[field.name, 'featuredImage']}
        fieldKey={[field.fieldKey, 'featuredImage']}
        label='Image'
        getValueFromEvent={() => {
          return featuredImageUrl;
        }}
        getValueProps={(e) => handleFeatureImageProps(e)}
        rules={[{ required: true, message: 'Required' }]}
        key={`featuredImage${field.fieldKey}`}
      >
        <Upload
          {...featuredImageProp}
          listType='picture'
          fileList={[]}
          multiple={false}
          accept={'.png,.jpeg,.jpg'}
        >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>
      <ProductImage imageUrl={featuredImageUrl} maxHeight={300} />
    </>
  );
};
