import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Input,
  PageHeader,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import { Pager } from '../../../components/Pager';
import { getDropDownFilterProps } from '../../../components/TableFilters';
import { productsActions } from '../../../store';
import { useHistory } from 'react-router';

const { Search } = Input;

export const SearchProducts = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchRef = useRef();

  // app state
  const searchResults = useSelector(
    (state) => state.products.searchResults,
    shallowEqual
  );

  // local state
  const [loading, setLoading] = useState(true);
  const [queryParams, setQueryParams] = useQueryParams({
    page: withDefault(NumberParam, 1),
    query: StringParam,
    handleType: ArrayParam,
    handleMaterial: ArrayParam,
    isDiscontinued: StringParam,
    isHidden: StringParam,
  });
  const [searchInput, setSearchInput] = useState(queryParams.query);

  useEffect(() => {
    setLoading(true);
    dispatch(productsActions.searchProducts(queryParams)).finally(() =>
      setLoading(false)
    );
  }, [dispatch, window.location.search]);

  /***
   * When the page changes, scroll back to the top
   */
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [queryParams.page]);

  /***
   * updateQueryParam updates a single param in the query object
   * @param key: the query param to update
   * @param value: the new value of the query param
   */
  const updateQueryParam = (key, value) => {
    let updatedQuery = { ...queryParams };
    updatedQuery[key] = value;
    setQueryParams(updatedQuery);
  };

  /***
   * clearAll wipes out all query string parameters and resets the table
   */
  const clearAll = () => {
    setQueryParams({}, 'push');
  };

  const handleAddClick = () => {
    dispatch(productsActions.setProductDetails(null));

    history.push('/products/new');
  };

  const columns = [
    {
      title: 'SKU',
      key: 'sku',
      render: (product) => (
        <Space>
          {!product.imageUrls ||
            (product.imageUrls.length === 0 && (
              <Tooltip title='This product has no images'>
                <ExclamationCircleOutlined style={{ color: 'red' }} />
              </Tooltip>
            ))}
          {product.skuAlias}
        </Space>
      ),
      sortDirections: ['descend'],
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Handle Type',
      dataIndex: ['metadata', 'handleType'],
      ...getDropDownFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'handleType',
        queryParamValue: queryParams.handleType,
        options: [
          {
            label: 'Straight',
            value: 'Straight',
          },
          {
            label: 'Curved',
            value: 'Curved',
          },
          {
            label: 'Hybrid',
            value: 'Hybrid',
          },
        ],
      }),
    },
    {
      title: 'Handle Material',
      dataIndex: ['metadata', 'handleMaterial'],
      ...getDropDownFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'handleMaterial',
        queryParamValue: queryParams.handleMaterial,
        options: [
          {
            label: 'Hickory',
            value: 'Hickory',
          },
          {
            label: 'Fiberglass',
            value: 'Fiberglass',
          },
          {
            label: 'Titanium',
            value: 'Titanium',
          },
        ],
      }),
    },
    {
      title: 'Discontinued',
      key: 'isDiscontinued',
      render: (text, record) =>
        record.isDiscontinued ? (
          <Tag color='volcano'>Yes</Tag>
        ) : (
          <Tag color='geekblue'>No</Tag>
        ),
      ...getDropDownFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'isDiscontinued',
        queryParamValue: queryParams.isDiscontinued,
        options: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
      }),
    },
    {
      title: 'Hidden',
      key: 'isHidden',
      render: (text, record) =>
        record.isHidden ? (
          <Tag color='green'>Yes</Tag>
        ) : (
          <Tag color='geekblue'>No</Tag>
        ),
      ...getDropDownFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'isHidden',
        queryParamValue: queryParams.isHidden,
        options: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
      }),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, product) => (
        <Space size='middle'>
          <Link
            to={`/products/${product.skuAlias}`}
            onClick={() => dispatch(productsActions.setProductDetails(product))}
          >
            <Button>Edit</Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>Products | Stiletto Tools Admin</title>
      </Helmet>
      <PageHeader
        title='Products'
        extra={[
          <Button
            key='add-new'
            type='primary'
            icon={<PlusOutlined />}
            onClick={handleAddClick}
          >
            Add new
          </Button>,
        ]}
      />
      <Row gutter={[16, 16]}>
        <Col xs={18} sm={20} md={16} lg={12} xl={8}>
          <Search
            ref={searchRef}
            placeholder={'Search...'}
            onSearch={(value) =>
              setQueryParams({ ...queryParams, query: value })
            }
            defaultValue={queryParams.query}
            loading={loading}
            style={{ width: '100%' }}
          />
        </Col>
        <Col>
          <Button onClick={clearAll}>Reset</Button>
        </Col>
      </Row>
      <Table
        rowKey='sku'
        dataSource={searchResults?.data || []}
        columns={columns}
        scroll={{ x: true }}
        loading={loading}
        pagination={false}
      />
      {searchResults?.totalCount > 50 && (
        <Pager
          current={queryParams.page}
          pageSize={50}
          total={searchResults?.totalCount}
          showSizeChanger={false}
          onChange={(page) => setQueryParams({ page })}
        />
      )}
    </React.Fragment>
  );
};
