import createAxiosAction from '../createAxiosAction';
import {
  GET_CONTINENTCOUNTRYSTORES,
  UPDATE_CONTINENTCOUNTRYSTORES,
  INSERT_CONTINENTCOUNTRYSTORES,
} from '../types';

export default {
  getContinentCountryStoresByCountryId: (countryId) => {
    return createAxiosAction({
      url: `/api/v1/continentCountryStores/${countryId}`,
      startAction: GET_CONTINENTCOUNTRYSTORES,
    });
  },

  insertContinentCountryStores: (continentCountryStores) => {
    return createAxiosAction({
      method: 'POST',
      url: '/api/v1/continentCountryStores',
      body: { ContinentCountryStores: continentCountryStores },
      startAction: INSERT_CONTINENTCOUNTRYSTORES,
    });
  },
  updateContinentCountryStores: (continentCountryStores) => {
    return createAxiosAction({
      method: 'PUT',
      url: '/api/v1/continentCountryStores',
      body: { ContinentCountryStores: continentCountryStores },
      startAction: UPDATE_CONTINENTCOUNTRYSTORES,
    });
  },
};
