import { MenuOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Table,
  Upload,
} from 'antd';
import arrayMove from 'array-move';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { distributorsActions } from '../../../store';
import { ProductImage } from '../../../components/ProductImage';
import './styles.css';
import { getCDN } from '../../../helpers/cdnHelper';

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export const SearchDistributors = () => {
  const dispatch = useDispatch();

  //Refs
  const distributorFormRef = React.createRef();
  // app state
  const distributorsList = useSelector(
    (state) => state.distributors.distributorsList,
    shallowEqual
  );

  // local state
  const [distributors, setDistributors] = useState(distributorsList);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    selectedDistributor: null,
    selectedDistributorImageUrl: null,
    modalVisible: false,
  });

  useEffect(() => {
    dispatch(distributorsActions.getDistributors()).finally(() =>
      setLoading(false)
    );
  }, [dispatch]);

  useEffect(() => {
    if (distributorsList) setDistributors(distributorsList);
  }, [distributorsList]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      let distributorId = distributors[oldIndex].id;
      const newData = arrayMove(
        [].concat(distributors),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setDistributors(newData);

      // Update Distributor Sort Order
      dispatch(
        distributorsActions.updateDistributorsSortOrder(
          distributorId,
          oldIndex + 1,
          newIndex + 1
        )
      ).then(() => {
        message.success('Order has been saved');
        dispatch(distributorsActions.getDistributors());
      });
    }
  };

  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'move', color: '#999' }} />
  ));

  const columns = [
    {
      title: 'Order',
      key: 'order',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
    },
    {
      title: 'Site URL',
      key: 'siteUrl',
      render: (distributor) => (
        <a href={distributor.siteUrl} rel='noopener noreferrer' target='_blank'>
          {distributor.siteUrl}
        </a>
      ),
    },
    {
      title: 'Image',
      key: 'imageUrl',
      render: (distributor) => (
        <img
          src={distributor.imageUrl}
          alt={distributor.companyName}
          width={100}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (distributor) => (
        <Space size='middle'>
          <Button
            onClick={() =>
              setState({
                ...state,
                selectedDistributor: distributor,
                selectedDistributorImageUrl: distributor.imageUrl,
                modalVisible: true,
              })
            }
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    if (!distributors) return null;

    const index = distributors.findIndex(
      (x) => x.id === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const handleAddClick = () => {
    setState({
      ...state,
      selectedDistributorImageUrl: null,
      selectedDistributor: null,
      modalVisible: true,
    });
  };

  const confirmDelete = () => {
    let distributor = { ...state.selectedDistributor, isDeleted: true };
    dispatch(distributorsActions.updateDistributor(distributor))
      .then(() => {
        message.success('Successfully Deleted');
        dispatch(distributorsActions.getDistributors());
        setState({ ...state, modalVisible: false });
      })
      .catch(() => {
        message.error('An error has occurred');
      });
  };

  /***
   * onFinish will save the updated distributor info
   * @param values: the values of the form
   */
  const onFinish = (values) => {
    // Reconstruct Object to be use able by stored proc
    let obj = {
      // Id: selectedDistributor.id,
      CompanyName: values.companyName,
      ImageUrl: state.selectedDistributorImageUrl,
      SiteUrl: values.url,
    };

    if (state.selectedDistributor) {
      //Existing Distributor
      obj = {
        ...obj,
        Id: state.selectedDistributor.id,
        SortOrder: state.selectedDistributor.sortOrder,
        IsDeleted: state.selectedDistributor.isDeleted,
      };

      dispatch(distributorsActions.updateDistributor(obj)).then(() => {
        message.success('Successfully Updated!');
        dispatch(distributorsActions.getDistributors());
        // dispatch(distributorsActions.setIsDistributorModalVisible(false));
        setState({ ...state, modalVisible: false });
      });
    } else {
      // New Distributor
      dispatch(distributorsActions.insertDistributor(obj)).then(() => {
        message.success('Successfully Inserted!');
        dispatch(distributorsActions.getDistributors());
        // dispatch(distributorsActions.setIsDistributorModalVisible(false));
        setState({ ...state, modalVisible: false });
      });
    }
  };

  const uploadProps = {
    beforeUpload: (file) => {
      dispatch(distributorsActions.uploadDistributorImage(file))
        .then(() => {
          // Assign new image to UI
          setState({
            ...state,
            selectedDistributorImageUrl: `${getCDN()}/images/distributors/${
              file.name
            }`,
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            message.error('Unsupported File Type');
          } else {
            message.error('An Error has occured. Please try again');
          }
        });

      return false;
    },
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Distributors | Stiletto Tools Admin</title>
      </Helmet>
      <PageHeader
        title='Distributors'
        extra={[
          <Button
            key='add-new'
            type='primary'
            icon={<PlusOutlined />}
            onClick={handleAddClick}
          >
            Add new
          </Button>,
        ]}
      />
      <Table
        rowKey='id'
        dataSource={distributors || []}
        columns={columns}
        scroll={{ x: true }}
        pagination={false}
        loading={loading}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
      <Modal
        title={
          state.selectedDistributor ? 'Edit Distributor' : 'Add Distributor'
        }
        visible={state.modalVisible}
        onCancel={() => setState({ ...state, modalVisible: false })}
        footer={[
          <Button
            key='back'
            onClick={() => setState({ ...state, modalVisible: false })}
          >
            Cancel
          </Button>,
          state.selectedDistributor ? (
            <Popconfirm
              key='delete'
              title='Are you sure delete this distributor?'
              onConfirm={confirmDelete}
              // onCancel={cancel}
              okText='Yes'
              cancelText='No'
            >
              <Button
                style={{ marginLeft: '3px' }}
                key={'DeleteBtn'}
                type='danger'
              >
                Delete
              </Button>
              ,
            </Popconfirm>
          ) : null,
          <Button
            key='submit'
            type='primary'
            onClick={() => distributorFormRef.current.submit()}
          >
            Submit
          </Button>,
        ]}
        destroyOnClose
      >
        <Form
          name='dynamic_form_nest_item'
          onFinish={onFinish}
          autoComplete='off'
          hideRequiredMark={true}
          layout={'vertical'}
          ref={distributorFormRef}
        >
          <Row gutter={[16, 16]} justify='center'>
            <Col xs={24} md={24}>
              <ProductImage
                imageUrl={state.selectedDistributorImageUrl}
                maxHeight={300}
              />
              <Upload
                {...uploadProps}
                multiple={true}
                accept={'.png,.jpeg,.jpg'}
                fileList={[]}
              >
                <Button style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <UploadOutlined /> Upload Image
                </Button>
              </Upload>
              <p>
                <i>
                  It is recommended that you pick an image with a width or
                  height of at least 300 pixels.
                </i>
              </p>
              <Form.Item
                name='companyName'
                label='Name'
                initialValue={state.selectedDistributor?.companyName}
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='url'
                label='Url'
                initialValue={state.selectedDistributor?.siteUrl}
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
