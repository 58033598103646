import React from 'react';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { element } from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';

import { loginRequest, parseAccessToken } from '../helpers/azureAd';

export const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  useMsalAuthentication(InteractionType.Redirect);
  const { instance, accounts } = useMsal();
  const [isLoadingToken, setIsLoadingToken] = useState(true);
  const [userProfile, setUserProfile] = useState();

  useEffect(() => {
    if (!accounts.length) {
      return;
    }

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((token) => {
        let user = parseAccessToken(token.accessToken);
        user.roles = token.idTokenClaims.roles;
        setUserProfile(user);
        setIsLoadingToken(false);
      });
  }, [accounts, instance]);

  if (isLoadingToken) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        userProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: element.isRequired,
};
