import {
  GET_WARRANTY_CLAIM,
  SEARCHING_WARRANTY_CLAIMS,
  GET_WARRANTY_CLAIMS_SETTINGS,
  UPDATE_WARRANTY_CLAIM_IS_RESOLVED,
  INSERT_WARRANTY_CLAIM_NOTE,
  SET_CONTACT_US,
  SET_WARRANTY_CLAIM,
} from '../types';
import { success } from '../utils';

export const initialState = {
  searchResultsLoading: false,
  searchResults: null,
  warrantyClaim: null,
  warrantyClaimNotes: [],
  settings: null,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case SEARCHING_WARRANTY_CLAIMS:
      return {
        ...state,
        searchResultsLoading: true,
      };
    case success(SEARCHING_WARRANTY_CLAIMS):
      return {
        ...state,
        searchResultsLoading: false,
        searchResults: action.payload,
      };
    case success(GET_WARRANTY_CLAIM):
      return {
        ...state,
        warrantyClaim: action.payload.data,
        warrantyClaimNotes: action.payload.data.warrantyClaimNotes,
      };
    case success(GET_WARRANTY_CLAIMS_SETTINGS):
      return {
        ...state,
        settings: action.payload.data,
      };

    case success(UPDATE_WARRANTY_CLAIM_IS_RESOLVED):
      return {
        ...state,
      };
    case success(INSERT_WARRANTY_CLAIM_NOTE):
      return {
        ...state,
        warrantyClaimNotes: action.payload.data,
      };
    case SET_WARRANTY_CLAIM:
      return {
        ...state,
        warrantyClaim: action.warrantyClaim,
        warrantyClaimNotes: action.warrantyClaim?.contactUsNotes,
      };
    default:
      return state;
  }
};
