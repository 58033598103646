import styled from 'styled-components';

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 10px;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

  &&:hover {
    cursor: pointer;
  }
`;

export const Link = styled.a`
  color: blue;

  &&:hover {
    text-decoration: underline;
  }
`;
