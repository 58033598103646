import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Avatar,
  Button,
  Col,
  Comment,
  Descriptions,
  Divider,
  Form,
  Input,
  List,
  message,
  PageHeader,
  Row,
  Switch,
  Typography,
  Image,
} from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { FormattedDate } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';

import { warrantyClaimsActions } from '../../../../store';
import { ImageWrapper, Link } from './styled';
import useCurrentUserProfile from '../../../../hooks/useCurrentUserProfile';
import dayjs from 'dayjs';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

export const ViewWarrantyClaim = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfile = useCurrentUserProfile();

  // app state
  const warrantyClaim = useSelector(
    (state) => state.warrantyClaims.warrantyClaim
  );
  const warrantyClaimNotes = useSelector(
    (state) => state.warrantyClaims.warrantyClaimNotes
  );

  const [form] = Form.useForm();

  /***
   * On a hard refresh, we won't have a warranty claim loaded yet.
   * Go hit the api to load the product details.
   */
  useEffect(() => {
    // hit api and load product
    if (!warrantyClaim) {
      dispatch(warrantyClaimsActions.getWarrantyClaimById(id));
    }
  }, [dispatch, id, warrantyClaim]);

  /***
   * onFinish will save the updated warranty claim info
   * @param values: the values of the form
   */
  const onFinish = (isResolved) => {
    dispatch(
      warrantyClaimsActions.updateWarrantyClaimIsResolved(
        warrantyClaim?.id,
        isResolved
      )
    )
      .then(() => {
        message.success('Warranty claim has been updated');
      })
      .catch(() => {
        message.error('An error has occurred');
      });
  };

  const addComment = (value) => {
    let obj = {
      WarrantyClaimId: warrantyClaim?.id,
      Note: value?.comment,
      CreatedBy: userProfile?.unique_name,
      CreatedByOid: userProfile?.oid,
    };
    dispatch(warrantyClaimsActions.insertWarrantyClaimNote(obj))
      .then(() => {
        message.success('Successfully added note');

        // Clears out comments field
        form.setFieldsValue({ comment: '' });
      })
      .catch(() => {
        message.error('An error has occurred');
      });
  };

  const toolImages = warrantyClaim?.toolImageUrls?.map((item, index) => {
    return (
      <Col lg={8} md={12} s={24} xs={24} key={index} style={{ padding: 10 }}>
        <ImageWrapper>
          <Image
            src={item}
            style={{
              alignSelf: 'flex-start',
              display: 'block',
              maxWidth: 150,
              maxHeight: '100%',
              width: 'auto',
              height: 'auto',
            }}
          />
        </ImageWrapper>
      </Col>
    );
  });

  const reasonCodes = warrantyClaim?.reasonCodes?.map((item, index) => {
    return <li key={index}>{item}</li>;
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>Warranty Claim | Stiletto Tools Admin</title>
      </Helmet>

      {warrantyClaim && (
        <React.Fragment>
          <PageHeader
            style={{ padding: 0 }}
            onBack={() => history.goBack()}
            title='Warranty Claim'
          />
          <Divider />
          <Typography>
            <Row>
              <Col xs={24} md={12}>
                <Title level={2}>
                  {warrantyClaim?.claimNumber}{' '}
                  <Switch
                    key={'Switch1'}
                    size={'large'}
                    checkedChildren='Resolved'
                    unCheckedChildren='Open'
                    defaultChecked={warrantyClaim?.isResolved}
                    onChange={(e) => onFinish(e)}
                  />
                </Title>
                <Descriptions title='Customer Information'>
                  <Descriptions.Item label='*First name'>
                    {warrantyClaim?.firstName}
                  </Descriptions.Item>
                  <Descriptions.Item label='*Last Name'>
                    {warrantyClaim?.lastName}
                  </Descriptions.Item>
                  <Descriptions.Item label='Company'>
                    {warrantyClaim?.company}
                  </Descriptions.Item>
                  <Descriptions.Item label='*Phone'>
                    {warrantyClaim?.phoneNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label='*Email'>
                    {warrantyClaim?.emailAddress}
                  </Descriptions.Item>
                  <Descriptions.Item label='*Address 1'>
                    {warrantyClaim?.addressLine1}
                  </Descriptions.Item>
                  <Descriptions.Item label='Address 2'>
                    {warrantyClaim?.addressLine2}
                  </Descriptions.Item>
                  <Descriptions.Item label='*City'>
                    {warrantyClaim?.city}
                  </Descriptions.Item>
                  <Descriptions.Item label='*State'>
                    {warrantyClaim?.region}
                  </Descriptions.Item>
                  <Descriptions.Item label='*Zip'>
                    {warrantyClaim?.postalCode}
                  </Descriptions.Item>
                  <Descriptions.Item label='Country'>
                    {warrantyClaim?.country}
                  </Descriptions.Item>
                </Descriptions>

                <Descriptions title='Product Information'>
                  <Descriptions.Item label='*Product Model Number / Tool Name'>
                    {warrantyClaim?.modelNumberToolName}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                  <Descriptions.Item label='*Reason for Return'>
                    <ul>{reasonCodes}</ul>
                  </Descriptions.Item>
                </Descriptions>
                {warrantyClaim?.magnetSize && (
                  <Descriptions>
                    <Descriptions.Item label='*Magnet Size'>
                      {warrantyClaim?.magnetSize}
                    </Descriptions.Item>
                  </Descriptions>
                )}
                {warrantyClaim?.placeOfPurchase && (
                  <Descriptions>
                    <Descriptions.Item label='*Place of Purchase'>
                      {warrantyClaim?.placeOfPurchase}
                    </Descriptions.Item>
                  </Descriptions>
                )}
                {warrantyClaim?.dateOfPurchase && (
                  <Descriptions>
                    <Descriptions.Item label='*Date of Purchase'>
                      {dayjs(warrantyClaim?.dateOfPurchase).format(
                        'YYYY-MM-DD'
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                )}
                <Descriptions>
                  <Descriptions.Item label='*Comments'>
                    {warrantyClaim?.reasonForReturn}
                  </Descriptions.Item>
                </Descriptions>

                <Text>Images</Text>
                <Paragraph>Proof of Purchase</Paragraph>
                <Paragraph>
                  <Link
                    href={warrantyClaim?.proofOfPurchaseUrl}
                    target='_blank'
                    style={{ color: 'blue' }}
                  >
                    {warrantyClaim?.proofOfPurchaseUrl?.slice(
                      warrantyClaim?.proofOfPurchaseUrl?.lastIndexOf('/') + 1
                    )}
                  </Link>
                </Paragraph>

                <Paragraph>Tool Images</Paragraph>
                <Row>
                  <Image.PreviewGroup>{toolImages}</Image.PreviewGroup>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <Title level={4}>Notes</Title>

                <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                  <List
                    className='comment-list'
                    header={`${warrantyClaimNotes?.length} replies`}
                    itemLayout='horizontal'
                    dataSource={warrantyClaimNotes}
                    renderItem={(item) => (
                      <li>
                        <Comment
                          author={item.createdBy}
                          avatar={
                            'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
                          }
                          content={<p>{item.note}</p>}
                          datetime={
                            <FormattedDate
                              value={moment.utc(item?.createdAt).local()}
                            />
                          }
                        />
                      </li>
                    )}
                  />
                </div>
                <Form
                  name='dynamic_form_nest_item'
                  onFinish={addComment}
                  autoComplete='off'
                  hideRequiredMark={true}
                  layout={'vertical'}
                  form={form}
                >
                  <Comment
                    avatar={
                      <Avatar
                        src='https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
                        alt='Han Solo'
                      />
                    }
                    content={
                      <React.Fragment>
                        <Form.Item
                          name={'comment'}
                          rules={[{ required: true, message: 'Required' }]}
                        >
                          <TextArea rows={4} />
                        </Form.Item>
                        <Form.Item>
                          <Button htmlType='submit' type='primary'>
                            Add Comment
                          </Button>
                        </Form.Item>
                      </React.Fragment>
                    }
                  />
                </Form>
              </Col>
            </Row>
          </Typography>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
