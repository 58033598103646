export const getCDN = () => {
  let location = window.location.hostname;

  switch (location) {
    case 'admin.stiletto.com':
      return 'https://cdn.stiletto.com';
    case 'admin-staging.stiletto.com':
      return 'https://cdn-staging.stiletto.com';
    default:
      return 'https://cdn-dev.stiletto.com';
  }
};
