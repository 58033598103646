import React from 'react';
import { Form, InputNumber } from 'antd';

export const inchesFormatter = (value) => `${value + (value ? '"' : '')}`;
export const ouncesFormatter = (value) => `${value + (value ? 'oz' : '')}`;
export const ouncesParser = (value) =>
  `${value.replace('o', '').replace('z', '')}`;

export const DimensionsFormItem = ({
  productDetails,
  dimensionsKey,
  formatter,
  parser,
}) => {
  return (
    <Form.Item name={['dimensions', dimensionsKey]}>
      <InputNumber
        size={'large'}
        step={0.1}
        formatter={formatter}
        parser={parser}
      />
    </Form.Item>
  );
};
