import React from 'react';
import { Select } from 'antd';
import {
  inchesFormatter,
  ouncesFormatter,
  ouncesParser,
} from './Component/DimensionsFormItem';

const { Option } = Select;

// Setup which form items should appear in the dimensions section
export const dimensionsFormItems = [
  {
    dimensionsKey: 'depthInches',
    formatter: inchesFormatter,
  },
  {
    dimensionsKey: 'lengthInches',
    formatter: inchesFormatter,
  },
  {
    dimensionsKey: 'heightInches',
    formatter: inchesFormatter,
  },
  {
    dimensionsKey: 'widthInches',
    formatter: inchesFormatter,
  },
  {
    dimensionsKey: 'totalWeightOunces',
    formatter: ouncesFormatter,
    parser: ouncesParser,
  },
  {
    dimensionsKey: 'weightOfHeadOunces',
    formatter: ouncesFormatter,
    parser: ouncesParser,
  },
];

export const metadataFormItems = [
  {
    metadataKey: 'handleType',
    type: 'select',
    options: [
      <Option key={1} value=''>
        N/A
      </Option>,
      <Option key={2} value='Straight'>
        Straight
      </Option>,
      <Option key={3} value='Curved'>
        Curved
      </Option>,
      <Option key={4} value='Hybrid'>
        Hybrid
      </Option>,
    ],
  },
  {
    metadataKey: 'materialType',
    type: 'select',
    options: [
      <Option key={1} value=''>
        N/A
      </Option>,
      <Option key={2} value='Titanium'>
        Titanium
      </Option>,
      <Option key={3} value='Aluminum'>
        Aluminum
      </Option>,
    ],
  },
  {
    metadataKey: 'colorFamily',
    type: 'select',
    options: [
      <Option key={1} value=''>
        N/A
      </Option>,
      <Option key={2} value='Black'>
        Black
      </Option>,
      <Option key={3} value='Silver'>
        Silver
      </Option>,
    ],
  },
  {
    metadataKey: 'hammerType',
    type: 'select',
    options: [
      <Option key={1} value=''>
        N/A
      </Option>,
      <Option key={2} value='Curved Claw'>
        Curved Claw
      </Option>,
      <Option key={3} value='Drywall'>
        Drywall
      </Option>,
      <Option key={4} value='Mallet'>
        Mallet
      </Option>,
      <Option key={5} value='Straight Claw'>
        Straight Claw
      </Option>,
    ],
  },
  {
    metadataKey: 'handToolType',
    type: 'select',
    options: [
      <Option key={1} value=''>
        N/A
      </Option>,
      <Option key={2} value='Hammer'>
        Hammer
      </Option>,
      <Option key={3} value='Wrecking Bar'>
        Wrecking Bar
      </Option>,
    ],
  },
  {
    metadataKey: 'handleMaterial',
    type: 'select',
    options: [
      <Option key={1} value=''>
        N/A
      </Option>,
      <Option key={2} value='Titanium'>
        Titanium
      </Option>,
      <Option key={3} value='Fiberglass'>
        Fiberglass
      </Option>,
      <Option key={4} value='Hickory'>
        Hickory
      </Option>,
    ],
  },
  {
    metadataKey: 'face',
    type: 'select',
    options: [
      <Option key={1} value=''>
        N/A
      </Option>,
      <Option key={2} value='Milled'>
        Milled
      </Option>,
      <Option key={3} value='Smooth'>
        Smooth
      </Option>,
    ],
  },
  {
    metadataKey: 'numberOfNailSlots',
    type: 'input',
  },
  {
    metadataKey: 'replaceableHead',
    type: 'yesNo',
  },
  {
    metadataKey: 'sidingOrExtTrim',
    type: 'yesNo',
  },
  {
    metadataKey: 'remodeling',
    type: 'yesNo',
  },
  {
    metadataKey: 'framing',
    type: 'yesNo',
  },
  {
    metadataKey: 'poleBarn',
    type: 'yesNo',
  },
  {
    metadataKey: 'concrete',
    type: 'yesNo',
  },
  {
    metadataKey: 'scaffolding',
    type: 'yesNo',
  },
  {
    metadataKey: 'finish',
    type: 'yesNo',
  },
  {
    metadataKey: 'drywall',
    type: 'yesNo',
  },
  {
    metadataKey: 'roofing',
    type: 'yesNo',
  },
  {
    metadataKey: 'wireLathe',
    type: 'yesNo',
  },
];

export const categoryOptions = [
  'Striking Tools',
  'Pry Bars',
  'Replacement Parts',
  'Accessories',
  'Titanium Bars',
  'Handles',
  'Replacement Faces',
  'TI-BONE™ Technology',
  'Bolt Kits',
  'Specialty',
  'Titanium Poly Fiberglass',
  'Titanium Hickory',
  'Grips',
  'Faces',
  'Squares',
];
