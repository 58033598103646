import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Popconfirm,
  message,
} from 'antd';
import React, { useState, useEffect } from 'react';

export const EditItemModal = (props) => {
  //Refs
  const formRef = React.createRef();

  const { selectedItem, isVisible, event, confirmDelete, onFinish } = props;

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setModalVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const handleModalClose = () => {
    formRef.current.setFieldsValue({
      name: null,
    });
    setModalVisible(false);
  };

  const handleDelete = () => {
    let item = { ...selectedItem, isDeleted: true };
    confirmDelete(item)
      .then((messageText) => {
        message.success(messageText);
        setModalVisible(false);
      })
      .catch((e) => {
        message.error('An error has occurred');
      });
  };

  const handleFinish = (e) => {
    onFinish(e, selectedItem)
      .then((messageText) => {
        message.success(messageText);
        setModalVisible(false);
      })
      .catch((e) => {
        message.error('An error has occurred');
      });
  };

  return (
    <React.Fragment>
      <Modal
        title={selectedItem ? 'Edit Item' : 'Add Item'}
        visible={modalVisible}
        onCancel={() => handleModalClose()}
        footer={[
          <Button key='back' onClick={() => handleModalClose()}>
            Cancel
          </Button>,
          selectedItem ? (
            <Popconfirm
              key='delete'
              title='Are you sure delete this item?'
              onConfirm={handleDelete}
              okText='Yes'
              cancelText='No'
            >
              <Button
                style={{ marginLeft: '3px' }}
                key={'DeleteBtn'}
                type='danger'
              >
                Delete
              </Button>
              ,
            </Popconfirm>
          ) : null,
          <Button
            key='submit'
            type='primary'
            onClick={() => formRef.current.submit()}
          >
            Submit
          </Button>,
        ]}
        destroyOnClose
      >
        <Form
          name='form'
          onFinish={handleFinish}
          autoComplete='off'
          hideRequiredMark={false}
          layout={'vertical'}
          ref={formRef}
        >
          <Row gutter={[16, 16]} justify='center'>
            <Col xs={24} md={24}>
              <Form.Item
                name='name'
                label='Name'
                initialValue={selectedItem?.name}
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
