import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { PageContentsEditor } from '../../components';

export const CategoryPage = () => {
  const location = useLocation();

  const { listItem } = location.state;

  return (
    <>
      <PageContentsEditor
        pageTitle={`${listItem.name}`}
        dataKey={listItem.dataKey}
        hasOnBack={true}
      />
    </>
  );
};
