import { GET_FILES } from '../types';
import { success } from '../utils';

export const initialState = {
  fileList: [],
  isFileListLoading: false,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_FILES:
      return {
        ...state,
        isFileListLoading: true,
      };
    case success(GET_FILES):
      return {
        ...state,
        fileList: action.payload.data,
        isFileListLoading: false,
      };
    default:
      return state;
  }
};
