import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  PageHeader,
  Popconfirm,
  Space,
  Table,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { reasonCodesActions } from '../../store';

export const ReasonCodes = () => {
  const dispatch = useDispatch();

  //Refs
  const reasonCodeFormRef = React.createRef();
  // app state
  const reasonCodeList = useSelector(
    (state) => state.reasonCodes.reasonCodeList,
    shallowEqual
  );

  // local state
  const [reasonCodes, setReasonCodes] = useState(reasonCodeList);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    selectedReasonCode: null,
    modalVisible: false,
  });

  useEffect(() => {
    dispatch(reasonCodesActions.getReasonCodes()).finally(() =>
      setLoading(false)
    );
  }, [dispatch]);

  useEffect(() => {
    if (reasonCodeList) setReasonCodes(reasonCodeList);
  }, [reasonCodeList]);

  const columns = [
    {
      title: 'Reason Code',
      dataIndex: 'description',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (reasonCode) => (
        <Space size='middle'>
          <Button
            //Magnet Missing/Fell Out is a hardcoded value with dependencies in Stiletto Web.
            //We want Admins to see that this Reason Code exists, but they should not be able to edit it.
            disabled={
              reasonCode.description === 'Magnet Missing/Fell Out'
                ? true
                : false
            }
            onClick={() =>
              setState({
                ...state,
                selectedReasonCode: reasonCode,
                modalVisible: true,
              })
            }
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  const handleAddClick = () => {
    setState({
      ...state,
      selectedReasonCode: null,
      modalVisible: true,
    });
  };

  const confirmDelete = () => {
    let reasonCode = { ...state.selectedReasonCode, isDeleted: true };
    dispatch(reasonCodesActions.updateReasonCode(reasonCode))
      .then(() => {
        message.success('Successfully Deleted');
        dispatch(reasonCodesActions.getReasonCodes());
        setState({ ...state, modalVisible: false });
      })
      .catch(() => {
        message.error('An error has occurred');
      });
  };

  /***
   * onFinish will save the updated reason code info
   * @param values: the values of the form
   */
  const onFinish = (values) => {
    // Reconstruct Object to be use able by stored proc
    let obj = {
      Description: values.description,
    };

    if (state.selectedReasonCode) {
      //Existing reason code
      obj = {
        ...obj,
        Id: state.selectedReasonCode.id,
        IsDeleted: state.selectedReasonCode.isDeleted,
      };

      dispatch(reasonCodesActions.updateReasonCode(obj)).then(() => {
        message.success('Successfully Updated!');
        dispatch(reasonCodesActions.getReasonCodes());
        setState({ ...state, modalVisible: false });
      });
    } else {
      //New Reason Code
      dispatch(reasonCodesActions.insertReasonCode(obj)).then(() => {
        message.success('Successfully Inserted!');
        dispatch(reasonCodesActions.getReasonCodes());
        setState({ ...state, modalVisible: false });
      });
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Reason Codes | Stiletto Tools Admin</title>
      </Helmet>
      <PageHeader
        title='Reason Codes'
        extra={[
          <Button
            key='add-new'
            type='primary'
            icon={<PlusOutlined />}
            onClick={handleAddClick}
          >
            Add new
          </Button>,
        ]}
      />
      <Table
        rowKey='id'
        dataSource={reasonCodes || []}
        columns={columns}
        scroll={{ x: true }}
        pagination={false}
        loading={loading}
      />
      <Modal
        title={
          state.selectedReasonCode ? 'Edit Reason Code' : 'Add Reason Code'
        }
        open={state.modalVisible}
        onCancel={() => setState({ ...state, modalVisible: false })}
        footer={[
          <Button
            key='back'
            onClick={() => setState({ ...state, modalVisible: false })}
          >
            Cancel
          </Button>,
          state.selectedReasonCode ? (
            <Popconfirm
              key='delete'
              title='Are you sure delete this reason code?'
              onConfirm={confirmDelete}
              // onCancel={cancel}
              okText='Yes'
              cancelText='No'
            >
              <Button
                style={{ marginLeft: '3px' }}
                key='DeleteBtn'
                type='danger'
              >
                Delete
              </Button>
              ,
            </Popconfirm>
          ) : null,
          <Button
            key='submit'
            type='primary'
            onClick={() => reasonCodeFormRef.current.submit()}
          >
            Submit
          </Button>,
        ]}
        destroyOnClose
      >
        <Form
          name='dynamic_form_nest_item'
          onFinish={onFinish}
          autoComplete='off'
          hideRequiredMark={true}
          layout={'vertical'}
          ref={reasonCodeFormRef}
        >
          <Form.Item
            name='description'
            label='Description'
            initialValue={state.selectedReasonCode?.description}
            rules={[{ required: true, message: 'Required' }]}
          >
            <Input maxLength={128} />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
