import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import history from '../history';

let instrumentationKey;

if (
  window.location.host === 'admin-dev.stiletto.com' ||
  window.location.host === 'stil-d-ncus-admin-1-svc.azurewebsites.net'
) {
  instrumentationKey = '2e3b62e4-c86d-42c5-880a-aedcb7772ddb';
} else if (
  window.location.host === 'admin-staging.stiletto.com' ||
  window.location.host === 'stil-s-ncus-admin-1-svc.azurewebsites.net'
) {
  instrumentationKey = '076f1e2a-c1aa-46c1-aae2-96c42f010d97';
} else {
  instrumentationKey = '5cb2a824-c0b4-4232-aeab-e7e85d520504';
}

let reactPlugin = new ReactPlugin();
let appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {
        debug: false,
        history: history,
      },
    },
  },
});

appInsights = appInsights.loadAppInsights();

const withAppInsights = (component) => withAITracking(reactPlugin, component);

export { appInsights, withAppInsights };
