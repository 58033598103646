import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useDebounce } from 'use-debounce';

const { Option } = Select;

export const AutoCompleteInput = ({
  onSearch,
  onChange,
  suggestions,
  style,
  defaultValue,
}) => {
  const [, setValue] = useState(defaultValue || []);

  const [searchTerm, setSearchTerm] = useState(null);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

  useEffect(() => {
    if (debouncedSearchTerm) onSearch(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const options =
    suggestions?.length > 0 &&
    suggestions?.map((suggestion) => (
      <Option key={suggestion.document.sku} value={suggestion.document.sku}>
        {
          <div
            dangerouslySetInnerHTML={{ __html: `${suggestion.suggestionText}` }}
          />
        }
      </Option>
    ));

  const handleChange = (selectValue) => {
    setValue(selectValue);
    onChange && onChange(selectValue);
  };

  return (
    <Select
      showSearch
      value={null}
      style={style}
      placeholder={'Search to find a product...'}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={(s) => setSearchTerm(s)}
      onChange={handleChange}
      notFoundContent={null}
    >
      {options}
    </Select>
  );
};
