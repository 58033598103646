import createAxiosAction from '../createAxiosAction';
import {
  DELETE_RETAIL_STORE,
  INSERT_RETAIL_STORE,
  SEARCHING_RETAIL_STORES,
  UPDATE_RETAIL_STORE,
} from '../types';

export default {
  deleteRetailStore: (id) => {
    return createAxiosAction({
      url: `/api/v1/retailstores/${id}`,
      method: 'DELETE',
      startAction: DELETE_RETAIL_STORE,
      actionPayload: { id },
    });
  },
  insertRetailStore: (retailStore) => {
    return createAxiosAction({
      url: '/api/v1/retailstores',
      method: 'POST',
      body: { retailStore },
      startAction: INSERT_RETAIL_STORE,
      actionPayload: { retailStore },
    });
  },
  searchRetailStores: (queryString) => {
    return createAxiosAction({
      url: '/api/v1/retailstores/search',
      queryString,
      startAction: SEARCHING_RETAIL_STORES,
    });
  },
  updateRetailStore: (retailStore) => {
    return createAxiosAction({
      url: '/api/v1/retailstores',
      method: 'PUT',
      body: { retailStore },
      startAction: UPDATE_RETAIL_STORE,
      actionPayload: { retailStore },
    });
  },
};
