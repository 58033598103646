import * as React from 'react';
import { SimplePageContentForm } from '../../../components';

export const EditLegal = () => {
  return (
    <SimplePageContentForm
      pageTitle='Legal Policy'
      dataKey='legal'
      slug='/legal'
    />
  );
};
