export default {
  general: {
    yes: 'Yes',
    no: 'No',
    notApplicable: 'N/A',
    delete: 'Delete',
    genericErrorMessage: 'Something went wrong. Please try again.'
  },
  footer: { 
    legal: 'Legal',
    safetyNotices: 'Safety Notices',
    contactUs: 'Contact Us',
    copyright:  '©{year} by Stiletto Tools. All rights reserved.',
    signIn: 'Sign In'
  },
  navigation: {
    products: 'Products',
    signOut: 'Sign Out'
  },
  productDetails: {
    whereToBuyButtonLabel: 'Where to buy',
    featuresLabel: 'Features:',
    specificationsLabel: 'Specifications',
    dimensions: {
      dimensionsLabel: 'Dimensions',
      depthInches: 'Depth',
      lengthInches: 'Length',
      totalWeightOunces: 'Total Weight',
      weightOfHeadOunces: 'Weight of Head',
      widthInches: 'Width',
      heightInches: 'Height',
    },
    metadata: {
      specificationDetailsLabel: 'Details',
      handleType: 'Handle Type',
      colorFamily: 'Color Family',
      hammerType: 'Claw Type',
      handToolType: 'Hand Tool Type',
      handleMaterial: 'Handle Material',
      materialType: 'Material Type',
      replaceableHead: 'Replaceable Face',
      face: 'Face',
      sidingOrExtTrim: 'Siding or Ext Trim',
      remodeling: 'Remodeling',
      framing: 'Framing',
      poleBarn: 'Pole Barn',
      concrete: 'Concrete',
      scaffolding: 'Scaffolding',
      finish: 'Finish',
      drywall: 'Drywall',
      roofing: 'Roofing',
      wireLathe: 'Wire Lathe',
      numberOfNailSlots: 'Number of Nail Slots'
    },
    youMightAlsoLikeLabel: 'You might also like'
  },
  adminProductDetails: {
    title: 'Edit Product | Stiletto Tools',
    editProduct: 'Edit Product',
    saveBtn: 'Save',
    finish: 'Finish',
    editImageOrder: 'Edit Image Order',
    sku: 'Sku',
    productTitle: 'Title',
    overview: 'Overview',
    featureIsRequired: 'Feature is Required',
    addFeature: 'Add Feature',
    categories: 'Categories',
    isFeatured: 'Is Featured',
    isDiscontinued: 'Is Discontinued',
    uploadBtn: 'Upload',
    unsupportedFileTypeMessage: 'Unsupported file type'
  },
  productSearch: {
    breadcrumbHome: 'Home',
    breadcrumbSearch: 'Search',
    breadcrumbBrowse: 'Browse',
    resultsCount: '{startIndex}-{endIndex} of {totalResults} results for "{query}"',
    noProductsFound: 'No products matched your search criteria',
    filterStrikingTools: 'Striking Tools',
    filterSquares: 'Squares',
    filterPryBars: 'Pry Bars',
    filterAccessories: 'Accessories',
    filterReplacements: 'Replacements',
    filterStraight: 'Straight',
    filterCurved: 'Curved',
    filterHybrid: 'Hybrid',
    filterHickory: 'Hickory',
    filterFiberglass: 'Fiberglass',
    filterRubber: 'Rubber',
    filterComposite: 'Composite'
  }
}