/* eslint-disable react/jsx-no-target-blank */
import { DeleteFilled, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Layout,
  message,
  PageHeader,
  Space,
  Upload,
} from 'antd';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  ProductImage,
  Container,
  GeneralInformationFormFields,
} from '../../../../components';
import { pageContentsActions } from '../../../../store';
import { getCDN } from '../../../../helpers/cdnHelper';

const { Content } = Layout;

export const EditGlobalContents = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  //Refs
  const formRef = useRef();

  // app state
  const content = useSelector((state) => state.pageContents.pageContent);

  // local state
  const [state, setState] = useState({
    visible: false,
    selectedContent: null,
    saving: false,
    loading: true,
    isNew: true,
    cataloguePDF: null,
    catalogueThumbnail: null,
  });

  const { cataloguePDF, catalogueThumbnail } = state;

  /***
   * On a hard refresh, we won't have a page content loaded yet.
   * Go hit the api to load the page contents.
   */
  useEffect(() => {
    if (!content) {
      // Check URL to see if its a new content page
      if (id !== 'new') {
        // hit api and load product
        dispatch(pageContentsActions.getPageContentById(id))
          .then(() => {})
          .catch(() => {
            //navigate to not found page
          });
      }
    }

    setState({
      ...state,
      selectedContent: content,
      cataloguePDF: content?.content?.cataloguePDF,
      catalogueThumbnail: content?.content?.catalogueThumbnail,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, content, formRef]);

  const onFinish = (values) => {
    setState({ ...state, saving: true, loading: true });
    let content;
    content = {
      ...state.selectedContent,
      ...values,
      content: {
        ...values?.content,
      },
    };

    if (id === 'new') {
      // Add
      let newContent = content;
      newContent = { ...newContent, dataKey: 'global', slug: '/' };
      dispatch(pageContentsActions.addPageContent(newContent))
        .then(() => {
          dispatch(pageContentsActions.getPageContents('global'));
          message.success('Successfully Saved!');
          history.push('/globalcontents');
        })
        .catch(() => {
          message.error('An error has occurred');
        });
    } else {
      // Edit
      dispatch(pageContentsActions.updatePageContent(content))
        .then(() => {
          dispatch(pageContentsActions.getPageContents('global'));
          message.success('Successfully Saved!');
          history.push('/globalcontents');
        })
        .catch(() => {
          message.error('An error has occurred');
        });
    }
  };

  const cataloguePDFUploader = {
    beforeUpload: (file) => {
      dispatch(pageContentsActions.uploadMiscFile(file))
        .then((res) => {
          setState({
            ...state,
            cataloguePDF: `${getCDN()}/misc/${res.data.data}`,
          });

          if (formRef?.current) {
            formRef.current.setFieldsValue({
              content: {
                cataloguePDF: `${getCDN()}/misc/${res.data.data}`,
              },
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            message.error('Unsupported File Type');
          } else {
            message.error('An Error has occured. Please try again');
          }
        });

      return false;
    },
    cataloguePDF,
  };

  const catalogueImageProp = {
    beforeUpload: (file) => {
      dispatch(pageContentsActions.uploadMiscImage(file))
        .then((res) => {
          // Assign new image to UI
          setState({
            ...state,
            catalogueThumbnail: `${getCDN()}/misc/${res.data.data}`,
          });

          if (formRef?.current) {
            formRef.current.setFieldsValue({
              content: {
                catalogueThumbnail: `${getCDN()}/misc/${res.data.data}`,
              },
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            message.error('Unsupported File Type');
          } else {
            message.error('An Error has occured. Please try again');
          }
        });

      return false;
    },
    catalogueThumbnail,
  };

  const onRemoveCataloguePDF = () => {
    setState({ ...state, cataloguePDF: null });
    formRef.current.setFieldsValue({
      content: {
        cataloguePDF: null,
      },
    });
  };

  const onRemoveCatalogueThumbnail = () => {
    setState({ ...state, catalogueThumbnail: null });
    formRef.current.setFieldsValue({
      content: {
        catalogueThumbnail: null,
      },
    });
  };

  const uploadButton = (
    <Button icon={<UploadOutlined />}>Click to upload</Button>
  );

  return (
    <Container>
      <Helmet>
        <title>Edit Global Contents | Stiletto Tools Admin</title>
      </Helmet>
      <Content>
        {(!state.selectedContent && id === 'new') || state.selectedContent ? (
          <Form
            name='dynamic_form_nest_item'
            onFinish={onFinish}
            autoComplete='off'
            hideRequiredMark={false}
            layout={'vertical'}
            initialValues={{
              content: { testimonials: content?.content?.testimonials },
            }}
            ref={formRef}
          >
            <PageHeader
              style={{ padding: 0 }}
              onBack={() => history.goBack()}
              title='Edit Landing Page'
              extra={[
                <Button
                  key={'Button1'}
                  type='primary'
                  htmlType='submit'
                  // disabled={isUploading}
                  // loading={isUpdating}
                  icon={<SaveOutlined />}
                >
                  Save
                  {/*{intl.formatMessage({*/}
                  {/*  id: 'adminProductDetails.saveBtn',*/}
                  {/*})}*/}
                </Button>,
              ]}
            />

            {/*START BASIC INFO*/}
            <Divider orientation='left'>General Information</Divider>
            <GeneralInformationFormFields
              selectedContent={state?.selectedContent}
            />
            {/*END BASIC INFO*/}

            {/*START CATALOGUE*/}
            <Divider orientation='left'>Footer Catalogue</Divider>
            <Form.Item
              name={['content', 'cataloguePDF']}
              label='Catalogue PDF'
              valuePropName={['content', 'cataloguePDF']}
              getValueFromEvent={cataloguePDF}
              rules={[{ required: false, message: 'Required' }]}
              initialValue={state?.cataloguePDF}
            >
              <Space direction={'horizontal'}>
                {state?.cataloguePDF && (
                  <React.Fragment>
                    <a href={state?.cataloguePDF} target='_blank'>
                      {state?.cataloguePDF?.slice(
                        state?.cataloguePDF?.lastIndexOf('/') + 1
                      )}
                    </a>

                    <DeleteFilled onClick={() => onRemoveCataloguePDF()} />
                  </React.Fragment>
                )}
              </Space>
              <br />
              <Upload
                {...cataloguePDFUploader}
                fileList={[]}
                multiple={false}
                accept={'.pdf'}
              >
                {uploadButton}
              </Upload>
            </Form.Item>

            <Form.Item
              name={['content', 'catalogueThumbnail']}
              label='Catalogue Thumbnail'
              valuePropName={['content', 'catalogueThumbnail']}
              getValueFromEvent={catalogueThumbnail}
              rules={[{ required: false, message: 'Required' }]}
              initialValue={state?.catalogueThumbnail}
            >
              {state?.catalogueThumbnail && (
                <Space
                  direction={'vertical'}
                  style={{ textAlign: 'center', marginBottom: '10px' }}
                >
                  <ProductImage
                    imageUrl={state?.catalogueThumbnail}
                    maxHeight={300}
                  />

                  <Button
                    type={'danger'}
                    icon={<DeleteFilled />}
                    onClick={() => onRemoveCatalogueThumbnail()}
                  >
                    {' '}
                    Remove
                  </Button>
                </Space>
              )}
              <br />
              <Upload
                {...catalogueImageProp}
                fileList={[]}
                multiple={false}
                accept={'.png,.jpg,.jpeg'}
              >
                {uploadButton}
              </Upload>
            </Form.Item>

            {/*END CATALOGUE*/}
            <Form.Item>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Content>
    </Container>
  );
};
