import { PlusOutlined } from '@ant-design/icons';
import { Button, PageHeader, Popconfirm, Space, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedDate, FormattedTime } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { pageContentsActions } from '../../store';

export const PageContentsEditor = ({
  dataKey,
  pageTitle,
  hasOnBack = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const onEdit = (content) => {
    dispatch(pageContentsActions.setPageContent(content));
    history.push(`${location.pathname}/${content.id}`);
  };

  const onAdd = () => {
    dispatch(pageContentsActions.setPageContent(null));
    history.push(`${location.pathname}/new`);
  };

  const contents = useSelector((state) => state.pageContents.contents);

  const [state, setState] = useState({
    loading: true,
    saving: false,
  });

  const loadPageContents = () => {
    setState({ ...state, loading: true });
    dispatch(pageContentsActions.getPageContents(dataKey)).finally(() =>
      setState({ ...state, loading: false })
    );
  };

  useEffect(() => {
    loadPageContents();
  }, []);

  const clonePageContent = (id) => {
    dispatch(pageContentsActions.clonePageContent(id)).then(() =>
      loadPageContents()
    );
  };

  const confirmDelete = (content) => {
    setState({ ...state, loading: true });
    dispatch(pageContentsActions.deletePageContent(content.id)).then(() => {
      dispatch(pageContentsActions.getPageContents(dataKey)).then(() => {
        setState({ ...state, loading: false });
      });
    });
  };

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Effective Start',
      dataIndex: 'effectiveStart',
      render: (date) =>
        date ? (
          <Space>
            <FormattedDate value={moment.utc(date).local()} />
            <FormattedTime value={moment.utc(date).local()} />
          </Space>
        ) : (
          '-'
        ),
    },
    {
      title: 'Effective End',
      dataIndex: 'effectiveEnd',
      render: (date) =>
        date ? (
          <Space>
            <FormattedDate value={moment.utc(date).local()} />
            <FormattedTime value={moment.utc(date).local()} />
          </Space>
        ) : (
          '-'
        ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
        <Tag
          color={
            status === 'Live'
              ? 'green'
              : status === 'Draft'
              ? 'geekblue'
              : 'volcano'
          }
          key={status}
        >
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (content) => (
        <Space size='middle'>
          <Button
            href={content.previewUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            Preview
          </Button>
          <Button onClick={() => clonePageContent(content.id)}>Clone</Button>
          {content.status !== 'Expired' && (
            <Button onClick={() => onEdit(content)}>Edit</Button>
          )}
          {content.status !== 'Live' && (
            <Popconfirm
              key='delete'
              title='Are you sure delete?'
              onConfirm={() => confirmDelete(content)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                style={{ marginLeft: '3px' }}
                key={'DeleteBtn'}
                type='danger'
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle} | Stiletto Tools Admin</title>
      </Helmet>
      <PageHeader
        title={pageTitle}
        onBack={hasOnBack ? () => history.goBack() : null}
        extra={[
          <Button
            key='add-new'
            type='primary'
            icon={<PlusOutlined />}
            onClick={onAdd}
          >
            Add new
          </Button>,
        ]}
      />
      <Table
        rowKey={'id'}
        columns={columns}
        dataSource={contents || []}
        scroll={{ x: true }}
        pagination={false}
        loading={state.loading}
      />
    </React.Fragment>
  );
};
