import React, { useEffect, useState } from 'react';

import { ListView } from '../../components/ListView';

export const CategoryPages = () => {
  const [listItems, setListItems] = useState([]);

  //We will want to grab these values from the database once that table and methods have been created
  useEffect(() => {
    setListItems([
      {
        id: 1,
        name: 'Striking Tools',
        dataKey: 'strikingTools',
        slug: '/striking-tools',
        isDeleted: false,
      },
      {
        id: 2,
        name: 'Squares',
        dataKey: 'squares',
        slug: '/squares',
        isDeleted: false,
      },
      {
        id: 3,
        name: 'Pry Bars',
        dataKey: 'pryBars',
        slug: '/pry-bars',
        isDeleted: false,
      },
      {
        id: 4,
        name: 'Accessories',
        dataKey: 'accessories',
        slug: '/accessories',
        isDeleted: false,
      },
    ]);
  }, []);

  return (
    <>
      <ListView
        pageTitle='Category Pages'
        hasAdd={false}
        hasEditListItem={false}
        listItems={listItems}
      />
    </>
  );
};
