// START Image Uploader
import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { message, Modal, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { productsActions } from '../../../../store';
import { useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { getCDN } from '../../../../helpers/cdnHelper';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function constructInitialImageList(imageList) {
  let finalImageList = [];

  // Loop through each image object
  Object.keys(imageList).forEach(function (key, index) {
    // Construct initial image obj
    let imageObj = {
      uid: index,
      name: key,
      status: 'done',
      url: imageList[key].imageUrl,
    };
    finalImageList.push(imageObj);
  });

  return finalImageList;
}

export const ImageUploader = () => {
  const intl = useIntl();
  const { sku } = useParams();
  const dispatch = useDispatch();

  const productImageList = useSelector((state) =>
    state.products.productImageList ? state.products.productImageList : []
  );
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: constructInitialImageList(productImageList),
  });

  /***
   * When the product image list changes, we need to recalculate if ANY
   * image is currently being uploaded.
   */
  useEffect(() => {
    if (productImageList) {
      setState({
        ...state,
        fileList: constructInitialImageList(productImageList),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productImageList]);

  function constructImageUrls(sku, currentFileList, fileToBeUpdated) {
    let updatedList = currentFileList;
    let imageName = fileToBeUpdated.name;
    let imageObj = {
      uid: fileToBeUpdated.uid,
      name: imageName,
      status: 'done',
      url: `${getCDN()}/${sku}/${fileToBeUpdated.name}`,
    };

    updatedList.push(imageObj);

    return updatedList;
  }

  const handleCancel = () => setState({ ...state, previewVisible: false });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setState({
      ...state,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  const { previewVisible, previewImage, fileList, previewTitle } = state;
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className='ant-upload-text'>
        <FormattedMessage id='adminProductDetails.uploadBtn' />
      </div>
    </div>
  );

  const props = {
    onRemove: (file) => {
      setState((state) => {
        const index = state.fileList.indexOf(file);
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);

        dispatch(productsActions.removeProductImage(file.name));
        return {
          fileList: newFileList,
        };
      });
    },
    beforeUpload: (file) => {
      let fileToBeAdded = file;
      dispatch(productsActions.uploadProductImage(sku, file))
        .then(() => {
          let newFileList = constructImageUrls(
            sku,
            state.fileList,
            fileToBeAdded
          );

          setState({ ...state, fileList: newFileList });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            message.error(
              intl.formatMessage({
                id: 'adminProductDetails.unsupportedFileTypeMessage',
              })
            );
          } else {
            message.error(
              intl.formatMessage({ id: 'general.genericErrorMessage' })
            );
          }
        });

      return false;
    },
    fileList,
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <Upload
        {...props}
        listType='picture-card'
        fileList={fileList}
        onPreview={handlePreview}
        multiple={true}
        accept={'.png,.jpeg,.jpg'}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};

// END Image Uploader
