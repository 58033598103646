import { SaveOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Layout, message, PageHeader, Upload } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container,
  GeneralInformationFormFields,
  ProductImage,
  MarkdownEditorSm,
} from '../../components';
import { pageContentsActions } from '../../store';
import './styles.css';
import { getCDN } from '../../helpers/cdnHelper';
import { FeaturedItem } from './EditComponents';
import { HeaderDivider, AddFeaturedItemButton } from './styled';

const { Content } = Layout;

export const EditCategoryPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const storageLocation = 'category';

  //Refs
  const formRef = useRef();

  // app state
  const content = useSelector((state) => state.pageContents.pageContent);

  // local state
  const [fields, setFields] = useState({
    selectedContent: null,
    hero1ImageUrl: null,
    hero2ImageUrl: null,
  });

  const { hero1ImageUrl, hero2ImageUrl } = fields;

  /***
   * On a hard refresh, we won't have a page content loaded yet.
   * Go hit the api to load the page contents.
   */
  useEffect(() => {
    if (!content) {
      // Check URL to see if its a new content page
      if (id !== 'new') {
        // hit api and load product
        dispatch(pageContentsActions.getPageContentById(id))
          .then(() => {})
          .catch(() => {
            //navigate to not found page
          });
      }
    }

    setFields({
      ...fields,
      selectedContent: content,
      hero1ImageUrl: content?.content?.hero1Image
        ? content?.content?.hero1Image
        : null,
      hero2ImageUrl: content?.content?.hero2Image
        ? content?.content?.hero2Image
        : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, content]);

  const hero1ImageProp = {
    beforeUpload: (file) => {
      dispatch(pageContentsActions.uploadImage(file, storageLocation))
        .then((res) => {
          // Assign new image to UI
          setFields({
            ...fields,
            hero1ImageUrl: `${getCDN()}/images/${storageLocation}/${
              res.data.data
            }`,
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            message.error('Unsupported File Type');
          } else {
            message.error('An Error has occured. Please try again');
          }
        });

      return false;
    },
    hero1ImageUrl,
  };

  const hero2ImageProp = {
    beforeUpload: (file) => {
      dispatch(pageContentsActions.uploadImage(file, storageLocation))
        .then((res) => {
          setFields({
            ...fields,
            hero2ImageUrl: `${getCDN()}/images/${storageLocation}/${
              res.data.data
            }`,
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            message.error('Unsupported File Type');
          } else {
            message.error('An Error has occured. Please try again');
          }
        });

      return false;
    },
    hero2ImageUrl,
  };

  const uploadButton = (
    <Button icon={<UploadOutlined />}>Click to upload</Button>
  );

  const onFinish = (values) => {
    let content;
    content = {
      ...fields.selectedContent,
      ...values,
      content: {
        ...values?.content,
        hero1Image: fields.hero1ImageUrl ? fields.hero1ImageUrl : null,
        hero2Image: fields.hero2ImageUrl ? fields.hero2ImageUrl : null,
      },
    };

    if (id === 'new') {
      // Add
      let newContent = content;
      newContent = { ...newContent, dataKey: props.dataKey, slug: props.slug };
      dispatch(pageContentsActions.addPageContent(newContent))
        .then(() => {
          dispatch(pageContentsActions.getPageContents(props.dataKey));
          message.success('Successfully Saved!');
          history.goBack();
        })
        .catch(() => {
          message.error('An error has occurred');
        });
    } else {
      // Edit
      dispatch(pageContentsActions.updatePageContent(content))
        .then(() => {
          dispatch(pageContentsActions.getPageContents(props.dataKey));
          message.success('Successfully Saved!');
          history.goBack();
        })
        .catch(() => {
          message.error('An error has occurred');
        });
    }
  };

  const defaultFeatureItem = {
    featuredTitle: '',
    featuredSubText: '',
    featuredHyperlinkText: '',
    featuredHyperlinkUrl: '',
    featuredImage: '',
  };

  return (
    <Container>
      <Helmet>
        <title>Edit | Stiletto Tools Admin</title>
      </Helmet>
      <Content>
        {(!fields.selectedContent && id === 'new') || fields.selectedContent ? (
          <Form
            name='dynamic_form_nest_item'
            onFinish={onFinish}
            autoComplete='off'
            hideRequiredMark={false}
            layout={'vertical'}
            ref={formRef}
          >
            <PageHeader
              style={{ padding: 0 }}
              onBack={() => history.goBack()}
              title={`Edit ${props.title}`}
              extra={[
                <Button
                  key={'Button1'}
                  type='primary'
                  htmlType='submit'
                  // disabled={isUploading}
                  // loading={isUpdating}
                  icon={<SaveOutlined />}
                >
                  Save
                  {/*{intl.formatMessage({*/}
                  {/*  id: 'adminProductDetails.saveBtn',*/}
                  {/*})}*/}
                </Button>,
              ]}
            />
            {/*START BASIC INFO*/}
            <HeaderDivider orientation='center'>
              General Information
            </HeaderDivider>
            <GeneralInformationFormFields
              selectedContent={fields?.selectedContent}
            />
            {/*END BASIC INFO*/}
            {/*START HERO 1*/}
            <HeaderDivider orientation='center'>Hero 1</HeaderDivider>
            <Form.Item
              name={['content', 'hero1Title']}
              label='Title'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={fields?.selectedContent?.content?.hero1Title ?? ''}
            >
              <MarkdownEditorSm
                required
                value={fields.selectedContent?.content?.hero1Title}
                options={{
                  spellChecker: false,
                  previewRender(value) {
                    return ReactDOMServer.renderToString(
                      <ReactMarkdown source={value} />
                    );
                  },
                }}
              />
            </Form.Item>
            <Form.Item
              label='Body'
              name={['content', 'hero1Body']}
              rules={[{ required: false, message: 'Required' }]}
              initialValue={fields?.selectedContent?.content?.hero1Body ?? ''}
            >
              <MarkdownEditorSm
                required
                value={fields.selectedContent?.content?.hero1Body}
                options={{
                  spellChecker: false,
                  previewRender(value) {
                    return ReactDOMServer.renderToString(
                      <ReactMarkdown source={value} />
                    );
                  },
                }}
              />
            </Form.Item>
            <Form.Item
              name={['content', 'hero1HyperlinkText']}
              label='Hyperlink Text'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={
                fields?.selectedContent?.content?.hero1HyperlinkText ?? ''
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['content', 'hero1HyperlinkUrl']}
              label='Hyperlink Url'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={
                fields?.selectedContent?.content?.hero1HyperlinkUrl ?? ''
              }
            >
              <Input addonBefore='https://stiletto.com/' defaultValue='' />
            </Form.Item>
            <Form.Item
              name={['content', 'hero1PriceSpiderSku']}
              label='PriceSpider SKU'
              initialValue={
                fields?.selectedContent?.content?.hero1PriceSpiderSku ?? ''
              }
              tooltip={
                'Enter a SKU to show the PriceSpider "Where to Buy" button. This SKU will be used for the seller feed in the PriceSpider widget.'
              }
            >
              <Input defaultValue='' />
            </Form.Item>
            <Form.Item
              name={['content', 'hero1Image']}
              label='Image'
              valuePropName='hero1ImageUrl'
              getValueFromEvent={hero1ImageUrl}
              rules={[{ required: true, message: 'Required' }]}
              initialValue={fields?.selectedContent?.content?.hero1Image}
            >
              <Upload
                {...hero1ImageProp}
                listType='picture'
                fileList={[]}
                // onPreview={handlePreview}
                multiple={false}
                accept={'.png,.jpeg,.jpg'}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
            <ProductImage imageUrl={fields.hero1ImageUrl} maxHeight={300} />
            {/*END HERO 1*/}

            {/*START FEATURED ITEMS*/}

            <HeaderDivider orientation='center'>Featured Items</HeaderDivider>
            <Form.List
              name={['content', 'featuredItems']}
              initialValue={fields?.selectedContent?.content?.featuredItems}
            >
              {(listFields, { add, remove }) => (
                <>
                  {listFields.map((field, index) => (
                    <FeaturedItem
                      key={`${index}${field.key}`}
                      remove={remove}
                      field={field}
                      storageLocation={storageLocation}
                    />
                  ))}
                  <AddFeaturedItemButton
                    type='dashed'
                    onClick={() => add(defaultFeatureItem)}
                    block
                  >
                    <PlusOutlined /> Add Featured Item
                  </AddFeaturedItemButton>
                </>
              )}
            </Form.List>

            {/*END FEATURED ITEMS*/}

            {/*START HERO 2*/}
            <HeaderDivider orientation='center'>Hero 2</HeaderDivider>
            <Form.Item
              name={['content', 'hero2Image']}
              label='Image'
              valuePropName='hero2ImageUrl'
              getValueFromEvent={hero2ImageUrl}
              rules={[{ required: true, message: 'Required' }]}
              initialValue={fields?.selectedContent?.content?.hero2Image}
            >
              <Upload
                {...hero2ImageProp}
                listType='picture'
                fileList={[]}
                // onPreview={handlePreview}
                multiple={false}
                accept={'.png,.jpeg,.jpg'}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
            <ProductImage imageUrl={fields.hero2ImageUrl} maxHeight={300} />
            {/*END HERO 2*/}
            <Form.Item>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Content>
    </Container>
  );
};
