import createAxiosAction from '../createAxiosAction';
import {
  GET_PRODUCT,
  REMOVE_PRODUCT_IMAGE,
  SEARCHING_PRODUCTS,
  SET_IMAGE_ORDER,
  SET_PRODUCT_DETAILS,
  SUGGEST_PRODUCTS,
  UPDATE_PRODUCT,
  UPLOAD_PRODUCT_IMAGE,
  INSERT_PRODUCT,
} from '../types';

export default {
  getProduct: (sku) => {
    return createAxiosAction({
      url: `/api/v1/products/${sku}`,
      startAction: GET_PRODUCT,
    });
  },
  searchProducts: (queryString) => {
    return createAxiosAction({
      url: '/api/v1/products/search',
      queryString,
      startAction: SEARCHING_PRODUCTS,
    });
  },
  updateProduct: (product) => {
    return createAxiosAction({
      method: 'PUT',
      url: '/api/v1/products',
      body: { Product: product },
      startAction: UPDATE_PRODUCT,
    });
  },
  insertProduct: (product) => {
    return createAxiosAction({
      method: 'POST',
      url: '/api/v1/products/new',
      body: { Product: product },
      startAction: INSERT_PRODUCT,
    });
  },
  setProductDetails: (product) => (dispatch) => {
    dispatch({ type: SET_PRODUCT_DETAILS, product });
  },
  uploadProductImage: (sku, file) => {
    let form = new FormData();

    form.append('file', file);
    form.append('name', file.name);

    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/products/${sku}/image`,
      body: form,
      startAction: UPLOAD_PRODUCT_IMAGE,
      contentType: 'multipart/form-data',
      actionPayload: { sku, file },
    });
  },
  removeProductImage: (fileName) => (dispatch) => {
    dispatch({ type: REMOVE_PRODUCT_IMAGE, fileName });
  },
  setImageOrder: (imageList, sku) => (dispatch) => {
    dispatch({ type: SET_IMAGE_ORDER, imageList, sku });
  },
  suggestProducts: (query) => {
    return createAxiosAction({
      url: '/api/v1/products/suggestions',
      queryString: { query },
      startAction: SUGGEST_PRODUCTS,
    });
  },
};
