import { Button, Form, Layout, message, PageHeader, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactMarkdown from 'react-markdown';
import { GeneralInformationFormFields } from '../GeneralInformationFormFields';
import { MarkdownEditorMd } from '../MarkdownEditor';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { pageContentsActions } from '../../store';
import { Helmet } from 'react-helmet';
import { Container } from '../Container';
import { SaveOutlined } from '@ant-design/icons';
const { Content } = Layout;

export const SimplePageContentForm = ({ dataKey, slug, pageTitle }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const formRef = useRef();
  const content = useSelector((state) => state.pageContents.pageContent);

  const [isSaving, setIsSaving] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);

  useEffect(() => {
    if (!content) {
      if (id !== 'new') {
        dispatch(pageContentsActions.getPageContentById(id));
      }
    }

    setSelectedContent(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, content]);

  const onFinish = (values) => {
    setIsSaving(true);

    let content;
    content = {
      ...selectedContent,
      ...values,
      content: {
        ...values?.content,
      },
    };

    if (id === 'new') {
      // Add
      let newContent = content;
      newContent = { ...newContent, dataKey: dataKey, slug: '/' };
      dispatch(pageContentsActions.addPageContent(newContent))
        .then(() => {
          setIsSaving(false);
          dispatch(pageContentsActions.getPageContents(dataKey));
          message.success('Successfully Saved!');
          history.push(slug);
        })
        .catch(() => {
          message.error('An error has occurred');
          setIsSaving(false);
        });
    } else {
      // Edit
      dispatch(pageContentsActions.updatePageContent(content))
        .then(() => {
          setIsSaving(false);
          dispatch(pageContentsActions.getPageContents(dataKey));
          message.success('Successfully Saved!');
          history.push(slug);
        })
        .catch(() => {
          message.error('An error has occurred');
          setIsSaving(false);
        });
    }
  };

  return (
    <Container>
      <Helmet>
        <title>
          {id === 'new' ? `Add New ${pageTitle}` : `Edit ${pageTitle}`}
        </title>
      </Helmet>
      <Content>
        {((!selectedContent && id === 'new') || selectedContent) && (
          <Form
            name='dynamic_form_nest_item'
            onFinish={onFinish}
            autoComplete='off'
            hideRequiredMark={false}
            layout={'vertical'}
            ref={formRef}
          >
            <PageHeader
              style={{ padding: 0 }}
              onBack={() => history.goBack()}
              title={
                id === 'new' ? `Add New ${pageTitle}` : `Edit ${pageTitle}`
              }
              extra={[
                <Spin
                  size='default'
                  wrapperClassName='button1-spinner'
                  spinning={isSaving}
                >
                  <Button
                    key={'Button1'}
                    type='primary'
                    onClick={() => formRef?.current?.submit()}
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                </Spin>,
              ]}
            />
            <GeneralInformationFormFields selectedContent={selectedContent} />
            <Form.Item
              name={['content', 'body']}
              label='Body'
              initialValue={selectedContent?.content?.body}
              rules={[{ required: true, message: 'Required' }]}
            >
              <MarkdownEditorMd
                options={{
                  spellChecker: false,
                  previewRender(value) {
                    return ReactDOMServer.renderToString(
                      <ReactMarkdown source={value} />
                    );
                  },
                }}
              />
            </Form.Item>
          </Form>
        )}
      </Content>
    </Container>
  );
};
