import createAxiosAction from '../createAxiosAction';
import {
  GET_PAGE_CONTENT_BY_ID,
  GET_PAGE_CONTENTS,
  INSERT_PAGE_CONTENT,
  SET_PAGE_CONTENT,
  UPDATE_PAGE_CONTENT,
  UPLOAD_PAGE_CONTENT_IMAGE,
  UPLOAD_PAGE_CONTENT_MISC_FILE,
  UPLOAD_MISC_IMAGE,
} from '../types';

export default {
  getPageContents: (dataKey) => {
    return createAxiosAction({
      url: `/api/v1/pagecontents/${dataKey}`,
      startAction: GET_PAGE_CONTENTS,
    });
  },
  getPageContentById: (id) => {
    return createAxiosAction({
      url: `/api/v1/pagecontents/get/${id}`,
      startAction: GET_PAGE_CONTENT_BY_ID,
    });
  },
  updatePageContent: (pageContent) => {
    return createAxiosAction({
      url: '/api/v1/pagecontents',
      method: 'PUT',
      body: { pageContent },
      startAction: UPDATE_PAGE_CONTENT,
    });
  },
  addPageContent: (pageContent) => {
    return createAxiosAction({
      url: '/api/v1/pagecontents',
      method: 'POST',
      body: { pageContent },
      startAction: INSERT_PAGE_CONTENT,
    });
  },
  deletePageContent: (id) => {
    return createAxiosAction({
      url: `/api/v1/pagecontents/${id}`,
      method: 'DELETE',
      startAction: UPDATE_PAGE_CONTENT,
    });
  },
  clonePageContent: (id) => {
    return createAxiosAction({
      url: `/api/v1/pagecontents/${id}/clone`,
      method: 'POST',
      startAction: 'CLONE',
    });
  },
  setPageContent: (pageContent) => (dispatch) => {
    dispatch({ type: SET_PAGE_CONTENT, pageContent });
  },

  uploadImage: (file, location) => {
    let form = new FormData();

    form.append('file', file);
    form.append('name', file.name);

    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/pagecontents/image/${location}`,
      body: form,
      startAction: UPLOAD_PAGE_CONTENT_IMAGE,
      contentType: 'multipart/form-data',
      actionPayload: { file },
    });
  },

  uploadMiscFile: (file) => {
    let form = new FormData();

    form.append('file', file);
    form.append('name', file.name);

    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/pagecontents/miscFile`,
      body: form,
      startAction: UPLOAD_PAGE_CONTENT_MISC_FILE,
      contentType: 'multipart/form-data',
      actionPayload: { file },
    });
  },

  uploadMiscImage: (file) => {
    let form = new FormData();

    form.append('file', file);
    form.append('name', file.name);

    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/pagecontents/miscImage`,
      body: form,
      startAction: UPLOAD_MISC_IMAGE,
      contentType: 'multipart/form-data',
      actionPayload: { file },
    });
  },
};
