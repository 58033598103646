// Locales
export const UPDATE_LOCALE = 'UPDATE_LOCALE';

// Products
export const GET_PRODUCT = 'GET_PRODUCT';
export const SEARCHING_PRODUCTS = 'SEARCHING_PRODUCTS';
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS';
export const SET_SEARCH_FILTERS_SIDER = 'SET_SEARCH_FILTERS_SIDER';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPLOAD_PRODUCT_IMAGE = 'UPLOAD_PRODUCT_IMAGE';
export const REMOVE_PRODUCT_IMAGE = 'REMOVE_PRODUCT_IMAGE';
export const SET_IMAGE_ORDER = 'SET_IMAGE_ORDER';
export const SUGGEST_PRODUCTS = 'SUGGEST_PRODUCTS';
export const INSERT_PRODUCT = 'INSERT_PRODUCT';

//Distributors
export const GET_DISTRIBUTORS = 'GET_DISTRIBUTORS';
export const SET_DISTRIBUTOR = 'SET_DISTRIBUTOR;';
export const GET_DISTRIBUTOR = 'GET_DISTRIBUTOR';
export const UPDATE_DISTRIBUTOR = 'UPDATE_DISTRIBUTOR';
export const UPLOAD_DISTRIBUTOR_IMAGE = 'UPLOAD_DISTRIBUTOR_IMAGE';
export const INSERT_DISTRIBUTOR = 'INSERT_DISTRIBUTOR';
export const UPDATE_DISTRIBUTORS_SORT_ORDER = 'UPDATE_DISTRIBUTORS_SORT_ORDER';

//Continents
export const GET_CONTINENTS = 'GET_CONTINENTS';
export const SET_CONTINENT = 'SET_CONTINENT;';
export const UPDATE_CONTINENT = 'UPDATE_CONTINENT';
export const INSERT_CONTINENT = 'INSERT_CONTINENT';

//Continent Countries
export const GET_CONTINENTCOUNTRIES = 'GET_CONTINENTCOUNTRIES';
export const UPDATE_CONTINENTCOUNTRIES = 'UPDATE_CONTINENTCOUNTRIES';
export const INSERT_CONTINENTCOUNTRIES = 'INSERT_CONTINENTCOUNTRIES';

//Continent Country Stores
export const GET_CONTINENTCOUNTRYSTORES = 'GET_CONTINENTCOUNTRYSTORES';
export const UPDATE_CONTINENTCOUNTRYSTORES = 'UPDATE_CONTINENTCOUNTRYSTORES';
export const INSERT_CONTINENTCOUNTRYSTORES = 'INSERT_CONTINENTCOUNTRYSTORES';

// Page Contents
export const GET_PAGE_CONTENTS = 'GET_PAGE_CONTENTS';
export const GET_PAGE_CONTENT_BY_ID = 'GET_PAGE_CONTENT_BY_ID';
export const UPDATE_PAGE_CONTENT = 'UPDATE_PAGE_CONTENT';
export const INSERT_PAGE_CONTENT = 'INSERT_PAGE_CONTENT';
export const SET_PAGE_CONTENT = 'SET_PAGE_CONTENT';
export const UPLOAD_PAGE_CONTENT_IMAGE = 'UPLOAD_PAGE_CONTENT_IMAGE';
export const UPLOAD_PAGE_CONTENT_MISC_FILE = 'UPLOAD_PAGE_CONTENT_MISC_FILE';
export const UPLOAD_MISC_IMAGE = 'UPLOAD_MISC_IMAGE';

// Retail Stores
export const SEARCHING_RETAIL_STORES = 'SEARCHING_RETAIL_STORES';
export const UPDATE_RETAIL_STORE = 'UPDATE_RETAIL_STORE';
export const INSERT_RETAIL_STORE = 'INSERT_RETAIL_STORE';
export const DELETE_RETAIL_STORE = 'DELETE_RETAIL_STORE';

// Warranty Claims
export const SEARCHING_WARRANTY_CLAIMS = 'SEARCHING_WARRANTY_CLAIMS';
export const GET_WARRANTY_CLAIM = 'GET_WARRANTY_CLAIM';
export const GET_WARRANTY_CLAIMS_SETTINGS = 'GET_WARRANTY_CLAIMS_SETTINGS';
export const UPDATE_WARRANTY_SETTINGS = 'UPDATE_WARRANTY_SETTINGS';
export const UPDATE_WARRANTY_CLAIM_IS_RESOLVED =
  'UPDATE_WARRANTY_CLAIM_IS_RESOLVED';
export const INSERT_WARRANTY_CLAIM_NOTE = 'INSERT_WARRANTY_CLAIM_NOTE';
export const SET_WARRANTY_CLAIM = 'SET_WARRANTY_CLAIM';

// Contact Us
export const SEARCHING_CONTACT_US = 'SEARCHING_CONTACT_US';
export const GET_CONTACT_US_SETTINGS = 'GET_CONTACT_US_SETTINGS';
export const UPDATE_CONTACT_US_SETTINGS = 'UPDATE_CONTACT_US_SETTINGS';
export const GET_CONTACT_US = 'GET_CONTACT_US';
export const UPDATE_CONTACT_US_IS_RESOLVED = 'UPDATE_CONTACT_US_IS_RESOLVED';
export const INSERT_CONTACT_US_NOTE = 'INSERT_CONTACT_US_NOTE';
export const SET_CONTACT_US = 'SET_CONTACT_US';

// Config
export const GET_LOCATION_DATA = 'GET_LOCATION_DATA';

// Files
export const GET_FILES = 'GET_FILES';
export const UPLOAD_MISC_FILE = 'UPLOAD_MISC_FILE';
export const INSERT_MISC_FILE = 'INSERT_MISC_FILE';
export const DELETE_MISC_FILE = 'DELETE_MISC_FILE';

// Reason Codes
export const GET_REASON_CODES = 'GET_REASON_CODES';
export const INSERT_REASON_CODE = 'INSERT_REASON_CODE';
export const UPDATE_REASON_CODE = 'UPDATE_REASON_CODE';
