import { success } from '../utils';
import {
  GET_REASON_CODES,
  INSERT_REASON_CODE,
  UPDATE_REASON_CODE,
} from '../types';

export const initialState = {
  reasonCodeList: null,
  reasonCode: null,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case success(GET_REASON_CODES):
      return {
        ...state,
        reasonCodeList: action.payload.data,
      };
    case success(UPDATE_REASON_CODE):
      return {
        ...state,
        reasonCode: action.payload.data,
      };
    case success(INSERT_REASON_CODE):
      return {
        ...state,
        reasonCode: action.payload.data,
      };
    default:
      return state;
  }
};
