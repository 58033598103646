import {
  Button,
  Col,
  Input,
  message,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Table,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedDate, FormattedTime } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import { Pager } from '../../components/Pager';
import { getSeachFilterProps } from '../../components/TableFilters';
import { configActions, retailStoresActions } from '../../store';
import { RetailStoreFormModal } from './components/RetailStoreFormModal';

const { Search } = Input;

export const RetailStores = () => {
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useQueryParams({
    page: withDefault(NumberParam, 1),
    query: StringParam,
    company: StringParam,
    addressLine1: StringParam,
    city: StringParam,
    region: StringParam,
    country: StringParam,
    phone: StringParam,
    url: StringParam,
  });

  // app state
  const searchResults = useSelector(
    (state) => state.retailStores.searchResults,
    shallowEqual
  );

  // local state
  const [loading, setLoading] = useState(true);
  const [, setSearchInput] = useState(queryParams.query);
  const [modal, setModal] = useState({
    isVisible: false,
    isNew: false,
  });

  useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(retailStoresActions.searchRetailStores(queryParams)),
      dispatch(configActions.getLocationData()),
    ]).finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, window.location.search]);

  /***
   * When the page changes, scroll back to the top
   */
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [queryParams.page]);

  /***
   * updateQueryParam updates a single param in the query object
   * @param key: the query param to update
   * @param value: the new value of the query param
   */
  const updateQueryParam = (key, value) => {
    let updatedQuery = { ...queryParams };
    updatedQuery[key] = value;
    setQueryParams(updatedQuery);
  };

  /***
   * clearAll wipes out all query string parameters and resets the table
   */
  const clearAll = () => {
    setQueryParams({}, 'push');
    setSearchInput('');
  };

  const handleSubmitForm = (retailStore) => {
    setModal({
      ...modal,
      isSaving: true,
    });

    if (modal.isNew) {
      dispatch(retailStoresActions.insertRetailStore(retailStore))
        .then(() => setModal({ ...modal, isSaving: false, isVisible: false }))
        .then(() => message.success('Retail Store Created'))
        .catch(() => {
          message.error('Something Went Wrong. Please Try Again Later.');
          setModal({ ...modal, isSaving: false });
        });
    } else {
      dispatch(retailStoresActions.updateRetailStore(retailStore))
        .then(() => setModal({ ...modal, isSaving: false, isVisible: false }))
        .then(() => message.success('Retail Store Updated'))
        .catch(() => {
          message.error('Something Went Wrong. Please Try Again Later.');
          setModal({ ...modal, isSaving: false });
        });
    }
  };

  const deleteRetailStore = (retailStore) => {
    dispatch(retailStoresActions.deleteRetailStore(retailStore.id))
      .then(() => message.success('Retail Store Deleted'))
      .catch(() =>
        message.error('Something Went Wrong. Please Try Again Later.')
      );
  };

  const cancelModal = (e) => {
    setModal({
      ...modal,
      isVisible: false,
    });
  };

  const openEditModal = (retailStore) => {
    setModal({
      ...modal,
      isVisible: true,
      retailStore: retailStore,
      isNew: false,
    });
  };

  const openAddModal = () => {
    setModal({
      ...modal,
      isVisible: true,
      retailStore: null,
      isNew: true,
    });
  };

  const columns = [
    {
      title: 'Company',
      dataIndex: 'company',
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'company',
        queryParamValue: queryParams.company,
      }),
    },
    {
      title: 'Address',
      dataIndex: 'addressLine1',
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'addressLine1',
        queryParamValue: queryParams.addressLine1,
      }),
    },
    {
      title: 'City',
      dataIndex: 'city',
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'city',
        queryParamValue: queryParams.city,
      }),
    },
    {
      title: 'State/Region',
      dataIndex: 'region',
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'region',
        queryParamValue: queryParams.region,
      }),
    },
    {
      title: 'Postal Code',
      dataIndex: 'postalCode',
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'postalCode',
        queryParamValue: queryParams.postalCode,
      }),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'country',
        queryParamValue: queryParams.country,
      }),
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'phoneNumber',
        queryParamValue: queryParams.phoneNumber,
      }),
      render: (phone) => <span style={{ whiteSpace: 'nowrap' }}>{phone}</span>,
    },
    {
      title: 'URL',
      dataIndex: 'url',
      ...getSeachFilterProps({
        setQueryParamFunction: updateQueryParam,
        queryParamKey: 'url',
        queryParamValue: queryParams.url,
      }),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      render: (date) =>
        date ? (
          <Space style={{ whiteSpace: 'nowrap' }}>
            <FormattedDate value={moment.utc(date).local()} />
            <FormattedTime value={moment.utc(date).local()} />
          </Space>
        ) : (
          '-'
        ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (retailStore) => (
        <Space size='middle'>
          <Button onClick={() => openEditModal(retailStore)}>Edit</Button>
          <Popconfirm
            key='delete'
            title='Are you sure?'
            onConfirm={() => deleteRetailStore(retailStore)}
            okText='Yes'
            cancelText='No'
          >
            <Button
              style={{ marginLeft: '3px' }}
              key={'DeleteBtn'}
              type='danger'
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>Retail Stores | Stiletto Tools Admin</title>
      </Helmet>
      <PageHeader
        title='Retail Stores'
        extra={[
          <Button
            key='add-new'
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => openAddModal()}
          >
            Add New
          </Button>,
        ]}
      />
      <Row gutter={[16, 16]}>
        <Col xs={18} sm={20} md={16} lg={12} xl={8}>
          <Search
            placeholder={'Search...'}
            onSearch={(value) =>
              setQueryParams({ ...queryParams, query: value })
            }
            defaultValue={queryParams.query}
            loading={loading}
            style={{ width: '100%' }}
          />
        </Col>
        <Col>
          <Button onClick={clearAll}>Reset</Button>
        </Col>
      </Row>
      <Table
        rowKey={'id'}
        columns={columns}
        dataSource={searchResults?.data || []}
        scroll={{ x: true }}
        pagination={false}
        loading={loading}
      />
      {searchResults?.totalCount > 50 && (
        <Pager
          current={queryParams.page}
          pageSize={50}
          total={searchResults?.totalCount}
          showSizeChanger={false}
          onChange={(page) => setQueryParams({ page })}
        />
      )}
      <RetailStoreFormModal
        isNew={modal.isNew}
        isSaving={modal.isSaving}
        isVisible={modal.isVisible}
        onCancel={cancelModal}
        onFormSubmit={handleSubmitForm}
        retailStore={modal.retailStore}
      />
    </React.Fragment>
  );
};
