import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  PageHeader,
  Space,
  Table,
  Modal,
  Popconfirm,
  Form,
  Row,
  Col,
  Upload,
  Input,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fileUploadActions } from '../../store';
import moment from 'moment';
import { FormattedDate } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const FileUpload = () => {
  const dispatch = useDispatch();

  //Refs
  const formRef = React.createRef();
  // app state
  const fileList = useSelector(
    (state) => state?.fileUpload?.fileList,
    shallowEqual
  );

  // local state
  const [files, setFiles] = useState(fileList);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    modalVisible: false,
    uploadedFiles: [],
  });

  useEffect(() => {
    dispatch(fileUploadActions.getFiles()).finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (fileList) setFiles(fileList);
  }, [fileList]);

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'File',
      key: 'url',
      render: (file) => (
        <a href={file?.url} rel='noopener noreferrer' target='_blank'>
          {file?.url?.slice(file?.url?.lastIndexOf('/') + 1)}
        </a>
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (date) => <FormattedDate value={moment.utc(date).local()} />,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (file) => (
        <Space size='middle'>
          <CopyToClipboard
            text={file.url}
            onCopy={() => message.success('Copied')}
          >
            <Button>Copy to Clipboard</Button>
          </CopyToClipboard>
          <Popconfirm
            key='delete'
            title='Are you sure delete this file?'
            onConfirm={() => confirmDelete(file.id)}
            // onCancel={cancel}
            okText='Yes'
            cancelText='No'
          >
            <Button
              style={{ marginLeft: '3px' }}
              key={'DeleteBtn'}
              type='danger'
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const handleAddClick = () => {
    setState({
      ...state,
      modalVisible: true,
    });
  };

  const confirmDelete = (id) => {
    dispatch(fileUploadActions.deleteFile(id))
      .then(() => {
        message.success('Successfully Deleted');
        dispatch(fileUploadActions.getFiles());
      })
      .catch(() => {
        message.error('An error has occurred');
      });
  };

  /***
   * onFinish will save the updated distributor info
   * @param values: the values of the form
   */
  const onFinish = (values) => {
    // Reconstruct Object to be use able by stored proc
    let obj = {
      Description: values.description,
      Url: state.selectedFileUrl,
    };
    dispatch(fileUploadActions.insertFile(obj)).then(() => {
      message.success('Successfully Inserted!');
      dispatch(fileUploadActions.getFiles());
      handleModalClose();
    });
  };

  const handleModalClose = () => {
    formRef.current.setFieldsValue({
      url: null,
    });
    setState({ uploadedFiles: [], modalVisible: false });
  };

  const uploadProps = {
    multiple: false,
    fileList: state?.uploadedFiles,
    onChange: (info) => {
      let fileList = [...info.fileList];

      if (formRef?.current && fileList.length === 0) {
        formRef.current.setFieldsValue({
          url: null,
        });
      }

      const is5M = info.file.size / 1024 / 1024 < 5;
      if (is5M) {
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-1);

        // 2. Read from response and show file link
        fileList = fileList.map((file) => {
          if (file.response) {
            // Component will show file.url as link
            file.url = file.response.url;
          }
          return file;
        });

        setState({ ...state, uploadedFiles: fileList });
      } else {
        formRef.current.setFieldsValue({
          url: null,
        });

        setState({ ...state, uploadedFiles: null });
      }
    },
    beforeUpload: (file) => {
      const is5M = file.size / 1024 / 1024 < 5;
      if (!is5M) {
        message.error('File must smaller than 5MB!');
      } else {
        dispatch(fileUploadActions.uploadFile(file))
          .then((res) => {
            setState({
              ...state,
              selectedFileUrl: res?.data?.data,
              uploadedFiles: [file],
            });
          })
          .catch((error) => {
            if (error.response.status === 400) {
              message.error('Unsupported File Type');
            } else {
              message.error('An Error has occured. Please try again');
            }
          });
      }

      return false;
    },
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>File Upload | Stiletto Tools Admin</title>
      </Helmet>
      <PageHeader
        title='Files'
        extra={[
          <Button
            key='add-new'
            type='primary'
            icon={<PlusOutlined />}
            onClick={handleAddClick}
          >
            Add new
          </Button>,
        ]}
      />
      <Table
        rowKey='id'
        dataSource={files || []}
        columns={columns}
        scroll={{ x: true }}
        pagination={false}
        loading={loading}
      />

      <Modal
        title={'Add file'}
        visible={state.modalVisible}
        onCancel={() => handleModalClose()}
        footer={[
          <Button key='back' onClick={() => handleModalClose()}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={() => formRef.current.submit()}
          >
            Submit
          </Button>,
        ]}
        destroyOnClose
      >
        <Form
          name='dynamic_form_nest_item'
          onFinish={onFinish}
          autoComplete='off'
          hideRequiredMark={true}
          layout={'vertical'}
          ref={formRef}
        >
          <Row gutter={[16, 16]} justify='center'>
            <Col xs={24} md={24}>
              <Form.Item
                name='description'
                label='Description'
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='url'
                label='File'
                rules={[{ required: true, message: 'Required' }]}
              >
                <Upload {...uploadProps}>
                  <Button style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <UploadOutlined /> Upload File
                  </Button>
                </Upload>
                {/*{state?.selectedFileUrl ? <a href={state.selectedFileUrl} target={'_blank'}>{state.selectedFileUrl}</a> : null}*/}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
