import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Avatar,
  Button,
  Col,
  Comment,
  Descriptions,
  Divider,
  Form,
  Input,
  List,
  message,
  PageHeader,
  Row,
  Switch,
  Typography,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedDate } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';

import { contactUsActions } from '../../../../store';
import useCurrentUserProfile from '../../../../hooks/useCurrentUserProfile';

const { Title } = Typography;
const { TextArea } = Input;

export const ViewContactUs = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfile = useCurrentUserProfile();

  // app state
  const contactUs = useSelector((state) => state.contactUs.contactUs);
  const contactUsNotes = useSelector((state) => state.contactUs.contactUsNotes);

  const [form] = Form.useForm();

  /***
   * On a hard refresh, we won't have a contact us loaded yet.
   * Go hit the api to load the product details.
   */
  useEffect(() => {
    // hit api and load product
    if (!contactUs) {
      dispatch(contactUsActions.getContactUsById(id));
    }
  }, [dispatch, id, contactUs]);

  /***
   * onFinish will save the updated contact us info
   * @param values: the values of the form
   */
  const onFinish = (isResolved) => {
    dispatch(
      contactUsActions.updateContactUsIsResolved(contactUs?.id, isResolved)
    )
      .then(() => {
        message.success('Successfully updated');
      })
      .catch(() => {
        message.error('An error has occurred');
      });
  };

  const addComment = (value) => {
    let obj = {
      ContactUsId: contactUs?.id,
      Note: value?.comment,
      CreatedBy: userProfile?.unique_name,
      CreatedByOid: userProfile?.oid,
    };
    dispatch(contactUsActions.insertContactUsNote(obj))
      .then(() => {
        message.success('Successfully added note');

        // Clears out comments field
        form.setFieldsValue({ comment: '' });
      })
      .catch(() => {
        message.error('An error has occurred');
      });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Contact Us | Stiletto Tools Admin</title>
      </Helmet>

      {contactUs && (
        <React.Fragment>
          <PageHeader
            style={{ padding: 0 }}
            onBack={() => history.goBack()}
            title='Contact Us'
          />
          <Divider />
          <Typography>
            <Row>
              <Col xs={24} md={12}>
                <Title level={2}>
                  {contactUs?.ticketNumber}{' '}
                  <Switch
                    key={'Switch1'}
                    size={'large'}
                    checkedChildren='Resolved'
                    unCheckedChildren='Open'
                    defaultChecked={contactUs?.isResolved}
                    onChange={(e) => onFinish(e)}
                  />
                </Title>
                <Descriptions title='Customer Information'>
                  <Descriptions.Item label='*First name'>
                    {contactUs?.firstName}
                  </Descriptions.Item>
                  <Descriptions.Item label='*Last Name'>
                    {contactUs?.lastName}
                  </Descriptions.Item>
                  <Descriptions.Item label='Phone'>
                    {contactUs?.phoneNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label='*Email'>
                    {contactUs?.emailAddress}
                  </Descriptions.Item>
                  <Descriptions.Item label='Country'>
                    {contactUs?.country}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions title='*Message'>
                  <Descriptions.Item>{contactUs?.message}</Descriptions.Item>
                </Descriptions>
              </Col>
              <Col xs={24} md={12}>
                <Title level={4}>Notes</Title>

                <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                  <List
                    className='comment-list'
                    header={`${
                      contactUsNotes?.length ? contactUsNotes.length : 0
                    } replies`}
                    itemLayout='horizontal'
                    dataSource={contactUsNotes}
                    renderItem={(item) => (
                      <li>
                        <Comment
                          author={item.createdBy}
                          avatar={
                            'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
                          }
                          content={<p>{item.note}</p>}
                          datetime={
                            <FormattedDate
                              value={moment.utc(item?.createdAt).local()}
                            />
                          }
                        />
                      </li>
                    )}
                  />
                </div>
                <Form
                  name='dynamic_form_nest_item'
                  onFinish={addComment}
                  autoComplete='off'
                  hideRequiredMark={true}
                  layout={'vertical'}
                  form={form}
                >
                  <Comment
                    avatar={
                      <Avatar
                        src='https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
                        alt='Han Solo'
                      />
                    }
                    content={
                      <React.Fragment>
                        <Form.Item
                          name={'comment'}
                          rules={[{ required: true, message: 'Required' }]}
                        >
                          <TextArea rows={4} />
                        </Form.Item>
                        <Form.Item>
                          <Button htmlType='submit' type='primary'>
                            Add Comment
                          </Button>
                        </Form.Item>
                      </React.Fragment>
                    }
                  />
                </Form>
              </Col>
            </Row>
          </Typography>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
