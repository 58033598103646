import { Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const { Option } = Select;

export const RetailStoreFormModal = ({
  isVisible,
  isSaving,
  isNew,
  retailStore,
  onFormSubmit,
  onCancel,
}) => {
  const formRef = useRef();

  // app state
  const states = useSelector((state) => state.config.states);
  const countries = useSelector((state) => state.config.countries);

  // local state
  const [country, setCountry] = useState(retailStore?.country);

  const countryOptions =
    countries &&
    countries.map((item, index) => {
      return (
        <Option key={index} value={item.iso}>
          {item.name}
        </Option>
      );
    });

  const stateOptions =
    states &&
    states.map((item, index) => {
      return (
        <Option key={index} value={item.abbreviation}>
          {item.name}
        </Option>
      );
    });

  useEffect(() => {
    if (formRef?.current) {
      if (isNew) {
        formRef.current.resetFields();
      } else {
        formRef.current.setFieldsValue({ ...retailStore });
      }
    }

    setCountry(retailStore?.country);
  }, [retailStore, formRef, isNew]);

  return (
    <Modal
      title={isNew ? 'Add Retail Store' : 'Edit Retail Store'}
      visible={isVisible}
      closable={false}
      onOk={() => formRef?.current?.submit()}
      onCancel={onCancel}
      confirmLoading={isSaving}
      width={800}
    >
      <Form
        name='retail-stores'
        onFinish={onFormSubmit}
        hideRequiredMark={false}
        layout={'vertical'}
        ref={formRef}
        initialValues={retailStore}
      >
        <Form.Item
          name='id'
          hidden={true}
          style={{ display: 'none' }}
        ></Form.Item>
        <Form.Item
          name='company'
          label='Company'
          rules={[{ required: true, message: 'Company is Required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='country'
          label='Country'
          rules={[{ required: true, message: 'Country is Required' }]}
        >
          <Select
            placeholder='Select a country'
            onChange={(val) => setCountry(val)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp='children'
            showSearch
          >
            {countryOptions}
          </Select>
        </Form.Item>
        <Form.Item
          name='addressLine1'
          label='Address'
          rules={[{ required: true, message: 'Address is Required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='city'
          label='City'
          rules={[{ required: true, message: 'City is Required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='region'
          label={country === 'US' || !country ? 'State' : 'Region'}
          rules={[{ required: true, message: 'State/Region is Required' }]}
        >
          {country !== 'US' ? (
            <Input />
          ) : (
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              optionFilterProp='children'
              showSearch
            >
              <Option value=''>Please Select</Option>
              {stateOptions}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          name='postalCode'
          label='Postal Code'
          rules={[{ required: true, message: 'Postal Code is Required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='phoneNumber'
          label='Phone'
          rules={[{ required: false, message: 'Phone is Required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name='url' label='URL'>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
