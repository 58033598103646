import styled from 'styled-components';
import { Divider, Button } from 'antd';

export const HeaderDivider = styled(Divider)`
  &&.ant-divider-horizontal {
    font-size: 20px;
  }
`;

export const AddFeaturedItemButton = styled(Button)`
  margin: 15px 0px 5px 0px;
`;
