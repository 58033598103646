import { PageHeader, Button, Space, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IntlRetailStoreModal } from './IntlRetailStoreModal';
import { continentCountryStoresActions } from '../../../store';

export const IntlRetailStores = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { listItem } = location.state;

  // app state
  const continentCountryStoresList = useSelector(
    (state) => state.continentCountryStores.continentCountryStoresList,
    shallowEqual
  );

  // local state
  const [continentCountryStores, setContinentCountryStores] = useState(
    continentCountryStoresList
  );
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    selectedstore: null,
    isVisible: false,
    event: null,
    CountryId: listItem.id,
  });

  useEffect(() => {
    dispatch(
      continentCountryStoresActions.getContinentCountryStoresByCountryId(
        listItem.id
      )
    ).finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (continentCountryStoresList)
      setContinentCountryStores(continentCountryStoresList);
  }, [continentCountryStoresList]);

  const handleAddClick = (e) => {
    setState({
      ...state,
      selectedStore: null,
      isVisible: true,
      event: e.timeStamp,
    });
  };

  const handleActionClick = (e, store) => {
    setState({
      ...state,
      selectedStore: store,
      isVisible: true,
      event: e.timeStamp,
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Url',
      dataIndex: 'url',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (country) => (
        <Space>
          <Button key='edit' onClick={(e) => handleActionClick(e, country)}>
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  if (continentCountryStores) {
    continentCountryStores.sort((a, b) =>
      a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>View Stores | Stiletto Tools Admin</title>
      </Helmet>
      <PageHeader
        style={{ padding: 0 }}
        onBack={() => history.goBack()}
        title={`${listItem.name} - Stores`}
        extra={[
          <Button
            key='add-new-store'
            type='primary'
            icon={<PlusOutlined />}
            onClick={(e) => handleAddClick(e)}
          >
            Add store
          </Button>,
        ]}
      />
      <Table
        rowKey='id'
        dataSource={continentCountryStores || []}
        columns={columns}
        scroll={{ x: true }}
        loading={loading}
        pagination={false}
      />
      <IntlRetailStoreModal {...state} />
    </React.Fragment>
  );
};
