import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { continentCountriesActions } from '../../../store';
import { ListView } from '../../../components/ListView';
import useCurrentUserProfile from '../../../hooks/useCurrentUserProfile';

export const Countries = () => {
  const dispatch = useDispatch();

  const userProfile = useCurrentUserProfile();

  const location = useLocation();
  const { listItem } = location.state;

  // app state
  const continentCountriesList = useSelector(
    (state) => state.continentCountries.continentCountriesList,
    shallowEqual
  );

  // local state
  const [continentCountries, setContinentCountries] = useState(
    continentCountriesList
  );

  useEffect(() => {
    dispatch(continentCountriesActions.getContinentCountriesById(listItem.id));
  }, [dispatch]);

  useEffect(() => {
    if (continentCountriesList) setContinentCountries(continentCountriesList);
  }, [continentCountriesList]);

  const confirmDelete = async (selectedCountry) => {
    let continentCountries = { ...selectedCountry, isDeleted: true };
    dispatch(
      continentCountriesActions.updateContinentCountries(continentCountries)
    ).then(() => {
      dispatch(
        continentCountriesActions.getContinentCountriesById(listItem.id)
      );
    });
    return 'Successfully Deleted';
  };

  const onFinish = async (values, selectedCountry) => {
    // Reconstruct Object to be use able by stored proc
    let obj = {
      Name: values.name,
      UpdatedBy: userProfile.unique_name,
      ContinentId: listItem.id,
    };

    if (selectedCountry) {
      //Existing Country
      obj = {
        ...obj,
        Id: selectedCountry.id,
        IsDeleted: selectedCountry.isDeleted,
        UpdatedBy: userProfile.unique_name,
        ContinentId: listItem.id,
      };
      dispatch(continentCountriesActions.updateContinentCountries(obj)).then(
        () => {
          dispatch(
            continentCountriesActions.getContinentCountriesById(listItem.id)
          );
        }
      );
      return 'Successfully Updated!';
    } else {
      // New Country
      dispatch(continentCountriesActions.insertContinentCountries(obj)).then(
        () => {
          dispatch(
            continentCountriesActions.getContinentCountriesById(listItem.id)
          );
        }
      );
    }
    return 'Successfully Inserted!';
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>View Countries | Stiletto Tools Admin</title>
      </Helmet>
      <ListView
        pageTitle={`${listItem.name} - Countries`}
        hasAdd={true}
        hasEditListItem={true}
        listItems={continentCountries}
        hasOnBack={true}
        modalConfirmDelete={confirmDelete}
        modalOnFinish={onFinish}
      />
    </React.Fragment>
  );
};
