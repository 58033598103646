import * as React from 'react';
import { Route, Switch, useHistory } from 'react-router';
import useAppInsightsUserContext from './hooks/useAppInsightsUserContext';
import useCurrentUserProfile from './hooks/useCurrentUserProfile';

import {
  AboutUs,
  EditProduct,
  Legal,
  RetailStores,
  SearchContactUs,
  SearchDistributors,
  SearchProducts,
  SearchWarrantyClaims,
  ViewContactUs,
  ViewWarrantyClaim,
  Warranty,
  SiteLayout,
  Continents,
} from './containers';
import { EditLandingPage } from './containers/LandingPage/EditLandingPage/EditLandingPage';
import { LandingPage } from './containers/LandingPage/LandingPage';
import {
  CategoryPage,
  CategoryPages,
  EditCategoryPage,
} from './containers/CategoryPage';
import { WarrantyPolicy } from './containers/Policies/Warranty';
import { GlobalContents } from './containers/PageContents/GlobalContents';
import { EditGlobalContents } from './containers/PageContents/GlobalContents/EditGlobalContents';
import { FAQ } from './containers/PageContents/FAQ';
import { FileUpload } from './containers/FileUpload';
import { ReasonCodes } from './containers/ReasonCodes';
import { Countries } from './containers/International/Countries';
import { IntlRetailStores } from './containers/International/IntlRetailStores';
import { EditFAQ } from './containers/PageContents/FAQ/EditFAQ';
import { EditAboutUsPage } from './containers/AboutUs/EditAboutUs';
import { EditLegal } from './containers/Policies/Legal/EditLegal';
import { EditWarrantyPolicy } from './containers/Policies/Warranty/EditWarrantyPolicy';
import { EditWarranty } from './containers/Warranty/EditWarranty';

const AppRoute = ({ component: Component, adminOnly, ...rest }) => {
  const history = useHistory();
  const userProfile = useCurrentUserProfile();

  const isAdmin = () => {
    return userProfile.roles.includes('SuperUser');
  };

  if (adminOnly && !isAdmin()) history.push('/');

  return (
    <Route
      {...rest}
      render={(props) => (
        <Component
          {...props}
          title={rest.title}
          dataKey={rest.dataKey}
          slug={rest.slug}
        />
      )}
    />
  );
};

export default () => {
  useAppInsightsUserContext();

  return (
    <SiteLayout>
      <Switch>
        <AppRoute exact path='/' component={SearchWarrantyClaims} />
        <AppRoute
          exact
          path='/warrantyclaims/:id'
          component={ViewWarrantyClaim}
        />
        <AppRoute exact path='/contactus/:id' component={ViewContactUs} />
        <AppRoute exact path='/contactus' component={SearchContactUs} />

        <AppRoute
          exact
          adminOnly
          path='/products/:sku'
          component={EditProduct}
        />
        <AppRoute exact adminOnly path='/products' component={SearchProducts} />
        <AppRoute
          exact
          adminOnly
          path='/international'
          component={Continents}
        />
        <AppRoute
          exact
          adminOnly
          path='/international/:continent'
          component={Countries}
        />
        <AppRoute
          exact
          adminOnly
          path='/international/:continent/:country'
          component={IntlRetailStores}
        />
        <AppRoute
          exact
          adminOnly
          path='/distributors'
          component={SearchDistributors}
        />
        <AppRoute exact adminOnly path='/about' component={AboutUs} />
        <AppRoute
          exact
          adminOnly
          path='/about/:id'
          component={EditAboutUsPage}
        />

        <AppRoute exact adminOnly path='/legal' component={Legal} />
        <AppRoute exact adminOnly path='/legal/:id' component={EditLegal} />

        <AppRoute exact adminOnly path='/warranty' component={Warranty} />
        <AppRoute
          exact
          adminOnly
          path='/warranty/:id'
          component={EditWarranty}
        />

        <AppRoute
          exact
          adminOnly
          path='/warranty-policy'
          component={WarrantyPolicy}
        />
        <AppRoute
          exact
          adminOnly
          path='/warranty-policy/:id'
          component={EditWarrantyPolicy}
        />

        <AppRoute
          exact
          adminOnly
          path='/retailstores'
          component={RetailStores}
        />
        <AppRoute
          exact
          adminOnly
          path='/landing-page'
          component={LandingPage}
        />
        <AppRoute
          exact
          adminOnly
          path='/landing-page/:id'
          component={EditLandingPage}
        />
        <AppRoute
          exact
          adminOnly
          path='/category-pages'
          component={CategoryPages}
        />
        <AppRoute
          exact
          adminOnly
          path='/category-pages/:category'
          component={CategoryPage}
        />
        <AppRoute
          exact
          adminOnly
          path='/category-pages/pry bars/:id'
          dataKey='pryBars'
          slug='/category/pry-bars'
          title='Pry Bars'
          component={EditCategoryPage}
        />
        <AppRoute
          exact
          adminOnly
          path='/category-pages/striking tools/:id'
          dataKey='strikingTools'
          slug='/category/striking-tools'
          title='Striking Tools'
          component={EditCategoryPage}
        />
        <AppRoute
          exact
          adminOnly
          path='/category-pages/accessories/:id'
          dataKey='accessories'
          slug='/category/accessories'
          title='Accessories'
          component={EditCategoryPage}
        />
        <AppRoute
          exact
          adminOnly
          path='/category-pages/squares/:id'
          dataKey='squares'
          slug='/category/squares'
          title='Squares'
          component={EditCategoryPage}
        />
        <AppRoute
          exact
          adminOnly
          path={'/globalcontents'}
          component={GlobalContents}
        />
        <AppRoute
          exact
          adminOnly
          path={'/globalcontents/:id'}
          component={EditGlobalContents}
        />
        <AppRoute
          exact
          adminOnly
          path={'/globalcontents/new'}
          component={EditGlobalContents}
        />
        <AppRoute exact adminOnly path={'/faq'} component={FAQ} />
        <AppRoute exact adminOnly path={'/faq/:id'} component={EditFAQ} />
        <AppRoute exact adminOnly path={'/fileupload'} component={FileUpload} />
        <AppRoute
          exact
          adminOnly
          path={'/reasoncodes'}
          component={ReasonCodes}
        />
      </Switch>
    </SiteLayout>
  );
};
