import { GET_CONTINENTS, SET_CONTINENT, UPDATE_CONTINENT } from '../types';
import { success } from '../utils';

export const initialState = {
  continentsList: null,
  isContinentsListLoading: false,
  continent: null,
  isContinentLoading: false,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_CONTINENTS:
      return {
        ...state,
        isContinentsListLoading: true,
      };
    case success(GET_CONTINENTS):
      return {
        ...state,
        continentsList: action.payload.data,
        isContinentsListLoading: false,
      };
    case SET_CONTINENT:
      return {
        ...state,
        continent: action.continent,
      };
    case success(UPDATE_CONTINENT):
      return {
        ...state,
        continent: action.payload.data,
        continentsList: null,
      };
    default:
      return state;
  }
};
