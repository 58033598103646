import {
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Input,
  Layout,
  message,
  PageHeader,
  Space,
  Upload,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  Container,
  GeneralInformationFormFields,
  ProductImage,
  MarkdownEditorSm,
} from '../../../components';
import { pageContentsActions } from '../../../store';
import './styles.css';
import { getCDN } from '../../../helpers/cdnHelper';

const { Content } = Layout;
const { TextArea } = Input;

export const EditLandingPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const storageLocation = 'home';
  const priceSpiderToolTip =
    'This button text is managed by PriceSpider. To change the SKU, update the Button SKU below';

  //Refs
  const formRef = useRef();

  // app state
  const content = useSelector((state) => state.pageContents.pageContent);

  // local state
  const [state, setState] = useState({
    visible: false,
    selectedContent: null,
    saving: false,
    loading: true,
    isNew: true,
    hero1ImageUrl: null,
    featured1ImageUrl: null,
    featured2ImageUrl: null,
    hero2ImageUrl: null,
  });

  const { hero1ImageUrl, featured1ImageUrl, featured2ImageUrl, hero2ImageUrl } =
    state;

  /***
   * On a hard refresh, we won't have a page content loaded yet.
   * Go hit the api to load the page contents.
   */
  useEffect(() => {
    if (!content) {
      // Check URL to see if its a new content page
      if (id !== 'new') {
        // hit api and load product
        dispatch(pageContentsActions.getPageContentById(id))
          .then(() => {})
          .catch(() => {
            //navigate to not found page
          });
      }
    }

    setState({
      ...state,
      selectedContent: content,
      hero1ImageUrl: content?.content?.hero1Image
        ? content?.content?.hero1Image
        : null,
      featured1ImageUrl: content?.content?.featured1Image
        ? content?.content?.featured1Image
        : null,
      featured2ImageUrl: content?.content?.featured2Image
        ? content?.content?.featured2Image
        : null,
      hero2ImageUrl: content?.content?.hero2Image
        ? content?.content?.hero2Image
        : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, content]);

  const hero1ImageProp = {
    beforeUpload: (file) => {
      dispatch(pageContentsActions.uploadImage(file, storageLocation))
        .then((res) => {
          // Assign new image to UI
          setState({
            ...state,
            hero1ImageUrl: `${getCDN()}/images/home/${res.data.data}`,
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            message.error('Unsupported File Type');
          } else {
            message.error('An Error has occured. Please try again');
          }
        });

      return false;
    },
    hero1ImageUrl,
  };

  const featured1ImageProp = {
    beforeUpload: (file) => {
      dispatch(pageContentsActions.uploadImage(file, storageLocation))
        .then((res) => {
          setState({
            ...state,
            featured1ImageUrl: `${getCDN()}/images/home/${res.data.data}`,
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            message.error('Unsupported File Type');
          } else {
            message.error('An Error has occured. Please try again');
          }
        });

      return false;
    },
    featured1ImageUrl,
  };

  const featured2ImageProp = {
    beforeUpload: (file) => {
      dispatch(pageContentsActions.uploadImage(file, storageLocation))
        .then((res) => {
          setState({
            ...state,
            featured2ImageUrl: `${getCDN()}/images/home/${res.data.data}`,
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            message.error('Unsupported File Type');
          } else {
            message.error('An Error has occured. Please try again');
          }
        });

      return false;
    },
    featured2ImageUrl,
  };

  const hero2ImageProp = {
    beforeUpload: (file) => {
      dispatch(pageContentsActions.uploadImage(file, storageLocation))
        .then((res) => {
          setState({
            ...state,
            hero2ImageUrl: `${getCDN()}/images/home/${res.data.data}`,
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            message.error('Unsupported File Type');
          } else {
            message.error('An Error has occured. Please try again');
          }
        });

      return false;
    },
    hero2ImageUrl,
  };

  const uploadButton = (
    <Button icon={<UploadOutlined />}>Click to upload</Button>
  );

  const onFinish = (values) => {
    setState({ ...state, saving: true, loading: true });
    let content;
    content = {
      ...state.selectedContent,
      ...values,
      content: {
        ...values?.content,
        hero1Image: state.hero1ImageUrl ? state.hero1ImageUrl : null,
        featured1LearnMoreLink: values.featured1LearnMoreLink,
        featured1Image: state.featured1ImageUrl
          ? state.featured1ImageUrl
          : null,
        featured2Image: state.featured2ImageUrl
          ? state.featured2ImageUrl
          : null,
        hero2Image: state.hero2ImageUrl ? state.hero2ImageUrl : null,
      },
    };

    if (id === 'new') {
      // Add
      let newContent = content;
      newContent = { ...newContent, dataKey: 'landingPage', slug: '/' };
      dispatch(pageContentsActions.addPageContent(newContent))
        .then(() => {
          dispatch(pageContentsActions.getPageContents('landingPage'));
          message.success('Successfully Saved!');
          history.push('/landing-page');
        })
        .catch(() => {
          message.error('An error has occurred');
        });
    } else {
      // Edit
      dispatch(pageContentsActions.updatePageContent(content))
        .then(() => {
          dispatch(pageContentsActions.getPageContents('landingPage'));
          message.success('Successfully Saved!');
          history.push('/landing-page');
        })
        .catch(() => {
          message.error('An error has occurred');
        });
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Edit Landing Page | Stiletto Tools Admin</title>
      </Helmet>
      <Content>
        {(!state.selectedContent && id === 'new') || state.selectedContent ? (
          <Form
            name='dynamic_form_nest_item'
            onFinish={onFinish}
            autoComplete='off'
            hideRequiredMark={false}
            layout={'vertical'}
            initialValues={{
              content: { testimonials: content?.content?.testimonials },
            }}
            ref={formRef}
          >
            <PageHeader
              style={{ padding: 0 }}
              onBack={() => history.goBack()}
              title='Edit Landing Page'
              extra={[
                <Button
                  key={'Button1'}
                  type='primary'
                  htmlType='submit'
                  // disabled={isUploading}
                  // loading={isUpdating}
                  icon={<SaveOutlined />}
                >
                  Save
                  {/*{intl.formatMessage({*/}
                  {/*  id: 'adminProductDetails.saveBtn',*/}
                  {/*})}*/}
                </Button>,
              ]}
            />

            {/*START BASIC INFO*/}
            <Divider orientation='left'>General Information</Divider>
            <GeneralInformationFormFields
              selectedContent={state?.selectedContent}
            />
            {/*END BASIC INFO*/}
            {/*START HERO 1*/}
            <Divider orientation='left'>Hero 1</Divider>

            <Form.Item
              name={['content', 'hero1Title']}
              label='Title'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero1Title}
            >
              <MarkdownEditorSm
                required
                value={state.selectedContent?.content?.hero1Title}
                options={{
                  spellChecker: false,
                  previewRender(value) {
                    return ReactDOMServer.renderToString(
                      <ReactMarkdown source={value} />
                    );
                  },
                }}
              />
            </Form.Item>
            <Form.Item
              name={['content', 'hero1SubText']}
              label='Sub Title'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero1SubText}
            >
              <MarkdownEditorSm
                required
                value={state.selectedContent?.content?.hero1SubText}
                options={{
                  spellChecker: false,
                  previewRender(value) {
                    return ReactDOMServer.renderToString(
                      <ReactMarkdown source={value} />
                    );
                  },
                }}
              />
            </Form.Item>
            <Form.Item
              label='Body'
              name={['content', 'hero1Body']}
              rules={[{ required: false, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero1Body}
            >
              <MarkdownEditorSm
                required
                value={state.selectedContent?.content?.hero1Body}
                options={{
                  spellChecker: false,
                  previewRender(value) {
                    return ReactDOMServer.renderToString(
                      <ReactMarkdown source={value} />
                    );
                  },
                }}
              />
            </Form.Item>
            <Form.Item
              name={['content', 'hero1HyperlinkText']}
              label='Hyperlink Text'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero1HyperlinkText}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['content', 'hero1HyperlinkUrl']}
              label='Hyperlink Url'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero1HyperlinkUrl}
            >
              <Input addonBefore='https://stiletto.com/' defaultValue='' />
            </Form.Item>
            <Form.Item
              name={['content', 'hero1ButtonText']}
              label='Button Text'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero1ButtonText}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['content', 'hero1ButtonUrl']}
              label='Button Url'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero1ButtonUrl}
            >
              <Input addonBefore='https://stiletto.com/' defaultValue='' />
            </Form.Item>
            <Form.Item
              name={['content', 'hero1Image']}
              label='Image'
              valuePropName='hero1ImageUrl'
              getValueFromEvent={hero1ImageUrl}
              rules={[{ required: true, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero1Image}
            >
              <Upload
                {...hero1ImageProp}
                listType='picture'
                fileList={[]}
                // onPreview={handlePreview}
                multiple={false}
                accept={'.png,.jpeg,.jpg'}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
            <ProductImage imageUrl={state.hero1ImageUrl} maxHeight={300} />

            {/*END HERO 1*/}

            {/*START FEATURED 1*/}
            <Divider orientation='left'>Featured 1</Divider>
            <Form.Item
              name={['content', 'featured1Title']}
              label='Title'
              rules={[{ required: true, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.featured1Title}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['content', 'featured1SubText']}
              label='Sub Title'
              rules={[{ required: true, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.featured1SubText}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['content', 'featured1HyperlinkText']}
              label='Hyperlink Text'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={
                state?.selectedContent?.content?.featured1HyperlinkText
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['content', 'featured1HyperlinkUrl']}
              label='Hyperlink Url'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={
                state?.selectedContent?.content?.featured1HyperlinkUrl
              }
            >
              <Input addonBefore='https://stiletto.com/' defaultValue='' />
            </Form.Item>
            <Form.Item
              name={['content', 'featured1PriceSpiderSku']}
              label='PriceSpider SKU'
              initialValue={
                state?.selectedContent?.content?.featured1PriceSpiderSku
              }
              tooltip={
                'Enter a SKU to show the PriceSpider "Where to Buy" button. This SKU will be used for the seller feed in the PriceSpider widget.'
              }
            >
              <Input defaultValue='' />
            </Form.Item>
            <Form.Item
              name={['content', 'featured1Image']}
              label='Image'
              valuePropName='featured1ImageUrl'
              getValueFromEvent={featured1ImageUrl}
              rules={[{ required: true, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.featured1Image}
            >
              <Upload
                {...featured1ImageProp}
                listType='picture'
                fileList={[]}
                // onPreview={handlePreview}
                multiple={false}
                accept={'.png,.jpeg,.jpg'}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
            <ProductImage imageUrl={state.featured1ImageUrl} maxHeight={300} />

            {/*END FEATURED 1*/}

            {/*START FEATURED 2*/}
            <Divider orientation='left'>Featured 2</Divider>
            <Form.Item
              name={['content', 'featured2Title']}
              label='Title'
              rules={[{ required: true, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.featured2Title}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['content', 'featured2SubText']}
              label='Sub Title'
              rules={[{ required: true, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.featured2SubText}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['content', 'featured2HyperlinkText']}
              label='Hyperlink Text'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={
                state?.selectedContent?.content?.featured2HyperlinkText
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['content', 'featured2HyperlinkUrl']}
              label='Hyperlink Url'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={
                state?.selectedContent?.content?.featured2HyperlinkUrl
              }
            >
              <Input addonBefore='https://stiletto.com/' defaultValue='' />
            </Form.Item>
            <Form.Item
              name={['content', 'featured2PriceSpiderSku']}
              label='PriceSpider SKU'
              initialValue={
                state?.selectedContent?.content?.featured2PriceSpiderSku
              }
              tooltip={
                'Enter a SKU to show the PriceSpider "Where to Buy" button. This SKU will be used for the seller feed in the PriceSpider widget.'
              }
            >
              <Input defaultValue='' />
            </Form.Item>
            <Form.Item
              name={['content', 'featured2Image']}
              label='Image'
              valuePropName='featured2ImageUrl'
              getValueFromEvent={featured2ImageUrl}
              rules={[{ required: true, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.featured2Image}
            >
              <Upload
                {...featured2ImageProp}
                listType='picture'
                fileList={[]}
                // onPreview={handlePreview}
                multiple={false}
                accept={'.png,.jpeg,.jpg'}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
            <ProductImage imageUrl={state.featured2ImageUrl} maxHeight={300} />

            {/*END FEATURED 2*/}

            {/*START HERO 2*/}
            <Divider orientation='left'>Hero 2</Divider>

            <Form.Item
              name={['content', 'hero2Title']}
              label='Title'
              rules={[{ required: true, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero2Title}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['content', 'hero2SubText']}
              label='Sub Title'
              rules={[{ required: true, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero2SubText}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['content', 'hero2HyperlinkText']}
              label='Hyperlink Text'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero2HyperlinkText}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['content', 'hero2HyperlinkUrl']}
              label='Hyperlink Url'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero2HyperlinkUrl}
            >
              <Input addonBefore='https://stiletto.com/' defaultValue='' />
            </Form.Item>
            <Form.Item
              name={['content', 'hero2ButtonText']}
              label='Button Text'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero2ButtonText}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['content', 'hero2ButtonUrl']}
              label='Button Url'
              rules={[{ required: false, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero2ButtonUrl}
            >
              <Input addonBefore='https://stiletto.com/' defaultValue='' />
            </Form.Item>
            <Form.Item
              label='Body'
              name={['content', 'hero2Body']}
              rules={[{ required: true, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero2Body}
            >
              <MarkdownEditorSm
                value={content?.content?.hero2Body}
                options={{
                  spellChecker: false,
                  previewRender(value) {
                    return ReactDOMServer.renderToString(
                      <ReactMarkdown source={value} />
                    );
                  },
                }}
              />
            </Form.Item>
            <Form.Item
              name={['content', 'hero2Image']}
              label='Image'
              valuePropName='hero2ImageUrl'
              getValueFromEvent={hero2ImageUrl}
              rules={[{ required: true, message: 'Required' }]}
              initialValue={state?.selectedContent?.content?.hero2Image}
            >
              <Upload
                {...hero2ImageProp}
                listType='picture'
                fileList={[]}
                // onPreview={handlePreview}
                multiple={false}
                accept={'.png,.jpeg,.jpg'}
              >
                {uploadButton}
              </Upload>
            </Form.Item>
            <ProductImage imageUrl={state.hero2ImageUrl} maxHeight={300} />
            {/*END HERO 2*/}

            {/*START TESTIMONIALS*/}
            <Divider orientation='left'>Testimonials</Divider>

            <Form.List name={['content', 'testimonials']}>
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field) => (
                      <React.Fragment key={`testimonial-` + field.key}>
                        <Space
                          style={{ display: 'flex', marginBottom: 8 }}
                          align='start'
                        >
                          <Form.Item
                            {...field}
                            name={[field.name, 'quote']}
                            fieldKey={[field.fieldKey, 'quote']}
                            rules={[{ required: true, message: 'Required' }]}
                            initialValue={field?.value?.quote}
                          >
                            <TextArea
                              placeholder='Quote'
                              rows={4}
                              style={{ width: '250px' }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'author']}
                            fieldKey={[field.fieldKey, 'author']}
                            rules={[{ required: true, message: 'Required' }]}
                            initialValue={field?.value?.author}
                          >
                            <Input placeholder='Author' />
                          </Form.Item>

                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              title={'Delete'}
                              onClick={() => {
                                remove(field.name);
                              }}
                            >
                              ''
                            </MinusCircleOutlined>
                          ) : null}
                        </Space>
                      </React.Fragment>
                    ))}

                    <Form.Item>
                      <Button
                        type='dashed'
                        onClick={() => {
                          add();
                        }}
                        block
                      >
                        <PlusOutlined /> Add Testimonial
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
            {/*END TESTIMONIALS*/}

            <Form.Item>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Content>
    </Container>
  );
};
