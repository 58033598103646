import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Popconfirm,
  message,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useCurrentUserProfile from '../../../hooks/useCurrentUserProfile';
import { continentCountryStoresActions } from '../../../store';

export const IntlRetailStoreModal = (props) => {
  //Refs
  const formRef = React.createRef();
  const dispatch = useDispatch();

  const userProfile = useCurrentUserProfile();
  const { selectedStore, isVisible, event } = props;
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setModalVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const handleModalClose = () => {
    formRef.current.setFieldsValue({
      name: null,
      url: null,
      phone: null,
    });
    setModalVisible(false);
  };

  const confirmDelete = () => {
    let continentCountryStores = { ...selectedStore, isDeleted: true };
    dispatch(
      continentCountryStoresActions.updateContinentCountryStores(
        continentCountryStores
      )
    )
      .then(() => {
        message.success('Successfully Deleted');
        dispatch(
          continentCountryStoresActions.getContinentCountryStoresByCountryId(
            props.CountryId
          )
        );
        setModalVisible(false);
      })
      .catch(() => {
        message.error('An error has occurred');
      });
  };

  /***
   * onFinish will save the updated store info
   * @param values: the values of the form
   */
  const onFinish = (values) => {
    //URL Validation
    const validateUrl = values.url.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g
    );
    if (validateUrl == null) {
      return message.error('Please enter a valid url');
    }

    // Reconstruct Object to be use able by stored proc
    let obj = {
      Name: values.name,
      Url: values.url,
      Phone: values.phone,
      UpdatedBy: userProfile.unique_name,
      CountryId: props.CountryId,
    };

    if (selectedStore) {
      //Existing Store
      obj = {
        ...obj,
        Id: selectedStore.id,
        IsDeleted: selectedStore.isDeleted,
        UpdatedBy: userProfile.unique_name,
        CountryId: props.CountryId,
      };
      dispatch(continentCountryStoresActions.updateContinentCountryStores(obj))
        .then(() => {
          message.success('Successfully Updated!');
          dispatch(
            continentCountryStoresActions.getContinentCountryStoresByCountryId(
              props.CountryId
            )
          );
          setModalVisible(false);
        })
        .catch(() => message.error('An error has occurred'));
    } else {
      // New Store
      dispatch(continentCountryStoresActions.insertContinentCountryStores(obj))
        .then(() => {
          message.success('Successfully Inserted!');
          dispatch(
            continentCountryStoresActions.getContinentCountryStoresByCountryId(
              props.CountryId
            )
          );
          setModalVisible(false);
        })
        .catch(() => message.error('An error has occurred'));
    }
  };

  return (
    <React.Fragment>
      <Modal
        title={selectedStore ? 'Edit store' : 'Add store'}
        visible={modalVisible}
        onCancel={() => handleModalClose()}
        footer={[
          <Button key='back' onClick={() => handleModalClose()}>
            Cancel
          </Button>,
          selectedStore ? (
            <Popconfirm
              key='delete'
              title='Are you sure delete this store?'
              onConfirm={confirmDelete}
              okText='Yes'
              cancelText='No'
            >
              <Button
                style={{ marginLeft: '3px' }}
                key={'DeleteBtn'}
                type='danger'
              >
                Delete
              </Button>
              ,
            </Popconfirm>
          ) : null,
          <Button
            key='submit'
            type='primary'
            onClick={() => formRef.current.submit()}
          >
            Submit
          </Button>,
        ]}
        destroyOnClose
      >
        <Form
          name='form'
          onFinish={onFinish}
          autoComplete='off'
          hideRequiredMark={false}
          layout={'vertical'}
          ref={formRef}
        >
          <Row gutter={[16, 16]} justify='center'>
            <Col xs={24} md={24}>
              <Form.Item
                name='name'
                label='Name'
                initialValue={selectedStore?.name}
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='url'
                label='Url'
                initialValue={selectedStore?.url}
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='phone'
                label='Phone'
                initialValue={selectedStore?.phone}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
