import { Layout } from 'antd';
import styled from 'styled-components';

const { Footer, Header } = Layout;

export const StyledFooter = styled(Footer)`
  background-color: black;
  color: rgba(255, 255, 255, 0.65);
  display: flex;
  align-items: center;
`;

export const AdminFooter = styled(Footer)`
  background-color: #f2f2f2;
  text-align: center;
`;

export const AdminHeader = styled(Header)`
  padding: 0;
  background-color: #f2f2f2;
  box-shadow: 0 2px 8px #ccc;
  display: flex;
`;
