import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { continentActions } from '../../../store';
import { ListView } from '../../../components/ListView';
import useCurrentUserProfile from '../../../hooks/useCurrentUserProfile';

export const Continents = () => {
  const dispatch = useDispatch();
  const userProfile = useCurrentUserProfile();

  // app state
  const continentsList = useSelector(
    (state) => state.continents.continentsList,
    shallowEqual
  );

  // local state
  const [continents, setContinents] = useState(continentsList);

  useEffect(() => {
    dispatch(continentActions.getContinents());
  }, [dispatch]);

  useEffect(() => {
    if (continentsList) setContinents(continentsList);
  }, [continentsList]);

  const confirmDelete = async (continent) => {
    dispatch(continentActions.updateContinent(continent)).then(() => {
      dispatch(continentActions.getContinents());
    });
    return 'Successfully Deleted';
  };

  const onFinish = async (values, selectedContinent) => {
    // Reconstruct Object to be use able by stored proc
    let obj = {
      Name: values.name,
      UpdatedBy: userProfile.unique_name,
    };

    if (selectedContinent) {
      //Existing Continent
      obj = {
        ...obj,
        Id: selectedContinent.id,
        IsDeleted: selectedContinent.isDeleted,
        UpdatedBy: userProfile.unique_name,
      };
      dispatch(continentActions.updateContinent(obj)).then(() => {
        dispatch(continentActions.getContinents());
      });
      return 'Successfully Updated!';
    } else {
      // New Continent
      dispatch(continentActions.insertContinent(obj)).then(() => {
        dispatch(continentActions.getContinents());
      });
      return 'Successfully Inserted!';
    }
  };

  return (
    <>
      <Helmet>
        <title>International | Stiletto Tools Admin</title>
      </Helmet>
      <ListView
        pageTitle='International'
        hasAdd={true}
        hasEditListItem={true}
        listItems={continents}
        modalConfirmDelete={confirmDelete}
        modalOnFinish={onFinish}
      />
    </>
  );
};
