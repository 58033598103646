import createAxiosAction from '../createAxiosAction';
import {
  GET_DISTRIBUTORS,
  SET_DISTRIBUTOR,
  GET_DISTRIBUTOR,
  UPDATE_DISTRIBUTOR,
  UPLOAD_DISTRIBUTOR_IMAGE,
  INSERT_DISTRIBUTOR,
  UPDATE_DISTRIBUTORS_SORT_ORDER,
} from '../types';

export default {
  getDistributors: () => {
    return createAxiosAction({
      url: `/api/v1/distributors`,
      startAction: GET_DISTRIBUTORS,
    });
  },

  setDistributor: (distributor) => (dispatch) => {
    dispatch({ type: SET_DISTRIBUTOR, distributor });
  },

  getDistributorById: (id) => {
    return createAxiosAction({
      url: `/api/v1/distributors/${id}`,
      startAction: GET_DISTRIBUTOR,
    });
  },
  insertDistributor: (distributor) => {
    return createAxiosAction({
      method: 'POST',
      url: '/api/v1/distributors',
      body: { Distributor: distributor },
      startAction: INSERT_DISTRIBUTOR,
    });
  },
  updateDistributor: (distributor) => {
    return createAxiosAction({
      method: 'PUT',
      url: '/api/v1/distributors',
      body: { Distributor: distributor },
      startAction: UPDATE_DISTRIBUTOR,
    });
  },
  uploadDistributorImage: (file) => {
    let form = new FormData();

    form.append('file', file);
    form.append('name', file.name);

    return createAxiosAction({
      method: 'POST',
      url: `/api/v1/distributors/image`,
      body: form,
      startAction: UPLOAD_DISTRIBUTOR_IMAGE,
      contentType: 'multipart/form-data',
      actionPayload: { file },
    });
  },
  updateDistributorsSortOrder: (id, oldIndex, newIndex) => {
    return createAxiosAction({
      method: 'PUT',
      url: '/api/v1/distributors/updateSortOrder',
      body: { id: id, oldIndex: oldIndex, newIndex: newIndex },
      startAction: UPDATE_DISTRIBUTORS_SORT_ORDER,
    });
  },
};
