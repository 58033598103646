import { GET_LOCATION_DATA } from '../types';
import { success, error } from '../utils';

export const initialState = {
  getLocationData: {
    isLoading: false,
    isError: false,
  },
  countries: null,
  states: null,
};

export default (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_LOCATION_DATA:
      return {
        ...state,
        getLocationData: {
          ...state.getLocationData,
          isLoading: true,
          isError: false,
        },
      };
    case success(GET_LOCATION_DATA):
      return {
        ...state,
        getLocationData: {
          ...state.getLocationData,
          isLoading: false,
          isError: false,
        },
        countries: action.payload.data.countries,
        states: action.payload.data.states,
      };
    case error(GET_LOCATION_DATA):
      return {
        ...state,
        getLocationData: {
          ...state.getLocationData,
          isLoading: false,
          isError: true,
        },
      };
    default:
      return state;
  }
};
