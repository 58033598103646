import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { appInsights } from '../helpers/appInsights';

const useAppInsightsUserContext = () => {
  const { accounts } = useMsal();

  useEffect(() => {
    if (!accounts.length) {
      return;
    }

    appInsights.setAuthenticatedUserContext(accounts[0].username);
  }, [accounts]);
};

export default useAppInsightsUserContext;
